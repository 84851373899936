/* eslint-disable prettier/prettier */
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../functions/dates/date.format';

@Pipe({ name: 'wakiDate', pure: false })
export class WakiDatePipe implements PipeTransform {
    transform(date: string | Date | undefined, withTime?: boolean): string {
        if (!date) {
            return '';
        }

        const convertedDate = new Date(date);

        if (!withTime) {
            return moment(convertedDate).format(DATE_FORMAT);
        }

        return moment(convertedDate).format(DATE_TIME_FORMAT);
    }
}
