/* eslint-disable prettier/prettier */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from '../component-base';

@Component({
  selector: 'app-activation-icon',
  templateUrl: './activation-icon.component.html',
  styleUrls: ['./activation-icon.component.scss']
})
export class ActivationIconComponent extends ComponentBase {
  @Input() record: any;
  @Input() isChecked: boolean | undefined = false;
  @Input() disabled = false;
  @Input() needToConfirm = false;

  @Output() toogleStatus = new EventEmitter();

  constructor() {
    super();
  }

  public preventDefault = (e: any): void => {
    e.stopPropagation();
    e.preventDefault();
    if (this.needToConfirm) {
      e.target.checked = !e.target.checked
    };
  }

}
