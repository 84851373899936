import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { AppComponent } from '@portal/app.component';

import { ComponentBase } from '@portal/shared/components/component-base';
import { allowedSectionOptions } from '@portal/shared/constants/sections';
import { DefaultLanguage } from '@portal/shared/services/language/language.model';
import { ScreenResolutionService } from '@portal/shared/services/screen-resolution.service';
import { IMerchantSignupData } from '@portal/shared/models/merchant-signup-request.model';
import { BusinessTypeEnum, DevicesEnum, SubscriptionTypeEnum } from '@portal/shared/models/merchant-signup.model';
import { FormValidators } from '@portal/shared/validators/form-validator';
import { LocationService } from '@portal/shared/services/location.service';
import { IApiResult } from '@portal/shared/models/api-result.model';
import { Observable, of, zip } from 'rxjs';
import { ICountry } from '@portal/shared/models/country.model';
import { ICity } from '@portal/shared/models/city.model';
import { translationLang } from '@portal/shared/functions/translate-language';
import { BankService } from '@portal/shared/services/bank.service';
import { isNullOrEmptyString } from '@portal/shared/functions/string.function';
import { environment } from 'src/environments/environment';


const DATE_FORMAT = 'YYYY-MM-DD';
@Component({
    selector: 'app-sign-up-merchant-form',
    templateUrl: './sign-up-merchant-form.component.html',
    styleUrls: ['./sign-up-merchant-form.component.scss'],
})
export class SignUpMerchantFormComponent extends ComponentBase {
    @Input() signupRequest: IMerchantSignupData | undefined;
    @Input() isLoading = false;
    @Input() useNewSignUpForm = false;
    @Output() signUp = new EventEmitter();
    @Output() approve = new EventEmitter();
    @Output() reject = new EventEmitter();
    @Output() exit = new EventEmitter();

    public formFields!: string[];
    public app = AppComponent;
    public form: FormGroup | undefined;
    public uploadDocForm: FormGroup | undefined;
    public isEditing = false;

    public captchaRequired = false;
    public captchaSolved = false;
    public minDate = moment('1900-01-01').format(DATE_FORMAT);
    public maxDate = moment().startOf('day').format(DATE_FORMAT);

    public sectionOptions = allowedSectionOptions;

    public checkboxValue = false;
    public merchantSignUpEnum = BusinessTypeEnum
    public subscriptionTypeEnum = SubscriptionTypeEnum
    public devicesEnum = DevicesEnum
    public isMonthly = false;
    public isFreelancing = BusinessTypeEnum.Freelancing;
    public isCommercialRecord = false;

    public countries: ICountry[] = [];
    public cities: ICity[] = [];

    public countryOptions: any = [];
    public cityOptions: any = [];
    public bankOptions: any = [];

    constructor(
        public screenService: ScreenResolutionService,
        private fb: FormBuilder,
        private locationService: LocationService,
        private bankService: BankService,
    ) {
        super()

        zip(this.getCountries(), this.getBanks()).pipe(takeUntil(this.destroyed$)).subscribe();
    }

    public toggleCheckBox(): void {
        this.checkboxValue = !this.checkboxValue
    }

    public get width(): number {
        return this.screenService.width();
    }

    public get currentLanguage(): string {
        return localStorage.getItem('lang') || DefaultLanguage;
    }

    public get nameTranslations() {
        return this.form?.get('nameTranslations') as FormGroup;
    }

    public get merchantBusinessType() {
        return this.form?.get('merchantBusinessType') as FormGroup;
    }

    public get subscriptionOrderType() {
        return this.form?.get('order') as FormGroup;
    }


    public get disableForm(): boolean {
        return this.isLoading || !this.requestPending;
    }

    public get requestPending(): boolean {
        if (!this.signupRequest) {
            return true;
        }
        return this.signupRequest?.status === 'PENDING';
    }

    public get shouldDisableSignUpButton(): boolean {
        return (!this.form?.valid || (this.captchaRequired && !this.captchaSolved));
    }

    ngOnInit(): void {
        this.uploadDocForm = this.createUploadDocForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const request = changes.signupRequest?.currentValue;
        this.isEditing = !!request;
        this.signupRequest = request;
        this.captchaRequired = !isNullOrEmptyString(environment.recaptcha.siteKey) && !this.isEditing;
        this.initRegistrationForm();
            // this.form?.get('merchantBusinessType')?.valueChanges
            //     .pipe(takeUntil(this.destroyed$))
            //     .subscribe((value) => {
            //         this.updateSpecialization(value)
            //     });

    }

    public onBusinessTypeChange(): void{
        this.form?.get('crNo')?.reset();
        this.form?.get('nationalId')?.reset();
        this.form?.get('authorizedDocumentId')?.reset();
        this.uploadDocForm?.get('commercialRecords')?.reset();
        this.updateSpecialization(this.form?.get('merchantBusinessType')?.value);
    }

    public submit(): void {
        if (this.form?.invalid || (this.captchaRequired && !this.captchaSolved)) {
            return;
        }
        this.signUp.emit(this.map());
    }

    public captchaResolved(captchaResponse: any): void {
        this.captchaSolved = captchaResponse && captchaResponse.length > 0;
    }

    public captchaErrored(error: any): void {
        console.error(`reCAPTCHA error encountered`, error);
    }

    private initRegistrationForm(): void {
        if (this.signupRequest){
            if (this.signupRequest.merchantBusinessType === BusinessTypeEnum.CommercialRecord){
                this.isCommercialRecord = true;
            }
        }
        this.form = this.fb.group({
            requestId: [''],
            merchantBusinessType: [this.signupRequest?.merchantBusinessType || BusinessTypeEnum.Freelancing],
            nationalId: [
                this.signupRequest?.nationalId || null,
                [Validators.required, Validators.maxLength(10), Validators.minLength(10)],
            ],
            crNo: [
                this.signupRequest?.crNo || null,
            ],
            officialNameTranslations: this.fb.group({
                en: [this.signupRequest?.officialNameTranslations?.en || '',
                [Validators.required],],
                ar: [
                    this.signupRequest?.officialNameTranslations?.ar || '',
                    [Validators.required],
                ],
            }),
            nameTranslations: this.fb.group({
                en: [this.signupRequest?.nameTranslations?.en || '', [Validators.required]],
                ar: [
                    this.signupRequest?.nameTranslations?.ar || '',
                    [Validators.required],
                ],
            }),
            streetAddress: this.fb.group({
                unitNo: [this.signupRequest?.streetAddress?.unitNo || '', [FormValidators.mustNumberic]],
                buildingNo: [this.signupRequest?.streetAddress?.buildingNo || '', [Validators.required, FormValidators.mustNumberic]],
                street: [this.signupRequest?.streetAddress?.street || '', [Validators.required]],
                district: [this.signupRequest?.streetAddress?.district || '', [Validators.required]],
                city: [this.signupRequest?.streetAddress?.city || '', [Validators.required]],
                zipCode: [this.signupRequest?.streetAddress?.zipCode || '', [Validators.required, FormValidators.mustNumberic]],
                country: [(this.signupRequest?.streetAddress?.country) || '', [Validators.required]],
                additional: [this.signupRequest?.streetAddress?.additional || '', [Validators.required, FormValidators.mustNumberic]]
            }),
            vatNo: [this.signupRequest?.vatNo || '', [Validators.maxLength(15), Validators.minLength(15), FormValidators.mustNumberic]],
            hasVAT:[this.signupRequest?.hasVAT || false],
            bankInformation: this.fb.group({
                accountNo: [this.signupRequest?.bankInformation?.accountNo || '', [Validators.required]],
                accountName: [this.signupRequest?.bankInformation?.accountName || '', [Validators.required]],
                IBAN: [this.signupRequest?.bankInformation?.IBAN.replace("SA", '') || '', [Validators.required, Validators.maxLength(22), Validators.minLength(22), FormValidators.mustNumberic]],
                bank: [this.signupRequest?.bankInformation?.bank || '', [Validators.required]],
            }),
            contactDetails: this.fb.group({
                email: [this.signupRequest?.contactDetails?.email || "", [Validators.required, Validators.email]],
                mobileNo: [this.signupRequest?.contactDetails?.mobileNo || "", [Validators.required, Validators.maxLength(9), Validators.minLength(9), FormValidators.mustNumberic]],
                phoneNo: [this.signupRequest?.contactDetails?.phoneNo || "", [Validators.required]],
                contactPerson: this.fb.group({
                    en: [this.signupRequest?.contactDetails?.contactPerson?.en || '', [Validators.required]],
                    ar: [
                        this.signupRequest?.contactDetails?.contactPerson?.ar || '',
                        [Validators.required],
                    ],
                }),
                nationalId: [
                    this.signupRequest?.contactDetails?.nationalId || null,
                    [Validators.required, Validators.maxLength(10), Validators.minLength(10)],
                ],
            }),
            specialization: [this.signupRequest?.specialization || '', [Validators.required]],
            issuingDate: [this.signupRequest?.issuingDate || '', [Validators.required]],
            expirationDate: [this.signupRequest?.expirationDate || '', [Validators.required]],
            authorizedDocumentId: [this.signupRequest?.authorizedDocumentId || '', [Validators.required]],

            validations: [this.signupRequest?.validations],
            order: this.fb.group({
                subscriptionType: [this.signupRequest?.order?.subscriptionType || this.subscriptionTypeEnum.Weekly, [Validators.required]],
                duration: [this.signupRequest?.order?.duration, [Validators.required]],
                startDate: [this.signupRequest?.order?.startDate || '', [Validators.required]],
                devices: [this.signupRequest?.order?.devices || false, [Validators.required]],
                noOfDevicesOrUsers: [this.signupRequest?.order?.noOfDevicesOrUsers, [Validators.required]],
                totalAmount: [500]
            }),

            documents:this.fb.group({
                nationalId: [this.signupRequest?.documents?.nationalId || null],
                commercialRecords: [this.signupRequest?.documents?.commercialRecords || null],
                nationalAddress: [this.signupRequest?.documents?.nationalAddress || null],
                vatCertificate: [this.signupRequest?.documents?.vatCertificate || null],
                IBANCertificate: [this.signupRequest?.documents?.IBANCertificate || null],
                logo: [this.signupRequest?.documents?.logo || null],
            })
        }, {
            validators: this.startCorrect('mobileNo')
        });


        if (this.signupRequest && this.signupRequest.merchantBusinessType === BusinessTypeEnum.CommercialRecord){
            this.form?.get('nationalId')?.disable();
            this.form?.get('nationalId')?.updateValueAndValidity();

            this.form?.get('authorizedDocumentId')?.disable();
            this.form?.get('authorizedDocumentId')?.updateValueAndValidity();
        }

        if (this.signupRequest){
            this.onVatChange({value: this.signupRequest.hasVAT})
            this.onDevicesChange({value: this.signupRequest.order?.devices})
        }

        this.subscriptionOrderType.valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                if (res.subscriptionType === this.subscriptionTypeEnum.Monthly) {
                    this.isMonthly = true;
                } else {
                    this.isMonthly = false;
                }
            });
    }

    createUploadDocForm = () => {
        // this.formFields = ['National ID', 'Commercial', 'National Address ', 'VAT Certificate', 'IBAN Certificate', 'Logo']
        return this.fb.group({
            nationalID: [null, [Validators.required]],
            commercialRecords: [null],
            nationalAddress: [null],
            vatCertificate: [null],
            IBANCertificate: [null],
            logo: [null],
        });
    };

    updateSpecialization = (userType: string) => {
        if (userType === BusinessTypeEnum.CommercialRecord) {
            this.isCommercialRecord = true;

            this.uploadDocForm?.get('commercialRecords')?.setValidators([Validators.required]);
            this.uploadDocForm?.get('commercialRecords')?.updateValueAndValidity();

            this.form?.get('nationalId')?.disable();
            this.form?.get('nationalId')?.updateValueAndValidity();

            this.form?.get('authorizedDocumentId')?.disable();
            this.form?.get('authorizedDocumentId')?.updateValueAndValidity();

            this.form?.get('crNo')?.setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(10)]);
            this.form?.updateValueAndValidity();
        } else {
            this.form?.get('nationalId')?.enable();
            this.form?.get('nationalId')?.updateValueAndValidity();

            this.form?.get('authorizedDocumentId')?.enable();
            this.form?.get('authorizedDocumentId')?.updateValueAndValidity();

            this.uploadDocForm?.get('commercialRecords')?.clearValidators();
            this.uploadDocForm?.get('commercialRecords')?.updateValueAndValidity();

            this.form?.get('crNo')?.clearValidators();
            this.form?.get('crNo')?.updateValueAndValidity();
            this.isCommercialRecord = false;
        }
    };

    private map() {
        const formValues: IMerchantSignupData = this.form?.getRawValue();
        const uploadDocForm: IMerchantSignupData = this.uploadDocForm?.getRawValue();

        const signupData = {
            merchantBusinessType: formValues.merchantBusinessType,
            nationalId: formValues.nationalId,
            crNo: formValues.crNo,
            officialNameTranslations: {
                en: formValues.officialNameTranslations?.en,
                ar: formValues.officialNameTranslations?.ar,
            },
            nameTranslations: {
                en: formValues.nameTranslations?.en,
                ar: formValues.nameTranslations?.ar,
            },
            streetAddress: {
                unitNo: formValues.streetAddress?.unitNo,
                buildingNo: formValues.streetAddress?.buildingNo,
                street: formValues.streetAddress?.street,
                district: formValues.streetAddress?.district,
                city: formValues.streetAddress?.city,
                zipCode: formValues.streetAddress?.zipCode,
                country: formValues.streetAddress?.country,
                additional: formValues.streetAddress?.additional
            },
            vatNo: formValues.vatNo,
            hasVAT:formValues.hasVAT,
            bankInformation: {
                accountNo: formValues.bankInformation?.accountNo,
                accountName: formValues.bankInformation?.accountName,
                IBAN: formValues.bankInformation?.IBAN,
                bank: formValues.bankInformation?.bank,
            },
            contactDetails: {
                email: formValues.contactDetails?.email,
                mobileNo: formValues.contactDetails?.mobileNo,
                phoneNo: formValues.contactDetails?.phoneNo,
                contactPerson: {
                    en: formValues.contactDetails?.contactPerson?.en,
                    ar: formValues.contactDetails?.contactPerson?.ar,
                },
                nationalId: formValues.contactDetails?.nationalId,
            },
            specialization: formValues.specialization,
            issuingDate: formValues.issuingDate,
            expirationDate: formValues.expirationDate,
            authorizedDocumentId: formValues.authorizedDocumentId,

            order: {
                subscriptionType: formValues.order?.subscriptionType,
                duration: formValues.order?.duration,
                startDate: formValues.order?.startDate,
                devices: formValues.order?.devices,
                noOfDevicesOrUsers: formValues.order?.noOfDevicesOrUsers,
                totalAmount: formValues.order?.totalAmount
            }
        }

        return { ...uploadDocForm, postData: signupData }
    }

    private getCountries(): Observable<boolean> {
        return this.locationService.getCountries()
            .pipe(
                map((result: IApiResult) => {
                    this.countries = result.countries ?? [];
                    this.countryOptions = this.countries.map((country) => {
                        return { value: country?._id, label: translationLang(country?.translations) };
                    });
                    return this.countries[0]._id;
                }),
                switchMap((countryId) => this.getCities(countryId)),
                catchError(() => of(false))
            );
    };

    private getCities(countryId: string): Observable<boolean> {
        if (isNullOrEmptyString(countryId)) {
            return of(false);
        }
        return this.locationService
            .getCities(countryId)
            .pipe(
                map((result: IApiResult) => {
                    this.cities = result.cities ?? [];
                    this.cityOptions = this.cities.map((city) => {
                        return { value: city?._id, label: translationLang(city?.translations) };
                    });
                    this.setCountryAndCityValue();
                    return result.success;
                }),
                catchError(() => of(false))
            );
    };

    private getBanks(): Observable<boolean> {
        return this.bankService
            .getBanks()
            .pipe(
                map((result: IApiResult) => {
                    this.bankOptions = (result.banks ?? []).map((bank) => {
                        return {
                            label: translationLang(bank?.translations),
                            value: bank?._id
                        };
                    });
                    return result.success;
                }),
                catchError(() => of(false))
            );
    };


    private setCountryAndCityValue(): void {
        if (!this.signupRequest || !this.countryOptions.length || !this.cityOptions.length) {
            return;
        }

        setTimeout(() => {
            this.form?.get('country')?.setValue(this.signupRequest?.streetAddress?.country || '');
            this.form?.get('city')?.setValue(this.signupRequest?.streetAddress?.city || '');

            this.form?.updateValueAndValidity();
        }, 300);
    };

    public onVatChange(value:any): void{
        if (value.value){
            this.form?.get('vatNo')?.setValidators([Validators.required, Validators.maxLength(15), Validators.minLength(15), FormValidators.mustNumberic])
            this.form?.get('vatNo')?.enable()

            this.uploadDocForm?.get('vatCertificate')?.setValidators([Validators.required]);
            this.uploadDocForm?.get('vatCertificate')?.updateValueAndValidity();
        }
        else {
            this.form?.get('vatNo')?.setValidators([Validators.nullValidator])
            this.form?.get('vatNo')?.disable()
            this.form?.get('vatNo')?.reset()

            this.uploadDocForm?.get('vatCertificate')?.clearValidators();
            this.uploadDocForm?.get('vatCertificate')?.updateValueAndValidity();
        }

        this.uploadDocForm?.updateValueAndValidity()
        this.form?.updateValueAndValidity()
    }

    public onDevicesChange(value:any): void{
        if (value.value){
            this.form?.get('order')?.get('noOfDevicesOrUsers')?.setValidators([Validators.required])
            this.form?.get('order')?.get('noOfDevicesOrUsers')?.enable()
        }
        else {
            this.form?.get('order')?.get('noOfDevicesOrUsers')?.setValidators([Validators.nullValidator])
            this.form?.get('order')?.get('noOfDevicesOrUsers')?.disable()
            this.form?.get('order')?.get('noOfDevicesOrUsers')?.reset()
        }

        this.form?.updateValueAndValidity()
    }

    displayValidation(formName: any) {
        return (this.form?.get(formName)?.invalid && this.form?.get(formName)?.errors && (this.form?.get(formName)?.dirty || this.form?.get(formName)?.touched))
    }

    startCorrect(controlName: string) {

        return () => {
            if (this.form){
                const control = this.form?.get('contactDetails')?.get(controlName) as FormGroup;
                if (control.value[0] != "5" && control.value) {
                    control.setErrors({ pattern: true })
                }
            }
        };

    }

    langTimeout: any
    englishInput(evt: any, formValidation: any) {
        formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity()
        var theEvent = evt || window.event;
        clearTimeout(this.langTimeout)
        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[a-zA-Z\0-9\!@#\$%\^\&*\)\(+=._-]/;
        if (key == '.' || !regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) {
                theEvent.preventDefault()
                formValidation.markAsDirty()
                formValidation.setErrors({ wrongInput: true })
                this.langTimeout = setTimeout(() => { formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity() }, 3000)
            };
        }
    }
    arabicInput(evt: any, formValidation: any) {
        formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity()
        var theEvent = evt || window.event;
        clearTimeout(this.langTimeout)
        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[\u0600-\u06FF\0-9\!@#\$%\^\&*\)\(+=._-]/;
        if (key == '.' || !regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) {
                theEvent.preventDefault()
                formValidation.markAsDirty()
                formValidation.setErrors({ wrongInput: true })
                this.langTimeout = setTimeout(() => { formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity() }, 3000)
            };
        }
    }


    numberTimeout: any
    validateNumericInput(evt: any, formValidation: any) {
        formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity()
        var theEvent = evt || window.event;
        clearTimeout(this.numberTimeout)
        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (key == '.' || !regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) {
                theEvent.preventDefault();
                formValidation.markAsDirty()
                formValidation.setErrors({ wrongInput: true })
                this.numberTimeout = setTimeout(() => { formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity() }, 3000)
            }
        }
    }
}
