/* eslint-disable prettier/prettier */
import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComponentBase } from '@portal/shared/components/component-base';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { AuthService } from '@portal/auth/services/auth.service';
import { of } from 'rxjs';
import { IOtpLoginResult } from '@portal/auth/models/login-result.model';
import { IApiResult } from '@portal/shared/models/api-result.model';
import { IUser } from '@portal/shared/models/user.model';
import { LoginPageComponent } from '../login-page/login-page.component';
import { NotifyService } from '@portal/shared/services/notify.service';

type NewType = ChangeDetectorRef;

@Component({
    selector: 'app-otp-login-page',
    templateUrl: './otp-login-page.component.html',
    styleUrls: ['../auth.component.scss']
})
export class OtpLoginPageComponent extends ComponentBase implements OnInit {
    form: FormGroup = this.fb.group({
        otp: [''],
        otp1: ['', [Validators.required]],
        otp2: ['', [Validators.required]],
        otp3: ['', [Validators.required]],
        otp4: ['', [Validators.required]]
    });
    url: string = '';
    email: string = '';
    isLoading: boolean = false;
    alert: string = '';
    error: string = '';

    private autoVerify: boolean = false

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private notify: NotifyService
    ) {
        super();
        this.url = this.route.snapshot.params.url;
    }

    ngOnInit(): void {
        this.email = this.authService.userEmail;

        if (!this.email || !this.email.length) {
            this.router.navigateByUrl('login');
        }
        
        for(let i = 0; i < 5; i++){
            this.form.get(`otp${i + 1}`)?.valueChanges.subscribe(() => {
            this.autoVerify = true
            })
        }

        this.form.valueChanges.subscribe(() => {
            if(this.form.valid && this.autoVerify) {
                this.autoVerify = false
                this.onVerify()
            }
        })
    }

    onVerify = () => {
        if (!this.FormIsValid) {
            return;
        }

        this.setOtp()

        this.isLoading = true;
        this.error = ''
        const formValue = this.form.getRawValue();

        this.authService
            .loginOtp({ email: this.email, otp: formValue.otp })
            .pipe(
                takeUntil(this.destroyed$),
                catchError((err) => {
                    this.isLoading = false;
                    this.error = "error";
                    return of<IOtpLoginResult>();
                })
            )
            .subscribe((res: IOtpLoginResult) => {
                if (res.success) {
                    this.authService.setLoginResult(res);
                    this.loadUserProfile();
                } else {
                    this.isLoading = false;
                    this.error = "error";
                }
            });
    };

    loadUserProfile = () => {
        this.authService.getUserProfile()
            .pipe(
                takeUntil(this.destroyed$),
                catchError(() => of<IApiResult>()),
                finalize(() => this.isLoading = false)
            )
            .subscribe((res: IApiResult) => {
                if (res.success && res.profile) {
                    this.notify.success('Login', 'OTP Accepted');
                    this.authService.setUser(res.profile as IUser);

                    LoginPageComponent.isLogging = new EventEmitter();
                    LoginPageComponent.isLogging.subscribe(() => {
                        if (!this.authService.isLoggedIn()) {
                            this.authService.logout();
                        }
                    });

                    this.authService.redirectToPage();
                } else {
                    this.error = 'Something went wrong, please try again';
                }
            });
    };

    get FormIsValid() {
        return this.form && this.form.valid;
    }

    private setOtp(): void{
        let otp = ''
        for(let i = 0; i < 5; i++){
            otp += this.form.get(`otp${i + 1}`)?.value || ''
        }

        this.form.get('otp')?.patchValue(otp)

        this.form.get('otp')?.updateValueAndValidity()
    }

    public get isArabic(): boolean{
        return localStorage.getItem('lang') === "ar"
    }
}
