/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpStatusCode
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '@portal/auth/services/auth.service';
import { catchError, tap } from 'rxjs/operators';
import {
    INTERCEPTOR_SKIP_HEADER,
    ACCESS_CONTROL_EXPOSE_HEADERS,
    ACCESS_CONTROL_ALLOW_ORIGIN,
    ACCESS_CONTROL_ALLOW_METHODS,
    ACCESS_CONTROL_ALLOW_HEADERS
} from '@portal/shared/constants/common.constants';
import { Router } from '@angular/router';
import { NotifyService } from '@portal/shared/services/notify.service';

const B_JWT_TOKEN = `businessjwttoken`;
const X_FE_VERSION = `frontendversion`;

@Injectable()
export class HttpApiInterceptor implements HttpInterceptor {
    private readonly TokenExpiredError = 'TokenExpiredError';

    constructor(
        private authService: AuthService,
        private router: Router,
        private notify: NotifyService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.getToken();

        req.headers.set('Access-Control-Allow-Origin', ACCESS_CONTROL_ALLOW_ORIGIN);
        req.headers.set('Access-Control-Allow-Methods', ACCESS_CONTROL_ALLOW_METHODS);
        req.headers.set('Access-Control-Allow-Headers', ACCESS_CONTROL_ALLOW_HEADERS);
        req.headers.set('Access-Control-Expose-Headers', ACCESS_CONTROL_EXPOSE_HEADERS);

        if (req.headers.has(B_JWT_TOKEN)) {
            const bToken = req.headers.get(B_JWT_TOKEN);
            const headers = req.headers.delete(B_JWT_TOKEN).append('Authorization', `Bearer ${bToken}`);

            return next.handle(req.clone({ headers })).pipe(
                catchError((error) => {
                    return this.handleError(error);
                })
            );
        }

        if (req.headers.has(INTERCEPTOR_SKIP_HEADER)) {
            const headers = req.headers.delete(INTERCEPTOR_SKIP_HEADER).append('Authorization', `Bearer ${token}`);

            return next.handle(req.clone({ headers })).pipe(
                catchError((error) => {
                    return this.handleError(error);
                })
            );
        }

        const option = token
            ? {
                setHeaders: {
                    // authentication (Bearer) required
                    'Authorization': `Bearer ${token}`,
                    // to determine the Accept header
                    'Accept': 'application/json',
                    // to determine the Content-Type header
                    'Content-Type': 'application/json; charset=utf-8',
                    'Access-Control-Allow-Origin': ACCESS_CONTROL_ALLOW_ORIGIN,
                    'Access-Control-Allow-Methods': ACCESS_CONTROL_ALLOW_METHODS,
                    'Access-Control-Allow-Headers': ACCESS_CONTROL_ALLOW_HEADERS,
                    'Access-Control-Expose-Headers': ACCESS_CONTROL_EXPOSE_HEADERS,
                },
            }
            : {};

        const apiReq = req.clone(option);
        return next.handle(apiReq).pipe(
            tap((event) => {
                if (req.method === 'GET' && event instanceof HttpResponse) {
                    const remoteFrontEndVersion = event.headers.get(X_FE_VERSION);
                    const localFrontEndVersion = localStorage.getItem(X_FE_VERSION);
                    if (remoteFrontEndVersion !== localFrontEndVersion) {
                        localStorage.setItem(X_FE_VERSION, '1');
                        document.location.reload();
                    }
                }
            }),
            catchError((error) => {
                return this.handleError(error);
            })
        );
    }

    handleError = (error: HttpErrorResponse): Observable<any> => {
        if (error.error?.name?.includes(this.TokenExpiredError)) {
            this.notify.error('Authentication', 'Login expired');
            this.authService.logout();
            this.router.navigate(['/login']);
        } else if (error.status === HttpStatusCode.Unauthorized) {
            this.notify.error('Authentication', 'Unauthorized', error);
        }
        return throwError(error);
    };
}
