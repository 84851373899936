import { Pipe, PipeTransform } from "@angular/core";
import { IFoodOrder } from "@portal/canteen/models/food-order.model";
import {
    ITransactionAdditionalInfo,
    ITransactionFoodOrderId
} from "@portal/shared/services/transactions/transaction.model";
import { PaymentMethod, PaymentToken } from "../constants/payment.constants";
import { IOrder } from "@portal/merchant/models/merchant-order.model";

@Pipe({ name: 'paymentToken', pure: false })
export class PaymentTokenPipe implements PipeTransform {
    transform(source: IFoodOrder | ITransactionFoodOrderId | ITransactionAdditionalInfo | IOrder | undefined): string {
        if (!source) {
            return '';
        }

        if (source.paidByFace) {
            return PaymentToken.FaceRecognition
        }
        if (source.paidByQRCode) {
            return PaymentToken.QRCode
        }
        if (source.paymentMethod === PaymentMethod.CreditCard.toUpperCase()) {
            return PaymentToken.CreditCard;
        }
        if (source.paymentMethod === PaymentMethod.Mada.toUpperCase()) {
            return PaymentToken.Mada
        }
        if ((source.cardOrWristbandScanned?.cardSerialNo || source.student?.user?.cardSerialNo ||  '').startsWith('WC') || source.cardOrWristbandScanned?.itemType === "CARD") {
            return PaymentToken.Card
        }
        if ((source.cardOrWristbandScanned?.cardSerialNo || source.student?.user?.cardSerialNo ||  '').startsWith('WW') || source.cardOrWristbandScanned?.itemType === "WRISTBAND") {
            return PaymentToken.Wristband
        }

        return '';
    }
}