import * as _ from "lodash";
import { PERMISSIONS } from "./claim.constants";
import { NAV_MENU } from "../../enums/nav-menu.enum";
import { isNullOrEmptyString } from "../../functions/string.function";
import { IPagePermissions, IUserType } from "../permission.model";

export function getNavMenuByPageName(page: string): string {
    const nav_menu = Object.entries(NAV_MENU).find(([key, _]) => key === page);
    return nav_menu ? nav_menu[1] : '';
}

export function getPageClaimValues(page: string, permissions: { [key: string]: IPagePermissions } | { [key: string]: string }) {
    return Object.values(permissions).map(val => `${page}.${val}`);
}

export const getClaimLabelsByType = (type: IUserType) => {
    const labels: string[] = [];
    Object.keys(PERMISSIONS).forEach((mainPage: string) => {
        if (mainPage === 'HOME') {
            labels.push(getNavMenuByPageName(mainPage));
            return;
        }
        if (type === IUserType.School) {
            if (mainPage === 'SCHOOLS') {
                const schoolSections = Object.keys(PERMISSIONS.SCHOOLS).map((subPage) => getNavMenuByPageName(subPage));
                labels.push(...schoolSections);
                return;
            }
        }
        if ([IUserType.Canteen, IUserType.Merchant].includes(type) && mainPage === 'REPORTS') {
            labels.push(getNavMenuByPageName(`${type.toUpperCase()}S_${mainPage}`));
            return;
        }
        if ([IUserType.Canteen, IUserType.Merchant].includes(type) && ['TRANSACTIONS', 'TRANSFER_REQUESTS', 'FEES'].includes(mainPage)) {
            labels.push(getNavMenuByPageName(mainPage));
            return;
        }
        if (type === IUserType.Canteen && mainPage === 'CANTEENS') {
            const sections = Object.keys(PERMISSIONS.CANTEENS).map((subPage) => getNavMenuByPageName(subPage));
            labels.push(...sections);
            return;
        }
        if (type === IUserType.Merchant && mainPage === 'MERCHANTS') {
            const sections = Object.keys(PERMISSIONS.MERCHANTS).map((subPage) => getNavMenuByPageName(subPage));
            labels.push(...sections);
            return;
        }
        if ([IUserType.School, IUserType.Canteen].includes(type) && mainPage === 'NOTIFICATIONS') {
            labels.push(NAV_MENU.PUSH_NOTIFICATION);
            labels.push(NAV_MENU.NOTIFICATION_HX);
            return;
        }
        if (mainPage === 'BUSINESS_STORE') {
            labels.push(getNavMenuByPageName(mainPage));
            return;
        }
    })
    return _.uniq(labels.filter((label) => !isNullOrEmptyString(label)));
}

export const getClaimValuesByType = (type: IUserType): string[] => {
    const homeClaims = Object.keys(PERMISSIONS.HOME).map((key) => getPageClaimValues('HOME', PERMISSIONS.HOME[key])).flat();
    const businessStoreClaims = Object.keys(PERMISSIONS.BUSINESS_STORE).map((key) => getPageClaimValues('BUSINESS_STORE', PERMISSIONS.BUSINESS_STORE[key])).flat();
    businessStoreClaims.push(
        ...Object.keys(PERMISSIONS.BUSINESS_ORDERS).map((key) => getPageClaimValues('BUSINESS_ORDERS', PERMISSIONS.BUSINESS_ORDERS[key])).flat()
    )
    
    const claims = [];
    if (type === IUserType.School) {
        claims.push(
            ...Object.keys(PERMISSIONS.SCHOOLS).map((key) => {
                return Object.keys(PERMISSIONS.SCHOOLS[key]).map((subKey) => getPageClaimValues(key, PERMISSIONS.SCHOOLS[key][subKey]));
            }).flat(2)
        );
        claims.push(...Object.keys(PERMISSIONS.NOTIFICATIONS).map((key) => getPageClaimValues('NOTIFICATIONS', PERMISSIONS.NOTIFICATIONS[key])).flat());
    } else {
        const homePermissions = type === IUserType.Canteen ? PERMISSIONS.CANTEENS.HOME : PERMISSIONS.MERCHANTS.HOME;
        homeClaims.push(...Object.keys(homePermissions).map((key) => getPageClaimValues('HOME', homePermissions[key])).flat());

        claims.push(
            ...Object.keys(PERMISSIONS.TRANSACTIONS).map((key) => getPageClaimValues('TRANSACTIONS', PERMISSIONS.TRANSACTIONS[key])).flat(),
            ...Object.keys(PERMISSIONS.TRANSFER_REQUESTS).map((key) => getPageClaimValues('TRANSFER_REQUESTS', PERMISSIONS.TRANSFER_REQUESTS[key])).flat(),
            ...Object.keys(PERMISSIONS.FEES).map((key) => getPageClaimValues('FEES', PERMISSIONS.FEES[key])).flat()
        );
    }
    if (type === IUserType.Canteen) {
        claims.push(
            ...getPageClaimValues('CANTEENS_REPORTS', PERMISSIONS.REPORTS.VIEW_CANTEENS_REPORTS),
            ...Object.keys(PERMISSIONS.CANTEENS).map((key) => {
                return Object.keys(PERMISSIONS.CANTEENS[key]).map((subKey) => getPageClaimValues(key, PERMISSIONS.CANTEENS[key][subKey]));
            }).flat(2),
            ...Object.keys(PERMISSIONS.NOTIFICATIONS).map((key) => getPageClaimValues('NOTIFICATIONS', PERMISSIONS.NOTIFICATIONS[key])).flat()
        );
    }
    if (type === IUserType.Merchant) {
        claims.push(
            ...getPageClaimValues('MERCHANTS_REPORTS', PERMISSIONS.REPORTS.VIEW_MERCHANTS_REPORTS),
            ...Object.keys(PERMISSIONS.MERCHANTS).map((key) => {
                return Object.keys(PERMISSIONS.MERCHANTS[key]).map((subKey) => getPageClaimValues(key, PERMISSIONS.MERCHANTS[key][subKey]));
            }).flat(2)
        );
    }

    return _.uniq([
        ...homeClaims,
        ...claims,
        ...businessStoreClaims,
    ].filter((claim) => !isNullOrEmptyString(claim)));
}