/* eslint-disable prettier/prettier */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'translation', pure: false })
export class TranslationPipe implements PipeTransform {
    transform(value: any): string {
        if (typeof value === 'string') {
            return value;
        }

        const transObj = Array.isArray(value) ? value[0]?.translations : value;

        if (!transObj || (!transObj.en && !transObj.ar)) {
            return '';
        }

        return localStorage.getItem('lang') === 'ar' ? transObj.ar : (transObj.en || transObj.ar);
    }
}
