import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss']
})
export class SecondaryButtonComponent {
  @Input() disabled: boolean = false;
  @Input() text = 'Exit';
  @Input() addClass:any;
  @Input() style:any;
  @Output() clickButton = new EventEmitter();

}
