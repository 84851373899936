import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DefaultLanguage, LanguageKey } from './language.model';
import { isNullOrEmptyString } from '@portal/shared/functions/string.function';

@Injectable({ providedIn: 'root' })
export class LanguageService {
    public get languageChanged$(): Observable<string> {
        return this.subject$.asObservable();
    }

    private subject$ = new BehaviorSubject<string>('');

    constructor(private translate: TranslateService) {
        this.translate.addLangs(['en', 'ar']);
        this.translate.setDefaultLang('en');
        this.load();
    }

    public load() {
        if (isNullOrEmptyString(this.subject$.value)) {
            const lang = localStorage.getItem(LanguageKey) ?? DefaultLanguage;
            this.subject$.next(lang ?? DefaultLanguage);
        }
    }

    public updateLanguage(language: string): void {
        this.translate.use(language);
        this.subject$.next(language);
    }

    public setDefault(): void {
        return this.updateLanguage(DefaultLanguage);
    }

    public languageChanged(): Observable<string> {
        return this.subject$.asObservable();
    }
}
