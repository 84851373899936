import * as moment from "moment";
import { today, tomorrow } from "./date.functions";

export function isToday(dateDate: moment.MomentInput): boolean {
  const now = moment();
  const dateMoment = moment(dateDate);
  return dateMoment.date() === now.date() && dateMoment.month() === now.month() && dateMoment.year() === now.year();
}

export function isDateTodayOrPast(dateDate: moment.MomentInput, format?: string): boolean {
    const date = moment(dateDate, format);
    return date < tomorrow();
}

export function isDateTodayOrLater(dateDate: moment.MomentInput, format?: string): boolean {
  const date = moment(dateDate, format);
  return date >= today();
}

export function isTimeBeforeTime(date1: moment.MomentInput | string, date2: moment.MomentInput | string) {
  return moment(date1).isBefore(moment(date2));
}