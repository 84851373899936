<div class="modal-header">
    <h5 class="header-text">Hide/Show Columns</h5>
    <button type="button" class="closeButton fa fa-times fa-sm"
    (click)="close()"></button>
</div>
<div class="modal-body p-3 position-relative">
    <form [formGroup]="form" class="sticky__search_bar">
        <div class="form-group col-12">
            <input
                type="text"
                [placeholder]="'Search Phrase' | translate"
                class="form-control"
                formControlName="search"
            />
        </div>
    </form>
    <ng-container *ngIf="!columns.length">
        <h6 class="mt-4" translate>No Columns Found</h6>
    </ng-container>

    <!-- <app-spinner-border [showSpinner]="isLoading"></app-spinner-border> -->

    <ng-container *ngIf="columns.length">
        <div class="tableContainer table-section table-responsive mt-3">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th translate class="col-1 align-middle">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="toggleAll"
                                [checked]="
                                    columns.length == selectedColumns.length
                                "
                                (change)="toggleAll($event)"
                            />
                        </th>
                        <th translate class="col">Column</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container
                        *ngFor="let column of filteredColumns; let i = index"
                    >
                        <tr>
                            <td class="col-1 align-middle">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    [id]="i"
                                    [checked]="
                                        selectedColumns.includes(column.value)
                                    "
                                    (change)="
                                        onColumnCheckBoxClick($event, column)
                                    "
                                />
                            </td>
                            <td class="col align-middle">
                                <label [for]="i">{{ column.text }}</label>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>
<div class="d-flex gap-4 justify-content-center mb-3">
    <app-primary-button
        text="Done"
        (clickButton)="save()"
    ></app-primary-button>
</div>
