/* eslint-disable prettier/prettier */
import { ITranslation } from "./translation.model";

export interface ISelectOption {
    value: string;
    label: string;
    translations?: ITranslation | ITranslation[];
}

export const dayOfWeekOptions = [
    { label: 'Sunday', value: 'Sunday' },
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' },
];
