import { FormControl, ValidatorFn } from '@angular/forms';
import { AbstractValidator } from './validation/abstrac-validator.service';

export class ConditionalValidator extends AbstractValidator {
  static failWhen(condition: () => boolean, control: FormControl, errorMessage?: string): ValidatorFn {
    return new ConditionalValidator(condition, control, errorMessage, true).getValidatorFn();
  }

  constructor(
    private condition: () => boolean,
    private control: FormControl,
    private errorMessage = "Failed",
    private failOnConditionMet: boolean
  ) {
    super();
    if (this.control) {
        this.setControls(this.control);
    }
  }

  getValidationErrors(control: FormControl): string | any | null {
    const conditionMet = this.condition();

    if ((conditionMet && this.failOnConditionMet) || (!conditionMet && !this.failOnConditionMet)) {
      return this.errorMessage;
    }

    return null;
  }
}
