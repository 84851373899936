/* eslint-disable prettier/prettier */
import { Pipe, PipeTransform } from '@angular/core';
import { ITransactionExternalPaymentObjectSource } from '@portal/shared/services/transactions/transaction.model';
@Pipe({ name: 'payment', pure: false })
export class PaymentPipe implements PipeTransform {
    transform(source: ITransactionExternalPaymentObjectSource | undefined): string {
        if (!source) {
            return '';
        }

        const payment = (source?.type).toLowerCase().replace(' ', '');
        const company = (source?.company).toLowerCase().replace(' ', '');

        if (!payment || !payment?.length || !company || !company.length) {
            return '';
        }

        if (payment === 'creditcard') {
            if (company === 'mada') {
                return 'Mada';  
            }

            if (['mastercard', 'master'].includes(company)) {
                return 'Master';
            }

            if (company === 'visa') {
                return 'Visa';
            }
        }

        if (payment === 'applepay') {
            if (company === 'visa') {
                return 'Apple Pay - Visa';
            }

            if (['mastercard', 'master'].includes(company)) {
                return 'Apple Pay - Master';
            }

            if (company === 'mada') {
                return 'Apple Pay - Mada';
            }
        }

        if (payment === 'stcpay') {
            if (company === 'stc') {
                return 'STC PAY';
            }
        }

        return source?.type || '';
    }
}
