import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentBase } from '@portal/shared/components/component-base';

@Component({
    selector: 'app-otp-login-form',
    templateUrl: './otp-login-form.component.html',
    styleUrls: ['../../auth.component.scss']
})
export class OtpLoginFormComponent extends ComponentBase {
    @Input() form: FormGroup | undefined;
    @Input() disableVerifyButton: boolean = true;
    @Input() isLoading: boolean = false;
    @Input() error: string = '';
    @Input() isArabic: boolean = false;
    @Output() verify = new EventEmitter();

    constructor() {
        super();
        this.focusOnInput(1)
    }

    @ViewChild('otp1') otp1!: ElementRef;
    @ViewChild('otp2') otp2!: ElementRef;
    @ViewChild('otp3') otp3!: ElementRef;
    @ViewChild('otp4') otp4!: ElementRef;

    validateNumericInput(evt: any, order:number = 0) {
        var theEvent = evt || window.event;
        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        
        var regex = /[0-9]|\./;
        if ((key == '.' || !regex.test(key)) && evt.key !== 'Enter') {
            theEvent.returnValue = false;
        }
         else  {
            switch(order){
                case 1:
                    this.focusOnInput(2)
                    break;
                case 2:
                    this.focusOnInput(3)
                    break;
                case 3:
                    this.focusOnInput(4)
                    break;
                default:
                    break;
            }
        }

    }

    public backspaceHandler(evt: any, order:number = 0):void {
        if (evt.key === 'Backspace' && evt.target.value === ''){
            switch(order){
                case 2:
                    this.focusOnInput(1)
                    break;
                case 3:
                    this.focusOnInput(2)
                    break;
                case 4:
                    this.focusOnInput(3)
                    break;
                default:
                    break;
            }
        }

        if (evt.key === 'ArrowRight' || evt.key === 'ArrowLeft' || evt.key === 'ArrowUp' || evt.key === 'ArrowDown'){
            evt.returnValue = false;
        }
    }

    public forceSelectAll(order:number): void {
        if (this.form?.invalid && this.form.get('otp1')?.invalid){
            order = 1
        }
        switch(order){
            case 1:
                this.otp1.nativeElement.select();
                break;
            case 2:
                this.otp2.nativeElement.select();
                break;
            case 3:
                this.otp3.nativeElement.select();
                break;
            case 4:
                this.otp4.nativeElement.select();
                break;
            default:
                break;
        }
    }

    public focusOnInput(order:number){
        switch(order){
            case 1:
                setTimeout(()=>{
                    this.otp1.nativeElement.focus();
                }, 50)
                break;
            case 2:
                setTimeout(()=>{
                    this.otp2.nativeElement.focus();
                }, 50)
                break;
            case 3:
                setTimeout(()=>{
                    this.otp3.nativeElement.focus();
                }, 50)
                break;
            case 4:
                setTimeout(()=>{
                    this.otp4.nativeElement.focus();
                }, 50)
                break;
            default:
                break;
        }
    }

    public pasteHandler(evt:any): void {
        evt.preventDefault()

        let regex = /[0-9]|\./;

        let pasteText = evt.clipboardData.getData('text/plain')

        for (let i = 0; i < 4 ; i++){
            if (regex.test(pasteText[i])){
                this.form?.get(`otp${i+1}`)?.setValue(pasteText[i])
                this.focusOnInput(i + 2)
            }
            else return
        }
        this.form?.updateValueAndValidity()
    }
}
