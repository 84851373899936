import { Injectable } from '@angular/core';
import { RouteAuthGuardService } from '@portal/shared/guards/route-auth-guard.service';
import { UserClientContext } from '@portal/shared/models/user-client-context.model';
import { hasNotificationsPermission } from '@portal/shared/permissions/permission';

@Injectable({
    providedIn: 'root',
})
export class NotificationGuardService extends RouteAuthGuardService {
    static canAccess = (context: UserClientContext, isRequired: boolean): boolean => isRequired ? hasNotificationsPermission(context) : true;

    protected canAccess = (context: UserClientContext): boolean => NotificationGuardService.canAccess(context, this.featureFlags.enableUserAccess);
}