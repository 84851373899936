/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AppUserSubType, Credentials, defaultUser, IForgotPassword, ILoginOTP, IUser } from '@portal/shared/models/user.model';
import { ILoginResult, IOtpLoginResult } from '../models/login-result.model';
import { IApiResult } from '@portal/shared/models/api-result.model';
import { ISchool } from '@portal/school/models/school.model';
import { translationLang } from '@portal/shared/functions/translate-language';
import { ITranslation } from '@portal/shared/models/translation.model';
import { AuthLoginService } from './auth-login/auth-login.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
    hasPermissionToGetUserList,
    hasAdminAccess,
    hasAdminOrSuperAdminAccess,
    hasCallSystemUserAccess,
    hasCanteenOrSuperCanteenAccess,
    hasCanteenUserAccess,
    hasSchoolOrSuperSchoolAccess,
    hasSchoolUserAccess,
    hasSuperAdminAccess,
    hasSuperCanteenUserAccess,
    hasSuperSchoolUserAccess,
    hasMerchantOrSuperMerchantAccess,
    hasMerchantUserAccess,
    hasSuperMerchantUserAccess,
    hasCanteenAppUserAccess,
    hasMerchantAppUserAccess
} from '@portal/shared/permissions/permission';
import { AppComponent } from '@portal/app.component';
import { UserClientContext } from '@portal/shared/models/user-client-context.model';
import { LocalStorageService } from '@portal/shared/services/local-storage/local-storage.service';

const JWT_TOKEN = `jwttoken`;
const WAKI_USER = 'wakiUser';
const WAKI_USER_EMAIL_KEY = 'wakiUserEmail';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public user: IUser = defaultUser;
    public userEmail = '';
    public userRoles: string[] = [];
    public userClaims: string[] = [];

    constructor(
        private service: AuthLoginService,
        private cookieService: CookieService,
        private localStorageService: LocalStorageService
    ) { }

    public static getCurrentUserToken = () => {
        const wakiUser = localStorage.getItem(WAKI_USER);

        if (wakiUser === 'undefined') {
            return null;
        }

        const user = JSON.parse(wakiUser!) as IUser;
        return user?.token || '';
    }

    public login(credentials: Credentials): Observable<ILoginResult> {
        return this.service.loginByEmail(credentials);
    }

    public loginOtp(loginOtp: ILoginOTP): Observable<IOtpLoginResult> {
        return this.service.verifyLoginOTP(loginOtp);
    }

    public forgotPasswordStepOne = (email: string): Observable<IApiResult> => {
        return this.service.forgotPassword({ email }, '1');
    };

    public forgotPasswordStepTwo = (email: string, verificationCode: string): Observable<IApiResult> => {
        const request: IForgotPassword = {
            email: email,
            verificationCode: verificationCode
        };
        return this.service.forgotPassword(request, '2')
    };

    public forgotPasswordStepThree = (email: string, newPassword: string) => {
        const request: IForgotPassword = {
            email: email,
            newPassword: newPassword
        };
        return this.service.forgotPassword(request, '3')
    };

    public getUserProfile(): Observable<IApiResult> {
        return this.service.getMyProfile().pipe(
            tap((res) => {
                if (res.success && res.profile) {
                    this.setUser(res.profile);
                }
            })
        );
    }

    public isLoggedIn = (): boolean => {
        if (!this.user || !this.user.token) {
            const usr = this.localStorageUser;

            if (!usr || !usr.token) {
                return false;
            }

            this.userEmail = usr.email;
            this.setUser(usr);
            this.setToken(usr.token);
        }

        return true;
    };

    public setUser = (user: IUser): void => {
        this.user = { ...this.user, ...user };
        this.userRoles = this.user.roles || [];
        this.userClaims = this.user.claims || [];
        this.localStorageService.set(WAKI_USER, this.user);
    };

    public get localStorageUser(): IUser | null {
        return this.localStorageService.get(WAKI_USER) as IUser;
    }

    public get localEmail(): string {
        return this.localStorageService.get(WAKI_USER_EMAIL_KEY);
    }

    public setStorageUser = (user: IUser): void => {
        this.user = user;
        this.localStorageService.set(WAKI_USER, user);
    };

    public setUserEmail = (email: string): void => {
        this.userEmail = email;
        this.localStorageService.set(WAKI_USER_EMAIL_KEY, { email: email });
    };

    public setUserRoles = (): string[] => {
        const rolesString = this.localStorageUser && this.localStorageUser.roles;
        if (!rolesString) {
            return [];
        }
        return rolesString;
    };

    public setUserClaims = (): string[] => {
        const claimsString = this.localStorageUser && this.localStorageUser.claims;
        if (!claimsString) {
            return [];
        }
        return claimsString;
    };

    public setUserSubType = (): AppUserSubType | null => {
        const type = this.localStorageUser?.businessAppUserSubType;
        if (!type) {
            return null;
        }
        return type;
    }

    private setToken(token: string): void {
        this.cookieService.set(JWT_TOKEN, token);
    };

    private clearUser(): void {
        this.user = defaultUser;
        this.localStorageService.remove(WAKI_USER);
        this.clearUserEmail();
        this.cookieService.delete(JWT_TOKEN);
    };

    private clearUserEmail(): void {
        this.userEmail = '';
        this.localStorageService.remove(WAKI_USER_EMAIL_KEY);
    };

    public isAdminOrSuperAdmin = (): boolean => hasAdminOrSuperAdminAccess(this.setUserRoles())

    public isSuperAdmin = (): boolean => hasSuperAdminAccess(this.setUserRoles())

    public isAdmin = (): boolean => hasAdminAccess(this.setUserRoles())

    public isSchoolOrSuperSchool = (): boolean => hasSchoolOrSuperSchoolAccess(this.setUserRoles())

    public isSuperSchoolUser = (): boolean => hasSuperSchoolUserAccess(this.setUserRoles())

    public isSchoolUser = (): boolean => hasSchoolUserAccess(this.setUserRoles())

    public isCanteenOrSuperCanteen = (): boolean => hasCanteenOrSuperCanteenAccess(this.setUserRoles())

    public isSuperCanteenUser = (): boolean => hasSuperCanteenUserAccess(this.setUserRoles())

    public isCanteenUser = (): boolean => hasCanteenUserAccess(this.setUserRoles())

    public isMerchantOrSuperMerchant = (): boolean => hasMerchantOrSuperMerchantAccess(this.setUserRoles())

    public isSuperMerchantUser = (): boolean => hasSuperMerchantUserAccess(this.setUserRoles())

    public isMerchantUser = (): boolean => hasMerchantUserAccess(this.setUserRoles())

    public isCallSystemUser = (): boolean => hasCallSystemUserAccess(this.setUserRoles())

    public canGetUserList = (): boolean => hasPermissionToGetUserList(this.setUserRoles())

    getToken = (): string | null | undefined => {
        const user = this.localStorageUser;
        return user && user.token;
    };

    getUserCanteenId = (): string => {
        return this.localStorageUser?.canteens[0]?._id || '';
    };

    getUserCanteenName = (): string => {
        return translationLang(this.localStorageUser?.canteens[0]?.translations);
    };

    getUserCanteenTranslations = (): ITranslation | undefined => {
        return this.localStorageUser?.canteens[0]?.translations;
    };


    getUserCanteenCode = (): string => {
        return this.localStorageUser?.canteens[0]?.canteenId || '';
    };

    getUserSchoolId = (): string => {
        return this.localStorageUser?.schools[0]?._id || '';
    };

    getUserSchoolName = (): string => {
        return translationLang(this.localStorageUser?.schools[0]?.translations);
    };

    getUserSchoolTranslations = (): ITranslation | undefined => {
        return this.localStorageUser?.schools[0]?.translations;
    };

    getUserSchools = (): ISchool[] => {
        return this.localStorageUser?.schools || [];
    };

    getUserMerchantId = (): string => {
        return this.localStorageUser?.merchants[0]?._id || '';
    };

    getUserMerchantName = (): string => {
        return translationLang(this.localStorageUser?.merchants[0]?.translations);
    };

    getUserMerchantTranslations = (): ITranslation | undefined => {
        return this.localStorageUser?.merchants[0]?.translations;
    };

    getCurrentUserId() {
        return this.localStorageUser?._id || '';
    }

    public logout = (): void => {
        this.clearUser();
        sessionStorage.clear();
        this.removeZohoChat();
    }

    private removeZohoChat(): void {
        document.querySelector('.zsiq_float')?.remove();

        const scripts = document.scripts;
        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].id === 'zsiqchat') {
                scripts[i].remove();
            }
        }
    }

    public get defaultNavigationPath() {
        if (this.isCallSystemUser()) {
            return '/schools/dismissal-call';
        }
        return '/home';
    }

    public setLoginResult(loginResult: IOtpLoginResult): void {
        this.setUser({
            ...this.user,
            isProfileSetup: loginResult.isProfileSetup,
            permissions: loginResult.permissions,
            token: loginResult.token,
            refreshToken: loginResult.refreshToken,
            roles: loginResult.roles
        });

        this.setToken(loginResult.token);
    }

    public redirectToPage = () => {
        if (this.isCallSystemUser()) {
            window.location.href = this.defaultNavigationPath;
            return;
        }

        const redirectTo = this.localStorageService.get('waki_redirect');
        if (redirectTo && redirectTo !== 'undefined') {
            window.location.href = redirectTo;
            return;
        }

        window.location.href = this.defaultNavigationPath;
    };

    public getUserClientContext = (): Observable<UserClientContext> => {
        if (this.localStorageUser) {
            return of({
                roles: this.setUserRoles(),
                claims: this.setUserClaims(),
                translations: this.localStorageUser?.translations,
                user: this.localStorageUser,
            });
        }
        return this.service.getMyProfile().pipe(
            catchError(() => {
                this.logout();
                return of(null);
            }),
            switchMap((res) => {
                const profile = res?.profile;
                return of({
                    roles: profile?.roles ?? this.setUserRoles(),
                    claims: profile?.claims ?? this.setUserClaims(),
                    translations: profile?.translations ?? this.localStorageUser?.translations,
                    user: profile ?? this.localStorageUser ?? undefined,
                })
            })
        )
    }
}
