/* eslint-disable prettier/prettier */
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '@portal/auth/services/auth.service';
import { ComponentBase } from '@portal/shared/components/component-base';
import { UPLOAD_FILE_SIZE_LIMIT_IN_BYTE } from '@portal/shared/constants/common.constants';
import { b64toBlob } from '@portal/shared/functions/convert-b64toBlob';
import { BusinessTypeEnum } from '@portal/shared/models/merchant-signup.model';
import { ISelectOption } from '@portal/shared/models/select-option.model';
import { NotifyService } from '@portal/shared/services/notify.service';


@Component({
  selector: 'app-merchant-sign-up-info-form',
  templateUrl: './merchant-sign-up-info-form.component.html',
  styleUrls: ['./merchant-sign-up-info-form.component.scss']
})
export class MerchantSignUpDoc extends ComponentBase {
  @Input() form: FormGroup | undefined;
  @Input() dataForm: FormGroup | undefined;
  @Input() formFields: string[] = [];
  @Output() submitInfo = new EventEmitter();

  merchantSignUpEnum = BusinessTypeEnum

  public isAdmin: boolean = false

  constructor(private notify: NotifyService, private cd: ChangeDetectorRef,private authService: AuthService) {
    super();
    this.isAdmin = this.authService.isAdminOrSuperAdmin();
  }

  onFilePicked = (event: any, control: string) => {
    if (event.target?.files?.length > 0) {
      const currentFile = event.target?.files[0];
      const fileName = currentFile.originalname || currentFile.name || '';
      console.log('control', control)
        if (!fileName.match(/\.(pdf|png|jpg|jpeg)$/)) {
          this.notify.error('Upload File', 'Please upload a valid file!');
        } else if (!this.fileSizeAllowed(currentFile)) {
          this.notify.error('File Size Limit', 'File size must be lower than 4 MB');
        } else {
          if (currentFile) {
            var reader = new FileReader();
    
            reader.readAsDataURL(currentFile);
            reader.onload = () => {
              const resultStr = (reader.result || '').toString();
              const b64Image = resultStr.replace('data:', '').replace(/^.+,/, '');
    
              this.form?.get(control)?.setValue(currentFile);
              this.form?.get(control)?.updateValueAndValidity();
              this.cd.detectChanges();
            };
          }
        }
    }
  };

  fileSizeAllowed = (file: any) => {
    return file && file.size < UPLOAD_FILE_SIZE_LIMIT_IN_BYTE;
  };

  onSubmission = (e: any) => {
    e.preventDefault();
    this.submitInfo.emit();
  }

  public getDocData (docType: string): any {
    return this.dataForm?.get(`documents.${docType}`)?.value
  }

  public viewDoc (docType: string): any {
    (window as any).open(this.getDocData(docType), "_blank");
  }

  public disableViewIcon(docType:string): boolean {
    return this.form?.get(docType)?.value !== null || this.dataForm?.get(`documents.${docType === 'nationalID' ? 'nationalId' : docType}`)?.value === null
  }

}
