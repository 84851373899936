/* eslint-disable prettier/prettier */
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@portal/auth/services/auth.service';
import { ComponentBase } from '@portal/shared/components/component-base';
import { IApiResult } from '@portal/shared/models/api-result.model';
import { NotifyService } from '@portal/shared/services/notify.service';
import { mustMatch } from '@portal/shared/validators/password-validators';
import { of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-backup-forgot-password-page',
    templateUrl: './forgot-password-page.component.html',
    styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent extends ComponentBase {
    public currentStep: number = 1;
    public isLoading = false;
    public form: FormGroup = this.fb.group(
        {
            email: [null, [Validators.required]],
            validationCode: [null, [Validators.required]],
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]]
        },
        {
            validators: mustMatch('password', 'confirmPassword')
        }
    );
    public notValid: boolean = false;

    private get email() {
        return this.form.get('email') as FormControl;
    }

    public get validationCode() {
        return this.form.get('validationCode') as FormControl;
    }

    get password() {
        return this.form.get('password') as FormControl;
    }

    constructor(
        private authService: AuthService,
        private router: Router,
        private fb: FormBuilder,
        private notify: NotifyService
    ) {
        super();
    }

    processStepOne = () => {
        if (this.currentStep !== 1 || !this.email.valid) {
            return;
        }
        this.isLoading = true;

        this.authService
            .forgotPasswordStepOne(this.email.value)
            .pipe(
                tap((result: IApiResult) => {
                    if (result.success) {
                        this.notify.success('Forgot Password', 'We have sent you validation code, please check your email');
                    }
                    this.nextStep();
                    this.isLoading = false;
                }),
                takeUntil(this.destroyed$),
                catchError((err) => {
                    this.notify.error('Forgot Password', 'Password recovery faild, please try again later', err);
                    this.isLoading = false;
                    return of();
                })
            )
            .subscribe();
    };

    processStepTwo = () => {
        if (this.currentStep !== 2 || !this.validationCode.valid) {
            return;
        }
        this.isLoading = true;
        this.notValid = false;

        this.authService
            .forgotPasswordStepTwo(this.email.value, this.validationCode.value)
            .pipe(
                tap((result: IApiResult) => {
                    if (result.success) {
                        this.notify.success('Forgot Password', 'Validation code accepted, please provide new password');
                        this.nextStep();
                    } else {
                        this.notValid = true;
                    }
                    this.isLoading = false;
                }),
                takeUntil(this.destroyed$),
                catchError((err) => {
                    this.notify.error('Forgot Password', 'Password recovery faild, please try again later', err);
                    this.isLoading = false;
                    return of();
                })
            )
            .subscribe();
    };

    processStepThree = () => {
        if (this.currentStep !== 3 || !this.password.valid) {
            return;
        }
        this.isLoading = true;

        this.authService
            .forgotPasswordStepThree(this.email.value, this.password.value)
            .pipe(
                tap((result: IApiResult) => {
                    if (result.success) {
                        this.notify.success('Forgot Password', 'Password change success, please proceed to login');
                        this.goToLoginPage();
                    } else {
                        this.notValid = true;
                        this.isLoading = false;
                    }
                }),
                takeUntil(this.destroyed$),
                catchError((err) => {
                    this.notify.error('Forgot Password', 'Password recovery faild, please try again later', err);
                    this.isLoading = false;
                    return of();
                })
            )
            .subscribe();
    };

    private nextStep = () => {
        this.currentStep++;
    };

    private previousStep = () => {
        this.currentStep--;
    };

    private goToLoginPage = () => {
        this.router.navigateByUrl('backup-login');
    };

    private goToFirstStep = () => {
        this.currentStep = 1;
    };
}
