import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-border',
  templateUrl: './spinner-border.component.html'
})
export class SpinnerBorderComponent {
  @Input() showSpinner = false;
  @Input() showWaitingMessage = false;
  @Input() marginTop = 'mt-5';
}
