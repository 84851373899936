<div class="container-fluid p-0 m-0" [class.h-100]="app.isCallPage" [class.rtl]="app.textDir === 'rtl'"
    (window:resize)="onCurrentPlatform()">
    <router-outlet></router-outlet>
</div>

<div class="cdk-overlay-container" *ngIf="isLoadingFullscreen">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
    <div class="cdk-global-overlay-wrapper justify-content-center align-items-center" dir="ltr">
        <app-spinner-border [showSpinner]="true"></app-spinner-border>
    </div>
</div>