<div class="modal-header">
    <h5 class="modal-title" translate>
        {{ 'Cart' | translate }} - <span>{{ cartCounts }} {{ (cartCounts < 2 ? 'item' : 'items') | translate }}</span>
    </h5>
</div>
<div class="modal-body" style="overflow-x: hidden;">
    <app-spinner-border [showSpinner]="isLoading"></app-spinner-border>
    <h6 translate *ngIf="(!myCart || myCart?.items?.length === 0) && !isLoading">You don't have any items on cart.</h6>

    <div *ngIf="myCart" class="row my_cart">
        <ng-container *ngFor="let item of filteredCart.items; let last = last">
            <div class="mb-3" *ngIf="item">
                <div class="row d-flex justify-content-between align-items-center">
                    <div class="col-4 col-md-1 item-img mb-2">
                        <img *ngIf="item.consumable.type === 'PRODUCT'" [src]="'assets/images/biz_device.png'">
                        <img *ngIf="item.consumable.type === 'SUBSCRIPTION'" [src]="'assets/images/biz_subs.png'">
                        <img *ngIf="item.consumable.type === 'USER'" [src]="'assets/images/biz_user.png'">
                    </div>
                    <div class="col-8 col-md-4 mb-2">
                        <h5 class="color-black mb-2">{{ item.consumable.title | translation }}</h5>

                        <div class="selection-section" *ngIf="(item.consumable.type === 'SUBSCRIPTION' && isCanteenUser) || isAdmin">
                            <div class="form-group">
                                <ng-multiselect-dropdown
                                    class="p-0"
                                    [placeholder]="'Select Schools' | translate"
                                    [settings]="dropdownSettings"
                                    [data]="schoolOptions"
                                    [disabled]="isProceedingOrder || isDeleting"
                                    [(ngModel)]="item.selectedSchools"
                                    (onSelect)="onItemSelect(item)"
                                    (onSelectAll)="onItemSelect(item)"
                                    (onDeSelect)="onItemSelect(item)">
                                    <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
                                        <span [class.f-bold]="isSelected">{{ option | translation }}</span>
                                    </ng-template>
                                    <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                                        {{option | translation}}
                                    </ng-template>
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="form-group mt-2" *ngIf="isAdmin">
                                <ng-multiselect-dropdown
                                    class="p-0"
                                    [placeholder]="'Select Canteens' | translate"
                                    [settings]="dropdownSettings"
                                    [data]="canteenOptions"
                                    [disabled]="isProceedingOrder || isDeleting"
                                    [(ngModel)]="item.selectedCanteens"
                                    (onSelect)="onItemSelect(item)"
                                    (onSelectAll)="onItemSelect(item)"
                                    (onDeSelect)="onItemSelect(item)">
                                    <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
                                        <span [class.f-bold]="isSelected">{{ option | translation }}</span>
                                    </ng-template>
                                    <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                                        {{option | translation}}
                                    </ng-template>
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 mb-2 align-self-start">
                        <div class="d-flex float-end">
                            <ng-container *ngIf="item.consumable.type !== 'SUBSCRIPTION'">
                                <div class="quantity-section">
                                    <button
                                        type="button"
                                        class="btn btn-link px-2"
                                        [disabled]="isProceedingOrder || isDeleting"
                                        (click)="onChangeQty(item, 'minus')">
                                        <i class="fas fa-minus"></i>
                                    </button>
        
                                    <div class="form-group">
                                        <input
                                            type="number"
                                            class="form-control text-center"
                                            [(ngModel)]="item.quantity"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>
        
                                    <button
                                        type="button"
                                        class="btn btn-link px-2"
                                        [disabled]="isProceedingOrder || isDeleting"
                                        (click)="onChangeQty(item, 'plus')">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-8 col-md-3 text-end mb-2 align-self-start">
                        <h5 class="f-bold mb-0 quantity-field">
                            {{ (item.pricePerItem * item.quantity) | wakiCurrency: '' }}
                        </h5>
                    </div>
                    <div class="col-4 col-md-1 text-end mb-2 align-self-start">
                        <button
                            type="button"
                            class="btn btn-icon-danger"
                            [disabled]="isProceedingOrder || isDeleting"
                            (click)="deleteItem(item._id)">
                            <i class="fas fa-trash fa-lg"></i>
                        </button>
                    </div>
                </div>
            </div>
            <hr *ngIf="!last">
        </ng-container>
    </div>
</div>
<div class="modal-footer cart_action bg-billing" *ngIf="myCart && !isLoading">
    <div class="row w-100">
        <div class="col-12 d-flex justify-content-end">
            <form [formGroup]="form" class="discount-section">
                <div class="form-group">
                    <label for="discount" translate>Discount Amount</label>
                    <div class="input-group" [class.is-invalid]="form.get('discountAmount')?.invalid">
                        <input id="discount" 
                            class="form-control"
                            type="number"
                            min="0"
                            [readonly]="(filteredCart.discountAmount || 0) > 0"
                            formControlName="discountAmount">
                        
                        <span
                            *ngIf="!filteredCart.discountAmount"
                            class="input-group-text"
                            [class.cursor-not-allowed]="form.get('discountAmount')?.invalid"
                            [class.cursor-pointer]="form.get('discountAmount')?.valid"
                            (click)="applyDiscount()"
                            translate>Apply</span>
                        
                        <span
                            *ngIf="(filteredCart.discountAmount || 0) > 0"
                            class="input-group-text cursor-pointer"
                            (click)="resetDiscount()"
                            translate>Cancel</span>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-12">
            <div class="table-section table-responsive">
                <table class="table m-0">
                    <tbody>
                        <tr>
                            <td class="col-6" translate>Subtotal</td>
                            <td class="col-6 text-{{isArabic ? 'start' : 'end'}}">
                                {{ filteredCart.subTotal | wakiCurrency: '' }}
                            </td>
                        </tr>
                        <tr>
                            <td class="col-6" translate>Discount</td>
                            <td class="col-6 text-{{isArabic ? 'start' : 'end'}}">
                                {{ (filteredCart.discountAmount || 0) | wakiCurrency: '' }}
                            </td>
                        </tr>
                        <tr>
                            <td class="col-6" translate>Total After Discount</td>
                            <td class="col-6 text-{{isArabic ? 'start' : 'end'}}">
                                {{ filteredCart.totalAfterDiscount | wakiCurrency: '' }}
                            </td>
                        </tr>
                        <tr>
                            <td class="col-6" translate>VAT</td>
                            <td class="col-6 text-{{isArabic ? 'start' : 'end'}}">
                                {{ filteredCart.vatAmount | wakiCurrency: ''}}
                            </td>
                        </tr>
                        <tr class="f-bold total-amount">
                            <td class="col-6" translate>Total Amount</td>
                            <td class="col-6 text-{{isArabic ? 'start' : 'end'}}">
                                {{ filteredCart.totalAmount | wakiCurrency: '' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-12 mt-3 d-flex justify-content-end">
            <button
                type="button"
                class="btn btn-outline-primary mx-2"
                (click)="modal.close('Close click')"
                [disabled]="isProceedingOrder || isDeleting"
                translate>Cancel</button>
        
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="shouldDisableProceedButton"
                (click)="checkout()">
                <ng-container *ngIf="!isProceedingOrder; else proceeding">
                    <span *ngIf="!isProceedingOrder" translate>Proceed to Order</span>
                </ng-container>
                <ng-template #proceeding>
                    <span
                        *ngIf="isProceedingOrder"
                        class="spinner-grow spinner-grow-sm me-2"></span>
                    <span *ngIf="isProceedingOrder" translate>Proceeding...</span>
                </ng-template>
            </button>
        </div>
    </div>
</div>

<div class="modal-footer d-flex justify-content-end" *ngIf="!myCart?._id && !isLoading">
    <button
        type="button"
        class="btn btn-outline-primary"
        (click)="modal.close('Close click')"
        translate>Cancel</button>
</div>
