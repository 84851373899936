<div class="d-flex h-100">
    <app-sidebar *ngIf="app.showSidebar && !hiddenSidebar"></app-sidebar>
    
    <div class="d-flex flex-column w-100">
        <app-toolbar
            *ngIf="!hiddenSidebar"
            [showSidebar]="app.showSidebar"
            (toggleSidebar)="toggleSidebar()"
            (logout)="logout()"></app-toolbar>
        <div
            class="content-wrapper layout-content" 
            [ngClass]="contentClass"
            [ngStyle]="{'opacity': (!app.isDesktop && app.showSidebar === true) ? '.5' : 1}">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
