<ng-container *ngIf="form">
    <form [formGroup]="form">
        <div class="d-flex justify-content-center">
            <h3 class="text-primary" translate>Password Recovery</h3>
        </div>
        <div class="card p-2">
            <ng-container *ngIf="currentStep === 1">
                <div class="row">
                    <div class="container">
                        <div class="form-group col-12 mt-2">
                            <label
                                class="w-100 text-center"
                                for="email" translate>
                                Email
                            </label>
                            <div class="input-group">
                                <span class="input-group-text fa fa-envelope"></span>
                                <input
                                    type="text"
                                    class="form-control"
                                    formControlName="email"
                                    [placeholder]="'email' | translate">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentStep === 2">
                <div class="row">
                    <div class="container">
                        <div class="form-group col-12 mt-2">
                            <label
                                class="w-100 text-center"
                                for="validationCode" translate>
                                Validation Code
                            </label>
                            <div class="input-group">
                                <span class="input-group-text fa fa-key"></span>
                                <input
                                    type="text"
                                    class="form-control"
                                    formControlName="validationCode"
                                    [placeholder]="'Validation Code' | translate">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentStep === 3">
                <div class="row">
                    <div class="container">
                        <div class="form-group col-12 mt-2">
                            <label
                                class="w-100 text-center"
                                for="password" translate>
                                Password
                            </label>
                            <div class="input-group">
                                <span class="input-group-text fa fa-lock"></span>
                                <input
                                    type="password"
                                    class="form-control"
                                    [placeholder]="'Password' | translate"
                                    formControlName="password">
                            </div>
                        </div>
                        <div class="form-group col-12 mt-2">
                            <label
                                class="w-100 text-center"
                                for="confirmPassword" translate>
                                Confirm Password
                            </label>
                            <div class="input-group">
                                <span class="input-group-text fa fa-lock"></span>
                                <input
                                    type="password"
                                    class="form-control"
                                    [placeholder]="'Confirm Password' | translate"
                                    formControlName="confirmPassword">
                            </div>
                            <small
                                *ngIf="form.get('confirmPassword')?.errors?.mustMatch"
                                class="text-danger" translate>
                                Password Confirmation doesn't match Password
                            </small>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="d-flex justify-content-center mt-2 gap-3">
                <button
                    type="submit"
                    class="btn btn-outline-primary" translate>
                    Cancel
                </button>
                <button
                    *ngIf="currentStep === 1"
                    type="submit"
                    class="btn btn-primary"
                    (click)="stepOneClick.emit()"
                    [disabled]="!form.get('email')?.valid || isLoading" translate>
                    Next
                </button>
                <button
                    *ngIf="currentStep === 2"
                    type="submit"
                    class="btn btn-primary"
                    (click)="stepTwoClick.emit()"
                    [disabled]="!form.get('validationCode')?.valid || isLoading" translate>
                    Next
                </button>
                <button
                    type="submit"
                    class="btn btn-primary"
                    *ngIf="currentStep === 3"
                    (click)="stepThreeClick.emit()"
                    [disabled]="!form.get('password')?.valid || isLoading" translate>
                    Complete
                </button>
            </div>
        </div>
    </form>
</ng-container>
