<ng-select
    *ngIf="typingConfiguration"
    class="ng-select-sm"
    [addTag]="false"
    [placeholder]="config.placeholderText | translate"
    [typeToSearchText]="config.typeToSearchText | translate"
    [loadingText]="config.loadingText | translate"
    [notFoundText]="config.notFoundText | translate"
    [minTermLength]="config.minTermLength"
    [multiple]="config.multiple"
    [loading]="searching"
    [labelForId]="id || formControlName"
    [attr.name]="formControlName"
    [(ngModel)]="value"
    [typeahead]="input$"
    [disabled]="disabled"
    (change)="change.emit()"
>
    <ng-option *ngFor="let item of data$ | async" [value]="fieldValue(item)">
        <ng-container *ngTemplateOutlet="labelFieldNameTemplate; context: { item: item, labelFieldName: labelFieldName }"></ng-container>
    </ng-option>
</ng-select>

<ng-template #labelFieldNameTemplate let-labelFieldName="labelFieldName" let-item="item">
    <span *ngIf="labelFieldName === 'translations'">{{ fieldText(item) | translation }}</span>
    <span *ngIf="labelFieldName !== 'translations'">{{ fieldText(item) | translate }}</span>
</ng-template>
