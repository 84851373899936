<div class="modal-header">
    <h5 class="header-text" translate>{{title}}</h5>
    <button type="button" class="closeButton fa fa-times fa-sm"
    (click)="close()"></button>
</div>

<div class="modal-body position-relative m-auto pt-0">
    <div class="d-flex flex-column justify-content-between">
        <div class="qr-section mb-3" [class.qr-placeholder]="!qr_code">
            <span class="qr-placeholder-text" translate>QR Code here</span>
            <img width="240" height="240" [src]="qr_code">
        </div>
        <!-- <ul class="qr-status" [hidden]="authService.isSchoolOrSuperSchool()">
            <ng-container>
                <li class="d-flex justify-content-between mb-3 align-items-center">
                    <h4 class="m-0 f-bold text-dark" translate>Activate</h4>
                    <div class="checkbox-container qr-settings text-end" (click)="toggleStatusQRCode()">
                        <input id="checkbox" type="checkbox" [checked]="userInfo.user.payByQRCodeEnabled" />
                        <div class="dummy-bg"></div>
                        <div class="checbox-interface">
                            <div class="text on"></div>
                            <div class="text off"></div>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul> -->
    </div>
</div>