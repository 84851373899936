import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TIMESPAN_FORMAT } from '../functions/dates/time.format';

@Pipe({ name: 'wakiTime', pure: false })
export class WakiTimePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(date: string | Date | undefined): string {
        if (!date) {
            return '';
        }

        const convertedDate = new Date(date);

        return this.datePipe.transform(convertedDate, TIMESPAN_FORMAT) || '';
    }
}
