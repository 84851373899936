/* eslint-disable prettier/prettier */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddButtonComponent } from './add-button/add-button.component';
import { CancelButtonComponent } from './cancel-button/cancel-button.component';
import { ExportButtonComponent } from './export-button/export-button.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import { EditButtonComponent } from './edit-button/edit-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ExitButtonComponent } from './exit-button/exit-button.component';
import { PaginationComponent } from './pagination/pagination.component';
import { InputRequiredErrorMessageComponent } from './input-required-error-message/input-required-error-message.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomFormsValidationComponent } from './custom-forms-validation/custom-forms-validation.component';
import { ActivationIconComponent } from './activation-icon/activation-icon.component';
import { FilterAndActionsComponent } from './filter-and-actions/filter-and-actions.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerBorderComponent } from './spinner-border/spinner-border.component';
import { BulkUploadButtonComponent } from './bulk-upload-button/bulk-upload-button.component';
import { ColumnSelectionDialogComponent } from './column-selection-dialog/column-selection-dialog.component';
import { CustomizeColumnButtonComponent } from './customize-column-button/customize-column-button.component';
import { TranslationPipe } from '../pipes/translate-language.pipe';
import { TypeaheadSelectComponent } from './typeahead-select/typeahead-select.component';
import { SignUpAdultFormComponent } from './sign-up-adult-form/sign-up-adult-form.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { OrderCardButtonComponent } from './filter-and-actions/order-card-button/order-card-button.component';
import { MaterialModule } from '../material.module';
import { QrCodeSettingsComponent } from './qr-code-settings/qr-code-settings.component';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { SecondaryButtonComponent } from './secondary-button/secondary-button.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SignUpMerchantFormComponent } from './sign-up-merchant-form/sign-up-merchant-form.component';
import { MerchantSignUpDoc } from '@portal/merchant-sign-up/merchant-sign-up-page/merchant-sign-up-info-form/merchant-sign-up-info-form.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NoColumnsMessageComponent } from './no-columns-message/no-columns-message.component';

export const COMPONENTS = [
    AddButtonComponent,
    CancelButtonComponent,
    ExportButtonComponent,
    SaveButtonComponent,
    EditButtonComponent,
    ExitButtonComponent,
    BulkUploadButtonComponent,
    CustomizeColumnButtonComponent,
    OrderCardButtonComponent,
    PaginationComponent,
    InputRequiredErrorMessageComponent,
    ConfirmDialogComponent,
    BreadcrumbComponent,
    CustomFormsValidationComponent,
    ActivationIconComponent,
    FilterAndActionsComponent,
    SpinnerBorderComponent,
    ColumnSelectionDialogComponent,
    TypeaheadSelectComponent,
    SignUpAdultFormComponent,
    SignUpMerchantFormComponent,
    MerchantSignUpDoc,
    QrCodeSettingsComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    SearchBoxComponent,
    NoColumnsMessageComponent
];

export const PIPES = [
    TranslationPipe,
];
@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        BreadcrumbModule,
        TranslateModule.forChild(),
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule,
        MaterialModule
    ],
    declarations: [...COMPONENTS, ...PIPES, UnauthorizedComponent],
    exports: [
        ...COMPONENTS,
        ...PIPES,
        BreadcrumbModule,
        TranslateModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule
    ],
    providers: [
        BreadcrumbService,
        TranslateService,
        TranslationPipe,
    ]
})
export class SharedComponentModule { }
