<button
    type="button"
    class="{{ size }} btn-add"
    [ngClass]="customClass.length > 0 ? customClass : 'btn btn-sm btn-secondary'"
    [disabled]="disabled"
    (click)="cancel.emit()"
    translate>
    <span *ngIf="showIcon">+</span>
    {{ text }}
</button>
