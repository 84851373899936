import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICustomizeSelectionColumn } from '@portal/shared/models/customize-column.model';
import { CustomizeColumnService } from '@portal/shared/services/customize-column.service';
import { debounceTime } from 'rxjs/operators';
import { ComponentBase } from '../component-base';

@Component({
    selector: 'app-column-selection-dialog',
    templateUrl: './column-selection-dialog.component.html',
    styleUrls: ['./column-selection-dialog.component.scss'],
})
export class ColumnSelectionDialogComponent
    extends ComponentBase
    implements OnInit
{
    @Input() pageName: string = '';
    @Input() _id: string = '';
    @Input() columns: ICustomizeSelectionColumn[] = [];

    filteredColumns: ICustomizeSelectionColumn[] = [];
    selectedColumns: string[] = [];

    form: FormGroup = this.fb.group({
        search: [''],
    });

    constructor(
        private fb: FormBuilder,
        private activeModal: NgbActiveModal,
        private customizeColumnService: CustomizeColumnService
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.pageName || !this._id) {
            this.close();
            return;
        }
        this.filteredColumns = [...this.columns];
        const visibleColumns = this.customizeColumnService.getVisibleColumns(
            this.pageName,
            this._id
        );
        if (visibleColumns) this.selectedColumns = visibleColumns;
        this.form.valueChanges
            .pipe(debounceTime(300))
            .subscribe(() => this.filterColumns());
    }

    toggleAll(event: any) {
        if (event.target.checked)
            this.selectedColumns = this.columns.map((c) => c.value);
        else this.selectedColumns = [];
    }

    onColumnCheckBoxClick(event: any, column: ICustomizeSelectionColumn) {
        if (event.target.checked) {
            this.selectedColumns = [...this.selectedColumns, column.value];
        } else {
            this.selectedColumns = this.selectedColumns.filter(
                (c) => c !== column.value
            );
        }
    }

    save() {
        this.customizeColumnService.setVisibleColumns(
            this.pageName,
            this._id,
            this.selectedColumns
        );
        this.close(this.selectedColumns);
    }

    close = (data: string[] | undefined = undefined) => {
        this.activeModal.close(data);
    };

    private filterColumns() {
        const search: string = this.form.value.search.trim();
        this.filteredColumns = this.columns.filter((c) =>
            c.text.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );
    }
}
