import { Component, Input, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent {
  @Input() formName :any;
  @Input() formGroup: any;
  @Input() addClass:any;

  constructor() { }

  @ViewChild('search') seacrhInput!: ElementRef;

  foucsOnSearch() {
      this.seacrhInput.nativeElement.focus()
  }
}
