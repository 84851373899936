/* eslint-disable prettier/prettier */
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '@portal/app.component';
import { ComponentBase } from '@portal/shared/components/component-base';
import { getBaseUrl } from '@portal/shared/functions/get-base-url';
import { IApiResult } from '@portal/shared/models/api-result.model';
import { ISelectOption } from '@portal/shared/models/select-option.model';
import { NotifyService } from '@portal/shared/services/notify.service';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CartDetails, CartItem, ICartItem, emptyCart } from '../shared/models/cart.model';
import { CartService } from '../shared/services/cart.service';
import { ConsumableOrderService } from '../shared/services/order.service';

@Component({
  selector: 'app-cart-details-dialog',
  templateUrl: './cart-details-dialog.component.html',
  styleUrls: ['./cart-details-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartDetailsDialogComponent extends ComponentBase implements OnInit, OnDestroy {
  @Input() isLoading = true;
  @Input() isAdmin = false;
  @Input() isCanteenUser = false;
  @Input() myCart: CartDetails | undefined;
  @Input() schoolOptions: ISelectOption[] = [];
  @Input() canteenOptions: ISelectOption[] = [];
  @Input() cartCounts = 0;

  public app = AppComponent;
  public filteredCart: CartDetails = emptyCart();
  public isArabic = localStorage.getItem('lang') === 'ar';
  public isProceedingOrder = false;
  public isDeleting = false;

  public get dropdownSettings(): IDropdownSettings {
    return {
      singleSelection: this.isAdmin,
      closeDropDownOnSelection: this.isAdmin,
      showSelectedItemsAtTop: this.isAdmin,
      idField: 'value',
      textField: 'translations',
      selectAllText: this.translationSvc.instant('Select All'),
      unSelectAllText: this.translationSvc.instant('Unselect All'),
      allowSearchFilter: false,
      itemsShowLimit: 2,
    }
  };

  public form = new FormGroup({
    discountAmount: new FormControl(null, [Validators.pattern(/^\d*\.?\d+$/)])
  })

  constructor(
    public modal: NgbActiveModal,
    private cartService: CartService,
    private orderService: ConsumableOrderService,
    private translationSvc: TranslateService,
    private notify: NotifyService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.myCart) {
      this.filteredCart = _.cloneDeep({
        ...this.myCart,
        totalAfterDiscount: this.myCart.subTotal
      });
    }
  }

  ngOnDestroy(): void {
    this.filteredCart = emptyCart();
    this.form.reset();
  }

  public checkout = (): void => {
    this.isProceedingOrder = true;
    const { _id, vatAmount, totalAmount, discountAmount } = this.filteredCart;
    const orderPayload = {
      cart: _id,
      vatAmount: vatAmount || 0.0,
      discountAmount: discountAmount || 0.0,
      totalAmount: totalAmount || 0.0,
    }
    this.orderService.createCartOrder(orderPayload)
      .pipe(
        tap((res) => {
          if (res.success && res.order) {
            sessionStorage.removeItem('myCart');
            this.notify.success('Consumable Order', 'save_success');
            this.resetCart();
            const payload = {
              webPurchase: true,
              consumableOrder: res.order._id,
              amount: res.order.totalAmount,
              returnUrl: getBaseUrl() + 'business-store/orders/' + res.order._id
            }
            this.orderService.getPaymentLinkForOrder(payload)
              .pipe(
                tap((response) => {
                  if (response.success && response.checkoutUrl) {
                    window.open(response.checkoutUrl, "_self");
                  }
                  this.isProceedingOrder = false;
                }),
                catchError(() => {
                  this.notify.error('Payment Link', 'There was an error attempting to load payment link.');
                  this.modal.close('There was an error attempting to load payment link.')
                  return of();
                })
              )
              .subscribe();
          }
        }),
        catchError((err) => {
          this.notify.error('Consumable Order', 'There was an error saving Consumable Order.', err);
          this.isProceedingOrder = false;
          return of();
        })
      ).subscribe();
  }

  public onChangeQty = (item: any, action: string) => {
    action === 'plus' ? item.quantity++ : item.quantity--;
    if (item.quantity === 0) {
      this.deleteItem(item._id)
    } else {
      this.cartService
        .updateCartItem(item._id, { quantity: item.quantity })
        .subscribe({
          next: (res) => this.mappingNewCartData(res),
          error: (ex) => this.notify.error('Try again!', 'Cannot update quantity of item.', ex)
        });
    }
  }

  public deleteItem = (id: string = '') => {
    if (!id) {
      this.notify.error('Id Required', 'Missing data item')
      return;
    }
    this.isDeleting = true;
    this.cartService.deleteCartItem(id)
      .pipe(
        tap((res) => {
          if (res.success) {
            this.notify.success('Cart Item', 'item_removed');
            this.cartService.updateCartCounts(res.cart?.items?.length);

            const currentIds = (res.cart?.items || []).map(i => i._id);
            this.cartCounts = currentIds.length;
            if (currentIds.length === 0) {
              delete this.myCart;
              this.filteredCart = emptyCart();
              sessionStorage.removeItem('myCart');
            } else {
              this.mappingNewCartData(res);
            }
            this.isDeleting = false;
          }
        }),
        catchError((err) => {
          this.notify.error('Try again!', 'Cannot remove item.', err);
          this.isDeleting = false;
          return of();
        })
      )
      .subscribe();
  }

  public onItemSelect = (item: CartItem) => {
    const selectedSchools = item.selectedSchools || [];
    const selectedCanteens = item.selectedCanteens || [];

    const dataToPut = this.isAdmin ? {
      forSchools: selectedSchools.map(v => v.value),
      forCanteens: selectedCanteens.map(v => v.value),
      quantity: selectedSchools.length + selectedCanteens.length
    } : {
      quantity: selectedSchools.length,
      canteenSubscriptionForSchools: selectedSchools.map(v => v.value),
    };

    if (dataToPut.quantity === 0) {
      this.deleteItem(item._id)
      return;
    }
    this.cartService
      .updateCartItem(item._id || '', dataToPut)
      .subscribe({
        next: (res) => this.mappingNewCartData(res),
        error: (ex) => this.notify.error('Try again!', 'Cannot update item.', ex)
      });
  }

  public applyDiscount = (): void => {
    const value = _.cloneDeep(this.form.get('discountAmount')?.value || 0)
    if (this.form.invalid || value === 0) {
      this.form.reset();
      return;
    }
    this.filteredCart.discountAmount = parseFloat((+value).toFixed(2));
    this.filteredCart.totalAfterDiscount = parseFloat(((this.myCart?.subTotal || 0) - value).toFixed(2));
    this.filteredCart.vatAmount = parseFloat((this.filteredCart.totalAfterDiscount * 15 / 100).toFixed(2));
    this.filteredCart.totalAmount = parseFloat((this.filteredCart.vatAmount + this.filteredCart.totalAfterDiscount).toFixed(2));
  }

  public resetDiscount = (): void => {
    this.form.reset();
    this.filteredCart.discountAmount = 0;
    this.filteredCart.totalAfterDiscount = this.myCart?.subTotal || 0;
    this.filteredCart.vatAmount = parseFloat((this.filteredCart.totalAfterDiscount * 15 / 100).toFixed(2));
    this.filteredCart.totalAmount = parseFloat((this.filteredCart.vatAmount + this.filteredCart.totalAfterDiscount).toFixed(2));
  }

  private mappingNewCartData(res: IApiResult) {
    const currentIds = (res.cart?.items || []).map(i => i._id);
    const currentItems = (this.filteredCart.items || []).filter((i: ICartItem) => currentIds.includes(i._id));
    this.myCart = {
      ...(this.myCart || res.cart || emptyCart()),
      items: currentItems,
      subTotal: res.cart?.subTotal || 0,
      vatAmount: res.cart?.vatAmount || 0,
      totalAmount: res.cart?.totalAmount || 0,
    };
    sessionStorage.setItem('myCart', JSON.stringify(this.myCart));
    this.filteredCart = _.cloneDeep({
      ...this.myCart,
      totalAfterDiscount: this.myCart.subTotal
    });
  }

  private resetCart(): void {
    this.cartService.updateCartCounts(undefined)
  }

  public get shouldDisableProceedButton() {
    const subscriptions = this.filteredCart.items?.filter((i: any) => i.consumable.type === 'SUBSCRIPTION' && i.selectedSchools?.length === 0) || [];
    const emptySchoolOrCanteen = this.filteredCart.items?.filter((i: any) => (i.selectedSchools?.length === 0 && i.selectedCanteens?.length === 0)) || [];
    return (this.isAdmin && emptySchoolOrCanteen.length > 0) || (subscriptions.length > 0 && this.isCanteenUser) || this.isProceedingOrder || this.isDeleting;
  }

}