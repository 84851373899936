<div class="align-items-center d_pagination" [ngClass]="{ 'px-3': !isAdmin }">
    <div class="d-flex align-items-center mt-2" *ngIf="showPageSizeOption">
        <div class="dropdown">
            <button
                type="button" id="specificSizePageMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                class="btn btn-outline-gray btn-sm dropdown-toggle sfm page-link">
                {{ currentPageSize }} {{ 'per page' | translate }}
            </button>
            <div class="dropdown-menu" aria-labelledby="specificSizePageMenuButton">
                <ng-container *ngFor="let size of sizeOptions">
                    <div class="dropdown-item" (click)="setSizePage(size)">
                        {{ size }} {{ 'per page' | translate }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <nav aria-label="Page navigation example">
        <ul class="pagination m-0 mt-2" [ngClass]="{'mx-2': app.isDesktop}">
            <li
                class="page-item"
                [ngClass]="{ 'disabled': currentPage === 1 }"
                [hidden]="isSetSpecificPage">
                <a
                    class="page-link"
                    (click)="goToPage.emit(1)"
                    translate>
                    First
                </a>
            </li>
            <li
                class="page-item"
                [ngClass]="{ 'disabled': currentPage === 1 }"
                [hidden]="isSetSpecificPage">
                <a
                    class="page-link"
                    (click)="goToPage.emit(currentPage - 1)"
                    translate>
                    {{ app.isDesktop ? 'Previous' : 'Prev' }}
                </a>
            </li>
            <ng-container *ngFor="let page of pagesForPagination">
                <li
                    class="page-item"
                    [ngClass]="{ 'active': page === currentPage }"
                    [hidden]="isSetSpecificPage">
                    <a
                        class="page-link"
                        (click)="goToPage.emit(page)">
                        {{ page }}
                    </a>
                </li>
            </ng-container>
            <li class="page-item" [hidden]="!isSetSpecificPage">
                <form [formGroup]="form">
                    <div class="input-group custom-pagination-text-box">
                        <input
                            type="number"
                            class="form-control custom-pagination-input"
                            [min]="1"
                            [max]="maxPage"
                            [placeholder]="'Typing page...' | translate"
                            formControlName="specificPage">

                        <span
                            class="input-group-text cursor-pointer border-radius-0"
                            (click)="goToSpecificPage()"
                            translate>✔</span>
                        <span
                            class="input-group-text cursor-pointer text-danger"
                            (click)="isSetSpecificPage = !isSetSpecificPage"
                            translate>✖</span>
                    </div>
                </form>
            </li>
            <li class="page-item" [hidden]="isSetSpecificPage || currentPage === maxPage">
                <a
                    class="page-link"
                    (click)="isSetSpecificPage = !isSetSpecificPage"
                    translate>
                    Custom
                </a>
            </li>
            <li
                class="page-item"
                [ngClass]="{ 'disabled': currentPage >= maxPage }"
                [hidden]="isSetSpecificPage">
                <a
                    class="page-link"
                    (click)="goToPage.emit(currentPage + 1)"
                    translate>
                    Next
                </a>
            </li>
            <li
                class="page-item"
                [ngClass]="{ 'disabled': currentPage === maxPage }"
                [hidden]="isSetSpecificPage">
                <a
                    class="page-link"
                    (click)="goToPage.emit(maxPage)"
                    translate>
                    Last
                </a>
            </li>
        </ul>
    </nav>
    <div class="d-flex align-items-center mt-2">
        <div class="text-end d-flex flex-grow-1 pe-2">
            <h6 class="m-0 pagination__text">{{ paginationTxt }}</h6>
        </div>
    </div>
</div>
