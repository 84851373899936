import { Component, Input, OnInit } from '@angular/core';
import { ICustomizeSelectionColumn } from '@portal/shared/models/customize-column.model';

@Component({
  selector: 'app-no-columns-message',
  templateUrl: './no-columns-message.component.html',
  styleUrls: ['./no-columns-message.component.scss']
})
export class NoColumnsMessageComponent implements OnInit {

  @Input() defaultColumns: ICustomizeSelectionColumn[] = []

  constructor() { }

  ngOnInit(): void {
  }

  public get noVisiableColumns():boolean {
    return !this.defaultColumns.some((column) => column.visible)
  }

}
