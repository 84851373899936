/* eslint-disable prettier/prettier */
import { Component } from '@angular/core';
import { AppComponent } from '@portal/app.component';
import { AuthService } from '@portal/auth/services/auth.service';
import { ComponentBase } from '@portal/shared/components/component-base';
import { MenuItem } from '@portal/shared/models/menu-item.model';
import { NavMenuService } from './menu-items.service';
import { Router } from "@angular/router";
import { ConfigurationService } from '@portal/shared/services/configuration.service';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';


@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent extends ComponentBase {
    public app = AppComponent;
    public isAdmin = false;
    public isCallSystemUser = false;
    public activeLinks: MenuItem[] = [];
    public selectedTranslations: any;
    public openNavMenus: any = [];

    public isLoading = true;
    public claims: string[] = [];

    public get isProduction(): boolean {
        return this.configService.config.production;
    }
    public get webVersion(): string {
        return `v${this.configService.config.APP_VERSION}`;
    }
    public get subMenuItemClass(): string {
        return AppComponent.textDir === 'rtl' ? 'mr-3' : 'ml-3';
    }

    constructor(
        private authService: AuthService,
        private navMenuService: NavMenuService,
        public router: Router,
        private configService: ConfigurationService
    ) {
        super();
        this.isAdmin = this.authService.isAdminOrSuperAdmin();
        this.isCallSystemUser = this.authService.isCallSystemUser();
        console.log(`production: `, this.isProduction);

        this.configService.getUserClientContext().pipe(
            finalize(() => this.isLoading = false)
        ).subscribe({
            next: (response) => {
                this.claims = response.claims ?? [];

                if (this.authService.isSchoolOrSuperSchool()) {
                    this.selectedTranslations = _.get(
                        _.first(response.user?.schools),
                        'translations',
                        this.authService.getUserSchoolTranslations()
                    );
                }

                if (this.authService.isCanteenOrSuperCanteen()) {
                    this.selectedTranslations = _.get(
                        _.first(response.user?.canteens),
                        'translations',
                        this.authService.getUserCanteenTranslations()
                    );
                }

                if (this.authService.isMerchantOrSuperMerchant()) {
                    this.selectedTranslations = _.get(
                        _.first(response.user?.merchants),
                        'translations',
                        this.authService.getUserMerchantTranslations()
                    );
                }
            }
        })
    }

    public get navMenus(): MenuItem[] {
        if (this.authService.isAdminOrSuperAdmin()) {
            return this.navMenuService.generateAdminMenu();
        }

        if (this.authService.isSchoolOrSuperSchool()) {
            return this.getSchoolMenu();
        }

        if (this.authService.isCanteenOrSuperCanteen()) {
            return this.getCanteenMenu();
        }

        if (this.authService.isCallSystemUser()) {
            return this.navMenuService.generateCallSystemUserMenu();
        }

        if (this.authService.isMerchantOrSuperMerchant()) {
            return this.getMerchantMenu()
        }

        return [];
    };

    public autoClose = (): void => {
        AppComponent.showSidebar = AppComponent.isDesktop;
    }

    public openWhenCollapsed(label: any): void {
        let index = this.openNavMenus.indexOf(label);
        if (index === -1) {
            this.openNavMenus.push(label);
        } else {
            this.openNavMenus.splice(index, 1);
        }
    }

    private getCanteenMenu(): MenuItem[] {
        if (this.authService.isSuperCanteenUser()) {
            return this.navMenuService.generateSuperCanteenMenu();
        } else {
            return this.navMenuService.generateNormalCanteenMenu(this.claims);
        }
    }

    private getSchoolMenu() {
        if (this.authService.isSuperSchoolUser()) {
            return this.navMenuService.generateSuperSchoolMenu();
        } else {
            return this.navMenuService.generateNormalSchoolMenu(this.claims);
        }
    }

    private getMerchantMenu() {
        return this.navMenuService.generateBusinessMerchantMenu();
    }
}
