export enum NAV_MENU {
    HOME = 'Home',
    STUDENTS = 'Students',
    CLASSES = 'Classes',
    ATTENDANCE = 'Attendance',
    DISMISSAL_CALL = 'Dismissal Call',
    DISMISSAL_CALL_LOGS = 'Call Logs',
    CANTEEN_SALES = 'Canteen Sales',
    NOTIFICATIONS = 'Notifications',
    PUSH_NOTIFICATION = 'Push Notifications',
    NOTIFICATION_HX = 'History',
    CANTEENS_REPORTS = 'Canteens Reports',
    MERCHANTS_REPORTS = 'Merchants Reports',
    TRANSACTIONS = 'Transactions',
    TRANSFER_REQUESTS = 'Transfer Requests',
    FEES = 'Fees',
    MONEY_REQUESTS = 'Money Requests',
    FOOD_ITEMS = 'Canteen Items',
    FOOD_MENU = 'Canteen Menu',
    FOOD_ORDERS = 'Canteen Orders',
    COUPONS = 'Coupons',
    COUPON_ORDERS = 'Coupon Orders',
    MERCHANT_ITEMS = 'Merchant Items',
    MERCHANT_PRODUCT_CATALOGUES = 'Catalogue',
    MERCHANT_ORDERS = 'Merchant Orders',
    MERCHANT_EVENTS = 'Events List',
    MERCHANT_EVENT_SALES = 'Event Sales',
    BUSINESS_STORE = 'Store',
    BUSINESS_ORDERS = 'Orders',
}

export enum CLAIM_LABEL {
    //**VIEW */
    VIEW_HOME = 'USER.CLAIMS.VIEW_HOME',
    VIEW_STUDENTS = 'USER.CLAIMS.VIEW_STUDENTS',
    VIEW_CLASSES = 'USER.CLAIMS.VIEW_CLASSES',
    VIEW_LOGS = 'USER.CLAIMS.VIEW_LOGS',
    VIEW_DISMISSAL_CALL = 'USER.CLAIMS.VIEW_DISMISSAL_CALL',
    VIEW_SALES = 'USER.CLAIMS.VIEW_SALES',
    VIEW_NOTIFICATION = 'USER.CLAIMS.VIEW_NOTIFICATIONS',
    VIEW_HISTORY = 'USER.CLAIMS.VIEW_HISTORY',
    VIEW_TRANSACTIONS = 'USER.CLAIMS.VIEW_TRANSACTIONS',
    VIEW_TRANSFER_REQUESTS = 'USER.CLAIMS.VIEW_TRANSFER_REQUESTS',
    VIEW_MONEY_REQUESTS = 'USER.CLAIMS.VIEW_MONEY_REQUESTS',
    VIEW_FEES = 'USER.CLAIMS.VIEW_FEES',
    VIEW_CANTEEN_ITEMS = 'USER.CLAIMS.VIEW_CANTEEN_ITEMS',
    VIEW_SCHOOL_FOOD_MENU = 'USER.CLAIMS.VIEW_SCHOOL_FOOD_MENU',
    VIEW_CANTEEN_ORDERS = 'USER.CLAIMS.VIEW_CANTEEN_ORDERS',
    VIEW_COUPONS = 'USER.CLAIMS.VIEW_COUPONS',
    VIEW_COUPON_ORDERS = 'USER.CLAIMS.VIEW_COUPON_ORDERS',
    VIEW_STORE_ITEMS ='USER.CLAIMS.VIEW_STORE_ITEMS',
    VIEW_ITEMS = 'USER.CLAIMS.VIEW_ITEMS',
    VIEW_ORDERS = 'USER.CLAIMS.VIEW_ORDERS',
    VIEW_CATALOGUE = 'USER.CLAIMS.VIEW_CATALOGUE',
    VIEW_EVENTS = 'USER.CLAIMS.VIEW_EVENTS',
    VIEW_CANTEENS_REPORTS = 'USER.CLAIMS.VIEW_REPORTS',
    VIEW_MERCHANTS_REPORTS = 'USER.CLAIMS.VIEW_REPORTS',
    STORE = 'USER.CLAIMS.STORE',
    ORDERS = 'USER.CLAIMS.ORDERS',
    //**EDIT */
    EDIT_CARD_STATUS = 'USER.CLAIMS.EDIT_CARD_STATUS',
    EDIT_STUDENT = 'USER.CLAIMS.EDIT_STUDENT',
    EDIT_FOOD_ITEM = 'USER.CLAIMS.EDIT_FOOD_ITEM',
    EDIT_REGULATIONS = 'USER.CLAIMS.EDIT_REGULATIONS',
    EDIT_SCHOOL_FOOD_MENU = 'USER.CLAIMS.EDIT_SCHOOL_FOOD_MENU',
    EDIT_INVENTORY = 'USER.CLAIMS.EDIT_INVENTORY',
    EDIT_COUPON = 'USER.CLAIMS.EDIT_COUPON',
    EDIT_ITEM = 'USER.CLAIMS.EDIT_ITEM',
    EDIT_ITEM_STATUS = 'USER.CLAIMS.EDIT_ITEM_STATUS',
    EDIT_BRANCH_CATALOGUE = 'USER.CLAIMS.EDIT_CATALOGUE',
    EDIT_EVENT = 'USER.CLAIMS.EDIT_EVENT',
    //**ADD */
    CREATE_COUPON = 'USER.CLAIMS.ADD_NEW_COUPON',
    ADD_CLASS = 'USER.CLAIMS.ADD_CLASS',
    ADD_FOOD_ITEM = 'USER.CLAIMS.ADD_FOOD_ITEM',
    ADD_FOOD_ITEMS = 'USER.CLAIMS.ADD_FOOD_ITEMS',
    ADD_CUSTOMIZATION = 'USER.CLAIMS.ADD_CUSTOMIZATION',
    ADD_INVENTORY = 'USER.CLAIMS.ADD_INVENTORY',
    ADD_ITEM = 'USER.CLAIMS.ADD_ITEM',
    ADD_ITEMS = 'USER.CLAIMS.ADD_ITEMS',
    CREATE_BRANCH_CATALOGUE = 'USER.CLAIMS.ADD_CATALOGUE_BRANCH',
    CREATE_EVENT = 'USER.CLAIMS.ADD_NEW_EVENT',
    //** */
    ACTIONS_CLASS_DETAILS = 'USER.CLAIMS.ACTIONS_CLASS_DETAILS',
    BUY_ITEMS = 'USER.CLAIMS.BUY_ITEMS',
    APPLY_QUANTITY_TO_ALL = 'USER.CLAIMS.APPLY_QTA',
    CHANGE_QUANTITY = 'USER.CLAIMS.CHANGE_QUANTITY',
    RESET_PRICES = 'USER.CLAIMS.RESET_PRICES',
    CUSTOMIZED_COLUMNS = 'USER.CLAIMS.CUSTOMIZE_COLUMNS',
    DELETE_FOOD_ITEMS = 'USER.CLAIMS.DELETE_FOOD_ITEMS',
    DELETE_ITEMS = 'USER.CLAIMS.DELETE_ITEMS',
    CHANGE_ORDER_STATUS = 'USER.CLAIMS.CHANGE_ORDER_STATUS',
    MARK_ALL_ORDERS_DELIVERED = 'USER.CLAIMS.MARK_ALL_ORDERS_DELIVERED',
    MARK_ALL_ORDERS_REJECTED = 'USER.CLAIMS.MARK_ALL_ORDERS_REJECTED',
    PUBLISH_UNPUBLISH_COUPON = 'USER.CLAIMS.PUBLISH_UNPUBLISH_COUPON',
}