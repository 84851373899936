/* eslint-disable prettier/prettier */
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AppComponent} from '@portal/app.component';
import {AuthService} from '@portal/auth/services/auth.service';
import {translationLang} from '@portal/shared/functions/translate-language';
import {BreadcrumbService} from 'xng-breadcrumb';
import {ComponentBase} from '../component-base';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent extends ComponentBase implements OnInit {
    public app = AppComponent;

    constructor(private authService: AuthService, private breadcrumbService: BreadcrumbService) {
        super();

        this.breadcrumbService.set('@home', { skip: true });
        if (this.authService.isCanteenOrSuperCanteen()) {
            this.breadcrumbService.set('/canteens', { disable: true, label:this.authService.getUserCanteenName() || 'Canteen' });
        }
        else if (this.authService.isSchoolOrSuperSchool()) {
            this.breadcrumbService.set('/schools', { skip: true });
        }
        else if (this.authService.isMerchantOrSuperMerchant()) {
            this.breadcrumbService.set('/merchants', { disable: true, label:this.authService.getUserMerchantName() || 'Merchant' });
        }
    }
}
