
export const PaymentMethod = {
    'FaceID': 'Face ID',
    'QRCode': 'QR Code',
    'CreditCard': 'CREDIT_CARD',
    'Mada': 'Mada',
    'Card': 'Card',
    'Cash': 'Cash'
}

export const PaymentToken = {
    'FaceRecognition': 'paymentToken.FACE_RECOGNITION',
    'FaceID': 'paymentToken.FACE_ID',
    'QRCode': 'paymentToken.QR_CODE',
    'Card': 'paymentToken.CARD',
    'Wristband': 'paymentToken.WRISTBAND',
    'Mada': 'paymentToken.MADA',
    'CreditCard': 'paymentToken.CREDIT_CARD'
}