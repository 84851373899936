<div class="sidebar-wrapper{{ isProduction ? '-prod' : '' }}">
    <a href="/" class="d-flex justify-content-center">
        <img src="../../../../../assets/images/{{isProduction ? 'only_logo_purple.png' : 'only_logo_white.png'}}">
    </a>
    <ul class="nav nav-pills flex-column mb-auto mt-4 position-relative" *ngIf="!isLoading">
        <ng-container *ngFor="let menu of navMenus">
            <a *ngIf="menu.type === 'LINK'" [routerLink]="!menu.sub ? [menu.link] :((openNavMenus.includes(menu.label)) ? [menu.link+menu.sub[0].link] :[])" (click)="!menu.sub &&  autoClose(); openWhenCollapsed(menu.label)" data-toggle="collapse" [attr.data-target]="'#' + menu.link">
                <span
                    [class.active]="router.url.startsWith(menu.link)"
                    class="menu {{app.isRTL && 'rtl-menu'}} "
                    translate>
                    {{ menu.label }}
                </span>
            </a>
            <a
                *ngIf="menu.type === 'ITEM'"
                data-toggle="collapse"
                [attr.data-target]="'#' + menu.link"
                [routerLink]="!menu.sub ? [menu.link] : ((openNavMenus.includes(menu.label)) ? [menu.link+menu.sub[0].link] :[])"
                (click)="openWhenCollapsed(menu.label)"
                >
                    <span
                    [class.active]="this.router.url.startsWith(menu.link)"
                    class="menu {{app.isRTL && 'rtl-menu'}}">
                    {{ !isAdmin && !isCallSystemUser && ['/schools', '/canteens', '/merchants'].includes(menu.link) ? (selectedTranslations | translation) : (menu.label | translate) }}
                    </span>
            </a>
            <div id="{{menu.link}}" class="collapse show" >
                <ng-container *ngFor="let sub of menu.sub">
                    <a *ngIf="sub.type === 'LINK'" [routerLink]="menu.link + sub.link" (click)="autoClose()">
                        <span
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                            class="sub-menu {{app.isRTL && 'rtl-sub-menu'}}"
                            translate>
                            {{ sub.label }}
                        </span>
                    </a>
                    <a
                        *ngIf="sub.type === 'ITEM'"
                        (click)="autoClose()">
                        <span
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: false }"
                        class="sub-menu {{app.isRTL && 'rtl-sub-menu'}}"
                        translate>
                        {{ sub.label }}
                        </span>
                    </a>
                </ng-container>
            </div>
        </ng-container>
        <li class="nav-sticky-footer hide__mobile" *ngIf="isAdmin">
            <span class="sub-menu" [class.rtl-menu]="app.isRTL" translate>
                {{ webVersion }}
            </span>
        </li>
    </ul>
</div>