import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppComponent } from '@portal/app.component';

@Component({
    selector: 'app-export-button',
    templateUrl: './export-button.component.html',
    styleUrls: ['./export-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportButtonComponent {
    @Input() text!: string;
    @Input() disabled: boolean = false;
    @Input() size: string = '';
    @Output() export = new EventEmitter();

    get isUsingSmallFontSize() {
        return AppComponent.isDesktop && ['Download Inventory Report', 'Export Pending Online Orders'].includes(this.text)
    }

    constructor() {}
}
