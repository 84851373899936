/* eslint-disable prettier/prettier */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './components/auth.component';
import { ForgotPasswordPageComponent } from './components/forgot-password-page/forgot-password-page.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { OtpLoginPageComponent } from './components/otp-login-page/otp-login-page.component';

import { BackupAuthComponent } from './components/backup-auth-route/auth.component';
import { LoginPageComponent as BackupLoginPageComponent } from './components/backup-auth-route/login-page/login-page.component';
import { OtpLoginPageComponent as BackupOtpLoginPageComponent } from './components/backup-auth-route/otp-login-page/otp-login-page.component';
import { ForgotPasswordPageComponent as BackupForgotPasswordPageComponent } from './components/backup-auth-route/forgot-password-page/forgot-password-page.component';

const routes: Routes = [
    {
        path: 'login',
        component: AuthComponent,
        children: [
            {
                path: '',
                component: LoginPageComponent,
            },
            {
                path: 'otp',
                component: OtpLoginPageComponent,
            },
        ],
    },
    {
        path: 'auth',
        component: AuthComponent,
        children: [
            {
                path: 'forgot-password',
                component: ForgotPasswordPageComponent,
            },
        ],
    },
    {
        path: 'business-sign-up',
        component: AuthComponent,
        children: [{
            path: '',
            loadChildren: () => import('../business-sign-up/business-sign-up.module').then((m) => m.BusinessSignUpModule),
        }]
    },
    {
        path: 'adult-signup-request',
        component: AuthComponent,
        children: [{
            path: '',
            loadChildren: () => import('../adult-sign-up/adult-sign-up.module').then((m) => m.AdultSignUpModule),
        }]
    },
    {
        path: 'merchant-signup-request',
        component: AuthComponent,
        children: [{
            path: '',
            loadChildren: () => import('../merchant-sign-up/merchant-sign-up.module').then((m) => m.MerchantSignUpModule),
        }]
    },
    {
        path: 'backup-login',
        component: BackupAuthComponent,
        children: [
            {
                path: '',
                component:BackupLoginPageComponent
            },
            {
                path: 'backup-otp',
                component:BackupOtpLoginPageComponent
            }
        ]
    },
    {
        path: 'backup-auth',
        component: BackupAuthComponent,
        children: [
            {
                path: 'backup-forgot-password',
                component: BackupForgotPasswordPageComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
