<div class="modal-header">
    <h5 class="m-0" translate>Required details before proceeding</h5>
    <button class="btn btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="card-body p-3">
    <ul class="required__details">
        <li translate>
            You MUST be the authorized person to apply on behave of the
            school/canteen.
        </li>
        <li translate>
            You will need to upload:
            <ul class="sub__required__details mt-2">
                <li translate>a. A commercial record of school/canteen.</li>
                <li translate>b. A national address of school/canteen.</li>
                <li translate>c. A certificate of VAT (if Applicable).</li>
            </ul>
        </li>
    </ul>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="proceed()" translate>
        Proceed
    </button>
</div>
