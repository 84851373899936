/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable prettier/prettier */
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-custom-forms-validation',
  templateUrl: './custom-forms-validation.component.html',
  styleUrls: ['./custom-forms-validation.component.scss']
})
export class CustomFormsValidationComponent {
  @Input() control: AbstractControl | null | undefined = undefined;
  @Input() controlName: string = '';

  specifiedControls = ['vat', 'email'];
  numbericControls = ['schoolRegistrationCode', 'schoolId', 'canteenId', 'phone', 'mobileNo'];

  constructor() {
  }

  get defaultControls() {
    return [...this.specifiedControls, ...this.numbericControls];
  }

  get errorMessage() {
    switch (this.controlName) {
      case 'schoolRegistrationCode': case 'schoolId': case 'canteenId': case 'phone': case 'mobileNo':
        return 'VALIDATORS.MSG.MUST_BE_NUMBERIC';

      case 'vat':
        return 'VALIDATORS.MSG.MUST_15_DIGITS';
      
      case 'email':
        return 'VALIDATORS.MSG.MUST_BE_VALID_EMAIL';

      case 'vatPercentage':
        return 'VALIDATORS.MSG.VAT_REQUIRED_BEFORE_SETUP_VAT_PERCENTAGE';
      
      case 'lat': case 'long':
        return `Please make sure you enter a valid ${this.controlName === 'lat' ? 'latitude' : 'longitude'} value`;

      default:
        return 'VALIDATORS.MSG.REQUIRED';
    }
  }
}
