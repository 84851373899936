/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable prettier/prettier */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppComponent } from '@portal/app.component';

@Component({
  selector: 'app-order-card-button',
  templateUrl: './order-card-button.component.html',
  styleUrls: ['./order-card-button.component.scss']
})
export class OrderCardButtonComponent {
  @Input() disabled: boolean = false;
  @Output() onClick = new EventEmitter();
}
