import * as moment from "moment";
import { MomentInput } from "moment";

export const DUMMY_DATE = new Date(1970, 1, 1);
export const DATE_TIME_MACHINE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_MACHINE_FORMAT = 'YYYY-MM-DD';

export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm:ss';
export const DATE_TIME_FORMAT_NO_SECONDS = 'DD-MM-YYYY HH:mm';
export const NODA_DATETIME_OFFSET = 'YYYY-MM-DDTHH:mm:ssZ';

export function formatLocalDate(dateTime: MomentInput) {
    if (!dateTime) return '';
    return moment(dateTime).format(DATE_MACHINE_FORMAT);
}

export function formatUtc(dateTime: MomentInput, format: string): string {
    return !dateTime ? '' : moment(dateTime).utc().format(format);
}

export function formatUtcDateMachine(dateTime: MomentInput): string {
    return formatUtc(dateTime, DATE_MACHINE_FORMAT);
}