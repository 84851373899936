/* eslint-disable prettier/prettier */
import { Pipe, PipeTransform } from '@angular/core';
import { OrderType, TransactionTypeEnum } from '../models/transaction.model';

@Pipe({ name: 'orderType', pure: false })
export class OrderTypePipe implements PipeTransform {
    transform(value = 'NONE'): string {
        const typeKey = Object.keys(TransactionTypeEnum)
            .find((key: string) => TransactionTypeEnum[key] === value)

        if (!typeKey) {
            return ''
        }
        return OrderType[typeKey]
    }
}
