import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'paymentMethodText', pure: false })
export class PaymentMethodTextPipe implements PipeTransform {
    transform(value: string): string {
        const types: { [key: string]: string } = {
            ['FACE_ID']: 'paymentMethod.FACE_ID',
            ['QR_CODE']: 'paymentMethod.QR_CODE',
            ['MADA']: 'paymentMethod.MADA',
            ['CREDIT_CARD']: 'paymentMethod.CREDIT_CARD',
            ['CARD']: 'paymentMethod.CARD',
            ['WRISTBAND']: 'paymentMethod.WRISTBAND',
            ['CASH']: 'paymentMethod.CASH',
            ['WAKI_WALLET']: 'paymentMethod.WAKI_WALLET',
            ['default']: value
        };

        return types[value ?? 'default'];
    }
}