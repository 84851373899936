import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DATE_TIME_MACHINE_FORMAT } from "./date.format";

export const today = () => moment().startOf('day');
export const tomorrow = () => moment().add(1, 'days').startOf('day');
export const yesterday = () => today().subtract(1, 'day');
export const firstDayOfCurrentYear = () => moment().startOf('year').add(1, 'days').startOf('day');

export const startOfMonth = () => moment().startOf('month').add(1, 'days').startOf('day');
export const endOfMonth = () => moment().endOf('month').endOf('day');

export const formatDateTime = (date: Date | string, format = 'YYYY-MM-DD HH:mm:ss'): string => {
    return moment(date).format(format);
}

/**
 * Accepts dateTime momentInput and returns string in the format YYYY-MM-DDT00:00:00
 */
export function dateOnly(dateTime: moment.MomentInput): string {
    return moment(dateTime).startOf('day').format(DATE_TIME_MACHINE_FORMAT);
}


// NgbDate
export const equals = (one: NgbDate, two: NgbDate): boolean => (one && two && two.year === one.year && two.month === one.month && two.day === one.day);

export const before = (one: NgbDate, two: NgbDate): boolean => {
    if (!one || !two) {
        return false;
    }

    return one.year === two.year
        ? (
            one.month === two.month
                ? (one.day === two.day ? false : one.day < two.day)
                : one.month < two.month
        ) : one.year < two.year;
}

export const after = (one: NgbDate, two: NgbDate): boolean => {
    if (!one || !two) {
        return false;
    }
    return one.year === two.year
        ? (
            one.month === two.month
                ? (one.day === two.day ? false : one.day > two.day)
                : one.month > two.month
        ) : one.year > two.year;
}