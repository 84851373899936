import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnauthorizedService } from '../services/unauthorized.service';
import { UserClientContext } from '../models/user-client-context.model';
import { ConfigurationService } from '../services/configuration.service';
import { IBaseConfig } from '@portal/common/config/config.model';

@Injectable({
  providedIn: 'root',
})
export abstract class RouteAuthGuardService implements CanLoad {
  protected readonly router: Router;
  protected readonly featureFlags: IBaseConfig['featureFlags'];

  constructor(
    private configService: ConfigurationService,
    private unauthorized: UnauthorizedService,
    router: Router,
  ) {
    this.router = router;
    this.featureFlags = this.configService.featureFlags;
  }

  protected abstract canAccess(context: UserClientContext): boolean;

  public canLoad(): Observable<true | UrlTree> {
    if (!this.featureFlags.enableUserAccess) {
      return of(true);
    }
    return this.configService.getUserClientContext()
      .pipe(map((context: UserClientContext) => (this.canAccess(context) ? true : this.unauthorized.handle())));
  }
}
