<div class="row mb-3">
    <xng-breadcrumb [separator]="iconTemplate" class="route">
        <ng-container *xngBreadcrumbItem="let breadcrumb; index">
            {{ breadcrumb | translate }}
        </ng-container>
    </xng-breadcrumb>
    <ng-template #iconTemplate>
        <i class="fas fa-chevron-down fa-rotate-{{ app.isRTL ? '90' : '270' }} icon"></i>
    </ng-template>
</div>
