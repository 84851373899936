export interface IUserPermissions {
    HOME: { [key: string]: IPagePermissions },
    REPORTS: { [key: string]: IPagePermissions },
    SCHOOLS: { [key: string]: IMainWithSubPagePermissions },
    NOTIFICATIONS: { [key: string]: IPagePermissions },
    TRANSACTIONS: { [key: string]: IPagePermissions },
    TRANSFER_REQUESTS: { [key: string]: IPagePermissions },
    FEES: { [key: string]: IPagePermissions },
    CANTEENS: { [key: string]: IMainWithSubPagePermissions },
    MERCHANTS: { [key: string]: IMainWithSubPagePermissions },
    BUSINESS_STORE: { [key: string]: IPagePermissions },
    BUSINESS_ORDERS: { [key: string]: IPagePermissions },
}

export type IPagePermissions = { [key: string]: string }
export type IMainWithSubPagePermissions = { [key: string]: IPagePermissions }
export type IUserType = 'School' | 'Canteen' | 'Merchant';
export const IUserType = {
    School: 'School' as IUserType,
    Canteen: 'Canteen' as IUserType,
    Merchant: 'Merchant' as IUserType,
}