<nav
    class="navbar navbar-light toolbar-wrapper"
    [ngClass]="{
        'full-width': !showSidebar,
        'remaining-rtl-width': currentLanguage === 'ar' && showSidebar,
        'remaining-width': currentLanguage === 'en' && showSidebar,
        'fixed-top': !app.isDesktop
    }">
    <div class="container-fluid">
        <div class="d-flex gap-3 align-items-center">
            <button
                class="btn btn-outline-primary pt-2 side-bar-toggler-button"
                type="button"
                (click)="toggleSidebar.emit()">
                <span class="fa fa-bars"></span>
            </button>
            <div *ngIf="!isProduction" class="dev-env">DEV</div>
        </div>

        <div class="d-flex justify-content-end align-items-center">
            <ng-container *ngIf="(!showSidebar && !app.isDesktop) || app.isDesktop">
                <div class="dropdown" *ngIf="app.isDesktop && !isCallSystemUser">
                    <a
                        (click)="goToCart()"
                        class="btn btn-outline-primary border-0 position-relative"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Go to cart"
                        [class.disabled]="isLoadingCart">
                        <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                        <span *ngIf="cartCounts > 0" class="badge badge_counts">
                            {{ cartCounts }}
                        </span>
                    </a>
                </div>
                <div class="dropdown" *ngIf="!isCallSystemUser">
                    <a class="btn btn-outline-primary border-0">
                        <i
                            class="fa fa-bell"
                            aria-hidden="true"
                            matBadgeColor="warn"
                            [matBadge]="notificationCounts"></i>
                    </a>
                </div>
                <div class="dropdown">
                    <a
                        class="btn btn-outline-primary border-0"
                        role="button" (click)="onChangedLanguage()">
                        {{ currentLanguage | languageTxt }}
                    </a>
                </div>
            </ng-container>
            <div class="dropdown">
                <a
                    class="btn btn-outline-primary dropdown-toggle border-0"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <span class="me-1 hide__mobile">{{ currentUser?.translations | translation }}</span>
                    <i class="fa fa-user" [ngClass]="{'hide__desktop': (currentUser?.translations | translation).length > 0 }"></i>
                </a>
                <ul
                    class="dropdown-menu dropdown-menu-{{app.textDir === 'rtl' ? 'start' : 'end'}}"
                    aria-labelledby="dropdownMenuLink">
                    <li class="hide__desktop w-100" style="min-height: 62px;">
                        <p class="dropdown-item p-2 m-0">
                            <b class="signup__text" translate>Login as </b><br>
                            <span class="signup__text float-end">{{ currentUser?.translations | translation }}</span>
                        </p>
                    </li>
                    <hr class="hide__desktop">
                    <ng-container *ngIf="!isCallSystemUser">
                        <li>
                            <a class="dropdown-item" routerLink="/users/current-users/profile">
                                {{'Profile' | translate}}
                            </a>
                        </li>
                        <hr />
                    </ng-container>
                    <ng-container *ngIf="!app.isDesktop && !isCallSystemUser">
                        <li>
                            <a class="dropdown-item" [class.disabled]="isLoadingCart" (click)="goToCart()">
                                {{ 'Cart' | translate}}

                                <span *ngIf="cartCounts > 0" class="float-end badge_counts__sm">
                                    {{ cartCounts }}
                                </span>
                            </a>
                        </li>
                        <hr />
                    </ng-container>
                    <li>
                        <a class="dropdown-item" (click)="logout.emit()">
                            {{'Logout' | translate}}
                        </a>
                    </li>
                    <li class="hide__desktop text-center w-100" *ngIf="isAdmin">
                        <small class="dropdown-item text-center w-100">{{ webVersion }}</small>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
