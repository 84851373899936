import { AbstractControl, ValidationErrors } from '@angular/forms';
import { IValidationError } from './validation-error.model';

export const addErrors = (control: AbstractControl, errors: ValidationErrors): void => {
  if (control.errors) {
    control.setErrors({ ...control.errors, ...errors });
  } else {
    control.setErrors(errors);
  }
};

export const clearError = (control: AbstractControl, errorKey: string): void => {
  if (!control || !control.errors) {
    return;
  }

  if (control.errors[errorKey]) {
    delete control.errors[errorKey];
  }

  if (Object.keys(control.errors).length === 0) {
    control.setErrors(null);
  }
};

export const getValidationError = (control: AbstractControl, id: string): ValidationErrors | null => {
  if (control.errors && control.errors[id]) {
    return { [id]: control.errors[id] };
  }
  return null;
};

export const createCustomError = (id: string, errors: IValidationError): ValidationErrors => {
  return { [id]: errors } as ValidationErrors;
};
