import { Injectable } from '@angular/core';
import { RouteAuthGuardService } from '@portal/shared/guards/route-auth-guard.service';
import { UserClientContext } from '@portal/shared/models/user-client-context.model';
import { canAccessCanteens } from '@portal/shared/permissions/permission';

@Injectable({
  providedIn: 'root',
})
export class CanteenGuardService extends RouteAuthGuardService {
  static canAccess = (context: UserClientContext, isRequired: boolean): boolean => !isRequired ? true : canAccessCanteens(context);

  protected canAccess = (context: UserClientContext): boolean => CanteenGuardService.canAccess(context, this.featureFlags.enableUserAccess);
}
