export const booleanOptions = [
    {
        value: true,
        translations: {
            en: 'Yes',
            ar: 'نعم'
        }
    },
    {
        value: false,
        translations: {
            en: 'No',
            ar: 'لا'
        }
    }
];