<div class="invalid-feedback" *ngIf="control && control.invalid && !control.dirty" translate>
    <span translate *ngIf="specifiedControls.includes(controlName) || numbericControls.includes(controlName)">
        {{ errorMessage }}
    </span>

    <span translate *ngIf="control.touched && !defaultControls.includes(controlName)">
        {{ errorMessage }}
    </span>
</div>

<div class="invalid-feedback" *ngIf="control && control.invalid && control.dirty">
    <span translate *ngIf="!defaultControls.includes(controlName)">
        {{ errorMessage }}
    </span>

    <span translate *ngIf="specifiedControls.includes(controlName) || numbericControls.includes(controlName)">
        {{ errorMessage }}
    </span>
</div>
