/* eslint-disable prettier/prettier */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/services/auth-guard.service';
import { LayoutComponent } from './core/components/layout/layout.component';
import { HomeGuardService } from './home/services/home-guard.service';
import { DependantGuardService } from './dependant/service/dependant-guard.service';
import { CanteenGuardService } from './canteen/guards/canteen-guard.service';
import { WakiUsersGuardService } from './adults/services/waki-users-guard.service';
import { MerchantGuardService } from './merchant/guards/merchant-guard.service';
import { SchoolGuardService } from './school/guards/school-guard.service';
import { AdvertisementGuardService } from './advertisements/guards/advertisement-guard.service';
import { LogGuardService } from './logs/guards/log-guard.service';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { NotificationGuardService } from './notification/services/notification-guard.service';
import { UsersGuardService } from './user/services/user-guard.service';
import { ReportGuardService } from './report/service/report-guard.service';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home',
        data: { breadcrumb: { alias: 'home', disable: true, label: '' } },
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'unauthorized-page', component: UnauthorizedComponent },
            {
                path: 'home',
                loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
                data: { title: 'Home' },
                canLoad: [HomeGuardService]
            },
            {
                path: 'reports',
                loadChildren: () => import('./report/report.module').then((m) => m.ReportModule),
                data: { title: 'Reports' },
                canLoad: [ReportGuardService]
            },
            {
                path: 'schools',
                loadChildren: () => import('./school/school.module').then((m) => m.SchoolModule),
                data: { title: 'Schools' },
                canLoad: [SchoolGuardService]
            },
            {
                path: 'canteens',
                loadChildren: () => import('./canteen/canteen.module').then((m) => m.CanteenModule),
                data: { title: 'Canteens' },
                canLoad: [CanteenGuardService]
            },
            {
                path: 'merchants',
                loadChildren: () => import('./merchant/merchant.module').then((m) => m.MerchantModule),
                data: { title: 'Merchants' },
                canLoad: [MerchantGuardService]
            },
            {
                path: 'waki-users',
                loadChildren: () => import('./adults/adult.module').then((m) => m.AdultModule),
                data: { title: 'Adults' },
                canLoad: [WakiUsersGuardService]
            },
            {
                path: 'notifications',
                loadChildren: () => import('./notification/notification.module').then((m) => m.NotificationModule),
                data: { title: 'Notifications' },
                canLoad: [NotificationGuardService]
            },
            {
                path: 'dependants',
                loadChildren: () => import('./dependant/dependant.module').then((m) => m.DependantModule),
                data: { title: 'Dependants' },
                canLoad: [DependantGuardService]
            },
            {
                path: 'business-store',
                loadChildren: () => import('./business-store/business-store.module').then((m) => m.BusinessStoreModule),
                data: { title: 'Business Store' }
            },
            {
                path: 'advertisements',
                loadChildren: () => import('./advertisements/advertisements.module').then((m) => m.AdvertisementModule),
                data: { title: 'Advertisements' },
                canLoad: [AdvertisementGuardService]
            },
            {
                path: 'users',
                loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
                data: { title: 'Users' },
                canLoad: [UsersGuardService]
            },
            {
                path: 'logs',
                loadChildren: () => import('./logs/logs.module').then((m) => m.LogsModule),
                data: { title: 'Logs' },
                canLoad: [LogGuardService]
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/home',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
