/* eslint-disable prettier/prettier */
import { Component, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '@portal/app.component';
import { RegistrationService } from '@portal/business-sign-up/services/registration.service';
import { defaultUser, IUser } from '@portal/shared/models/user.model';
import { environment } from 'src/environments/environment';
import { SignUpInfoDialogComponent } from '../sign-up-info-dialog/sign-up-info-dialog.component';
import { ArabicLanguage, DefaultLanguage } from '@portal/shared/services/language/language.model';
import { LanguageService } from '@portal/shared/services/language/language.service';
import { RoutingNavigateService } from '@portal/shared/services/routing-navigate/routing-navigate.service';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentBase } from '@portal/shared/components/component-base';
import { isNullOrEmptyString } from '@portal/shared/functions/string.function';

@Component({
    selector: 'app-login-toolbar',
    templateUrl: './login-toolbar.component.html',
    styleUrls: ['./login-toolbar.component.scss']
})
export class LoginToolbarComponent extends ComponentBase implements OnDestroy {
    currentLanguage = localStorage.getItem('lang') || DefaultLanguage;
    showSignUpAsCanteen = false;
    showEPortal = false;

    private subscription = new Subscription();

    constructor(
        private modalService: NgbModal,
        public trans: TranslateService,
        private router: Router,
        private registrationService: RegistrationService,
        private languageService: LanguageService,
        private routingNavigateService: RoutingNavigateService,
    ) {
        super();
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd && event.url) {
                this.showSignUpAsCanteen = !environment.production && !(event.url.startsWith('/business-sign-up') || event.url.startsWith('/adult-signup-request'));
                this.showEPortal = !(event.url.startsWith('/adult-signup-request') || event.url.startsWith('/merchant-signup-request'));

                if (!this.showEPortal) {
                    this.subscription.add(
                        this.languageService.languageChanged$.pipe(takeUntil(this.destroyed$)).subscribe((lang) => {
                            if (isNullOrEmptyString(lang) || this.currentLanguage === lang) {
                                return;
                            }
                            this.currentLanguage = lang;
                        })
                    );
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    showSignUpInfo = () => {
        const modalRef = this.modalService.open(SignUpInfoDialogComponent, {
            size: 'md',
            backdrop: 'static'
        });
        modalRef.result.then().catch();
    };

    onChangedLanguage() {
        if (this.currentLanguage === DefaultLanguage) {
            this.languageService.updateLanguage(ArabicLanguage)
            this.currentLanguage = ArabicLanguage;
        } else {
            this.languageService.setDefault();
            this.currentLanguage = DefaultLanguage;
        }
    }

    get dropdownClass() {
        return AppComponent.textDir === 'rtl' ? 'start' : 'end';
    }

    clearStorage = () => {
        if (!this.showSignUpAsCanteen) {
            const user: IUser = this.registrationService.localStorageBUser || defaultUser;

            if (user && (user.schools?.length > 0 || user.canteens?.length > 0)) {
                this.registrationService.clearUser();
                this.registrationService.clearUserEmail();
            }
            localStorage.removeItem('businessUserType');
        }

        this.routingNavigateService.home();
    }
}
