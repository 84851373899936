export const BusinessTypeEnum = {
    Freelancing: 'FREELANCING',
    CommercialRecord: 'COMMERCIAL_RECORD',
}

export const SubscriptionTypeEnum = {
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
}

export const DevicesEnum = {
    Yes: 'true',
    No: 'false',
}