import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApiResult } from '../models/api-result.model';
import { HttpService } from './http/http-service';

@Injectable({
    providedIn: 'root'
})
export class BankService {
    constructor(private httpService: HttpService) {}

    public getBanks(): Observable<IApiResult> {
        return this.httpService.v2Get(`banks`);
    };
}
