import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IconDefinition, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyService } from '@portal/shared/services/notify.service';
import { QRCodeService } from '../../services/qr-code-settings.service';
import * as _ from 'lodash';
import { IApiResult } from '@portal/shared/models/api-result.model';
import { TranslateService } from '@ngx-translate/core';
import { translationLang } from '@portal/shared/functions/translate-language';
import { AuthService } from '@portal/auth/services/auth.service';

@Component({
  selector: 'app-qr-code-settings',
  templateUrl: './qr-code-settings.component.html',
  styleUrls: ['./qr-code-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QrCodeSettingsComponent implements OnInit {
  @Input() qr_code!: string;
  @Input() title:string = "Canteen and Attendance QR Code"

  closeIcon: IconDefinition = faTimes;
  printIcon: IconDefinition = faPrint;
  isSaving = false;
  isHaveChanges = false;
  originalInfo: any;

  constructor(
    private activeModal: NgbActiveModal,
    private notify: NotifyService,
    private qrCodeService: QRCodeService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    // this.originalInfo = _.cloneDeep(this.userInfo)
  }

  // public toggleStatusQRCode() {
  //   this.userInfo.user.payByQRCodeEnabled = !this.userInfo.user.payByQRCodeEnabled;
  //   this.isHaveChanges = true;
  // }

  // public save() {
  //   this.isSaving = true;
  //   const payload = {
  //     regeneratePaymentQRCode: false,
  //     user: {
  //       payByQRCodeEnabled: this.userInfo?.user.payByQRCodeEnabled
  //     }
  //   }
  //   this.qrCodeService.updateSettings(this.userInfo?._id || '', payload).subscribe({
  //     next: (res: IApiResult) => {
  //       if (res.student) {
  //         this.notify.success('QR Code Settings', 'Updated successfully!')
  //         this.userInfo.user.payByQRCodeEnabled = res.student.payByQRCodeEnabled;
  //       }
  //     },
  //     error: () => {
  //       this.notify.error('QR Code Settings', 'Failed to save data')
  //       this.userInfo.user.payByQRCodeEnabled = !this.userInfo?.user.payByQRCodeEnabled;
  //     }
  //   }).add(() => {
  //     this.isSaving = false;
  //     this.isHaveChanges = false;
  //   });
  // }

  public close() {
    this.activeModal.close()
  }

}
