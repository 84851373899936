/* eslint-disable prettier/prettier */
import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GetDisplayDate } from "@portal/shared/functions/get-display-date";
import { IApiResult } from "@portal/shared/models/api-result.model";
import { Observable } from "rxjs";
import { IConsumableOrderFilter } from "../models/order.model";
import { HttpService } from "@portal/shared/services/http/http-service";

@Injectable({
    providedIn: 'root'
})
export class ConsumableOrderService {
    constructor(private httpService: HttpService) { }

    public getOrderList = (filter?: IConsumableOrderFilter): Observable<HttpResponse<IApiResult>> => {
        let url = `consumable-orders?`
            .concat('page=', (filter?.page || 1).toString())
            .concat('&limit=', (filter?.limit || 100).toString());

        if (filter?.school && filter?.school.length) {
            url = url.concat('&school=', filter.school);
        }

        if (filter?.canteen && filter?.canteen.length) {
            url = url.concat('&canteen=', filter.canteen);
        }

        if (filter?.merchant?.length) {
            url = url.concat('&merchant=', filter.merchant);
        }

        if (filter?.orderStatus) {
            url = url.concat(`&orderStatus=${filter.orderStatus}`);
        }

        if (filter?.fromDate?.toString().length) {
            url = url.concat(`&fromDate=${GetDisplayDate(new Date(filter.fromDate))}`);
        }

        if (filter?.toDate?.toString().length) {
            url = url.concat(`&toDate=${GetDisplayDate(new Date(filter.toDate))}`);
        }

        return this.httpService.v2Get(url, 'response');
    }

    public getConsumableOrder = (id: string): Observable<IApiResult> => {
        return this.httpService.v2Get(`consumable-orders/${id}`);
    }

    public toggleConsumableOrderStatus = (orderIds: string[], action: string): Observable<IApiResult> => {
        return this.httpService.v2Patch(`consumable-orders/multi-actions`, { orderIds, action });
    }

    public createCartOrder = (cartDetails: any): Observable<IApiResult> => {
        return this.httpService.v2Post(`consumable-orders`, cartDetails);
    }

    public getPaymentLinkForOrder = (payload: any): Observable<IApiResult> => {
        return this.httpService.v2Post(`noon/initiate-payment`, payload);
    }
}
