<div class="toolbar-wrapper p-0" [ngStyle]="{'z-index': showSignUpAsCanteen ? '1' : '2'}">
    <nav class="navbar navbar-light w-100 p-0">
        <div class="toolbar-container">
            <a class="d-flex justify-content-center" href="https://waki.sa/">
                <img
                    src="../../../../assets/images/logo_purple_horizontal.svg"
                    width="90px"
                    height="auto">
            </a>
            <div class="toolbar-menu-container">
                <div class="dropdown m-auto">
                    <button
                        class="mainBtn"
                        role="button"
                        (click)="onChangedLanguage()">
                        <span class="signup__text">{{ currentLanguage | languageTxt }}</span>
                    </button>
                </div>
            </div>
        </div>
    </nav>
</div>
