/* eslint-disable prettier/prettier */
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { SharedModule } from "../shared.module";
import { UtilitiesService } from "./utilites.service";

export const COMMON_TEXT = {
    LOADING: 'Refreshing data...',
    ERROR: 'There was an error loading data.',
    SUCCESS: 'Successfully'
};

@Injectable({
    providedIn: SharedModule
})
export class NotifyService {
    positionClass = localStorage.getItem('lang') == 'ar' ? 'toast-top-left' : 'toast-top-right';
    private dismissalSound: HTMLAudioElement = new Audio();
    private volume: number = 0.5; // Set the default volume here (between 0 and 1)

    constructor(private toastr: ToastrService, private utils: UtilitiesService) {
        this.dismissalSound.src = '../../../assets/audioes/dismissal_sound.mp3'; // Update the path to your sound file
        this.dismissalSound.volume = this.volume;
        this.dismissalSound.muted = true; //To make the autoplay on HTML5 elements work after the chrome 66 update
    }

    public get position(): string {
        return localStorage.getItem('lang') == 'ar' ? 'toast-top-left' : 'toast-top-right';
    }

    public success(title: string = '', msg: string = ''): void {
        const message = this.utils.isNullOrEmpty(msg) ? COMMON_TEXT.SUCCESS : msg;
        this.toastr.success(this.utils.translate(message), this.utils.translate(title));
    }

    public info(title: string = '', msg: string = ''): void {
        this.toastr.info(this.utils.translate(msg), this.utils.translate(title));
    }

    public warn(title: string = '', msg: string = ''): void {
        this.toastr.warning(this.utils.translate(msg), this.utils.translate(title));
    }

    public error(title: string = '', msg: string = '', e: any = null): void {
        let message = e?.error?.msg || e?.statusText || e?.msg || msg;
        message = this.utils.isNullOrEmpty(message) ? COMMON_TEXT.ERROR : message;
        this.toastr.error(this.utils.translate(message), this.utils.translate(title));
    }

    public setVolume(volume: number): void {
        if (volume >= 0 && volume <= 1) {
            this.volume = volume;
            this.dismissalSound.volume = volume;
        }
    }

    public playDismissalNotificationSound(): void {
        this.dismissalSound.muted = false;
        var sound = this.dismissalSound.play();
        if (sound !== undefined) {
            sound.then(_ => {
                // autoplay starts!
            }).catch(error => console.error(error));
        }
    }

    public showUnauthorizedAccessWarn(): void {
        this.warn('unauthorized.DESCRIPTION');
    }
}
