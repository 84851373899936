/* eslint-disable prettier/prettier */
/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @angular-eslint/no-input-rename */
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    concat,
    Observable,
    of,
    Subject
} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import { ComponentBase } from '../component-base';
import { environment } from 'src/environments/environment';
import { TypingSelectionConfiguration, SelectionConfiguration } from '@portal/shared/models/typeahead-select.model';
import { isNullOrEmptyString } from '@portal/shared/functions/string.function';
import * as _ from 'lodash';

@Component({
    selector: 'app-typeahead-select',
    templateUrl: './typeahead-select.component.html',
    styleUrls: ['./typeahead-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TypeaheadSelectComponent),
            multi: true,
        },
    ],
})
export class TypeaheadSelectComponent extends ComponentBase implements ControlValueAccessor {
    @Input() id = '';
    @Input() formControlName = '';
    @Input() typingConfiguration!: TypingSelectionConfiguration;
    @Input() disabled = false;

    @Output() change = new EventEmitter();

    @Input('value') _value: any;
    public get value() {
        return this._value;
    }
    public set value(value) {
        this._value = value;
        this.onChange(value);
        this.onTouched();
    }

    public data$: Observable<Array<any>>;
    public searching = false;
    public input$ = new Subject<string>();

    private onChange = (value: any) => { };
    private onTouched = () => { };
    private readonly BASE_PATH = `${environment.API_BASE_PATH}/v${environment.API_VERSION}`;

    constructor(private httpClient: HttpClient) {
        super();
        this.data$ = concat(
            of([]), // default items
            this.input$.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                filter((search) => !!search),
                tap(() => (this.searching = true)),
                switchMap((search) => this.httpObservable(search).pipe(
                    finalize(() => this.searching = false),
                )),
                takeUntil(this.destroyed$)
            )
        );
    }

    public get config(): SelectionConfiguration {
        return {
            placeholderText: this.typingConfiguration.placeholder ?? '',
            typeToSearchText: this.typingConfiguration.typeToSearchText ?? 'Start typing to search...',
            loadingText: this.typingConfiguration.loadingText ?? 'Searching...',
            notFoundText: this.typingConfiguration.notFoundText ?? 'No records found',
            minTermLength: this.typingConfiguration.minTermLength ?? 3,
            multiple: this.typingConfiguration.multiple ?? false
        }
    }
    public get labelFieldName(): string {
        if (isNullOrEmptyString(this.typingConfiguration?.bindLabel)) {
            return '';
        }

        const { bindLabel } = this.typingConfiguration;
        if (!bindLabel.includes('.')) {
            return bindLabel;
        }

        const fields: string[] = bindLabel.split('.');
        return fields[fields.length - 1];
    }

    public fieldText(item: any) {
        return this.extractFieldValue(item, this.typingConfiguration.bindLabel);
    }

    public fieldValue(item: any) {
        if (this.typingConfiguration.bindValue === 'user._id') {
            return this.extractFieldValue(item, this.typingConfiguration.bindValue)
        }
        else {
            return item['_id'];
        }
    }

    private httpObservable(search: string): Observable<Array<any>> {
        const apiConfig = this.typingConfiguration.api;
        const searchKey = apiConfig.searchKey || 'search';

        let apiUrl = apiConfig.url;
        let body = apiConfig.body;
        let queryParams: string[] = [];

        if (apiConfig.queryParams) {
            if (!apiUrl.includes('?')) {
                apiUrl += '?';
            }
            queryParams = apiConfig.queryParams.split('&');
        }
        if (apiConfig.method == 'GET') {
            queryParams.push(`${searchKey}=${search}`);
        }
        else if (body) {
            body = {
                ...body,
                [searchKey]: search,
            };
        }
        apiUrl += queryParams.join('&');

        const requestURL = `${this.BASE_PATH}/${decodeURIComponent(apiUrl)}`;
        return this.httpClient.request(apiConfig.method, requestURL, { body }).pipe(
            map((res: any) => {
                if (Array.isArray(res)) {
                    return res;
                }

                const responseKey = this.typingConfiguration.api.responseKey ?? '';
                if (isNullOrEmptyString(responseKey)) {
                    return [];
                }
                if (res[responseKey] && Array.isArray(res[responseKey])) {
                    return res[responseKey];
                }
                return [];
            }),
            catchError(() => of([]))
        );
    }

    private extractFieldValue(obj: any, pattern: any): any {
        return _.get(obj, pattern);
        // if (typeof pattern == 'string')
        //     return this.extractFieldValue(obj, pattern.split('.'));
        // else if (pattern.length == 0) return obj;
        // else return this.extractFieldValue(obj[pattern[0]], pattern.slice(1));
    }

    public writeValue(value: any): void {
        this.value = value;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
