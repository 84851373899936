/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import { CLAIMS } from '@portal/shared/permissions/claims/claim.constants';
import { CLAIM_LABEL, NAV_MENU } from '@portal/shared/enums/nav-menu.enum';
import { MenuItem } from '@portal/shared/models/menu-item.model';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class NavMenuService {
    private readonly homePage: MenuItem = { order: 0, link: '/home', label: NAV_MENU.HOME, type: 'LINK' };

    private readonly reportPage: MenuItem = {
        order: 0,
        link: '/reports',
        label: 'Reports',
        type: 'LINK',
        sub: [
            { order: 1, link: '/canteen-reports', label: 'Canteens Reports', type: 'LINK' },
            { order: 2, link: '/merchant-reports', label: 'Merchants Reports', type: 'LINK' }
        ]
    };

    private readonly canteenReportPage: MenuItem = {
        order: 0,
        link: '/reports',
        label: 'Reports',
        type: 'LINK',
        sub: [
            { order: 1, link: '/canteen-reports', label: 'Canteen Reports', type: 'LINK' }
        ]
    };

    private readonly merchantReportPage: MenuItem = {
        order: 0,
        link: '/reports',
        label: 'Reports',
        type: 'LINK',
        sub: [
            { order: 1, link: '/merchant-reports', label: 'Merchant Reports', type: 'LINK' }
        ]
    };

    private readonly schoolMenu: MenuItem = {
        order: 1,
        link: '/schools',
        label: 'Schools',
        type: 'LINK',
        sub: [
            { order: 10, link: '/schools-list', label: 'SCHOOLS.LIST', type: 'LINK' },
            { order: 20, link: '/subscriptions', label: 'Registration', type: 'LINK' },
            { order: 30, link: '/students', label: NAV_MENU.STUDENTS, type: 'LINK' },
            { order: 40, link: '/classes', label: NAV_MENU.CLASSES, type: 'LINK' },
            // { order: 30, link: '/devices', label: 'Devices', type: 'LINK' },
            { order: 50, link: '/attendance', label: NAV_MENU.ATTENDANCE, type: 'LINK' },
            { order: 60, link: '/dismissal-call', label: NAV_MENU.DISMISSAL_CALL, type: 'LINK' },
            { order: 70, link: '/call-logs', label: NAV_MENU.DISMISSAL_CALL_LOGS, type: 'LINK' },
            { order: 80, link: '/canteen-sales', label: NAV_MENU.CANTEEN_SALES, type: 'LINK' }
        ]
    };

    private readonly businessSchoolMenu: MenuItem = {
        order: 1,
        link: '/schools',
        label: 'Schools',
        type: 'ITEM',
        sub: [
            { order: 30, link: '/students', label: NAV_MENU.STUDENTS, type: 'LINK' },
            { order: 40, link: '/classes', label: NAV_MENU.CLASSES, type: 'LINK' },
            // { order: 30, link: '/devices', label: 'Devices', type: 'LINK' },
            { order: 50, link: '/attendance', label: NAV_MENU.ATTENDANCE, type: 'LINK' },
            { order: 60, link: '/dismissal-call', label: NAV_MENU.DISMISSAL_CALL, type: 'LINK' },
            { order: 70, link: '/call-logs', label: NAV_MENU.DISMISSAL_CALL_LOGS, type: 'LINK' },
            { order: 80, link: '/canteen-sales', label: NAV_MENU.CANTEEN_SALES, type: 'LINK' }
        ]
    };

    private readonly canteenMenu: MenuItem = {
        order: 2,
        link: '/canteens',
        label: 'Canteens',
        type: 'LINK',
        sub: [
            { order: 10, link: '/canteens-list', label: 'Canteens List', type: 'LINK' },
            { order: 20, link: '/subscriptions', label: 'Registration', type: 'LINK' },
            { order: 30, link: '/transactions', label: NAV_MENU.TRANSACTIONS, type: 'LINK' },
            { order: 40, link: '/transfer-request', label: NAV_MENU.TRANSFER_REQUESTS, type: 'LINK' },
            { order: 50, link: '/money-requests', label: NAV_MENU.MONEY_REQUESTS, type: 'LINK' },
            { order: 60, link: '/fee', label: NAV_MENU.FEES, type: 'LINK' },
            { order: 70, link: '/food-items', label: NAV_MENU.FOOD_ITEMS, type: 'LINK' },
            { order: 80, link: '/canteen-menu', label: NAV_MENU.FOOD_MENU, type: 'LINK' },
            { order: 90, link: '/food-orders', label: NAV_MENU.FOOD_ORDERS, type: 'LINK' },
            { order: 100, link: '/coupons', label: NAV_MENU.COUPONS, type: 'LINK' },
            { order: 110, link: '/coupons-order', label: NAV_MENU.COUPON_ORDERS, type: 'LINK' },
        ]
    };

    private readonly businessCanteenMenu: MenuItem = {
        order: 2,
        link: '/canteens',
        label: 'Canteens',
        type: 'ITEM',
        sub: [
            { order: 30, link: '/transactions', label: NAV_MENU.TRANSACTIONS, type: 'LINK' },
            { order: 40, link: '/transfer-request', label: NAV_MENU.TRANSFER_REQUESTS, type: 'LINK' },
            { order: 50, link: '/money-requests', label: NAV_MENU.MONEY_REQUESTS, type: 'LINK' },
            { order: 60, link: '/fee', label: NAV_MENU.FEES, type: 'LINK' },
            { order: 70, link: '/food-items', label: NAV_MENU.FOOD_ITEMS, type: 'LINK' },
            { order: 80, link: '/canteen-menu', label: NAV_MENU.FOOD_MENU, type: 'LINK' },
            { order: 90, link: '/food-orders', label: NAV_MENU.FOOD_ORDERS, type: 'LINK' },
            { order: 100, link: '/coupons', label: NAV_MENU.COUPONS, type: 'LINK' },
            { order: 110, link: '/coupons-order', label: NAV_MENU.COUPON_ORDERS, type: 'LINK' },
        ]
    };

    private readonly merchantMenu: MenuItem = {
        order: 2,
        link: '/merchants',
        label: 'Merchants',
        type: 'LINK',
        sub: [
            { order: 10, link: '/merchants-list', label: 'Merchants List', type: 'LINK' },
            { order: 20, link: '/registration', label: 'Registration', type: 'LINK' },
            { order: 30, link: '/transactions', label: NAV_MENU.TRANSACTIONS, type: 'LINK' },
            { order: 40, link: '/transfer-request', label: NAV_MENU.TRANSFER_REQUESTS, type: 'LINK' },
            { order: 50, link: '/fee', label: NAV_MENU.FEES, type: 'LINK' },
            { order: 60, link: '/merchant-items', label: NAV_MENU.MERCHANT_ITEMS, type: 'LINK' },
            { order: 70, link: '/catalogue', label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES, type: 'LINK' },
            { order: 80, link: '/orders', label: NAV_MENU.MERCHANT_ORDERS, type: 'LINK' },
            { order: 90, link: '/events-list', label: NAV_MENU.MERCHANT_EVENTS, type: 'LINK' },
            { order: 100, link: '/event-sales', label: NAV_MENU.MERCHANT_EVENT_SALES, type: 'LINK' },
        ]
    };

    private readonly businessMerchantMenu: MenuItem = {
        order: 1,
        link: '/merchants',
        label: 'Merchants',
        type: 'ITEM',
        sub: [
            { order: 30, link: '/transactions', label: NAV_MENU.TRANSACTIONS, type: 'LINK' },
            { order: 40, link: '/transfer-request', label: NAV_MENU.TRANSFER_REQUESTS, type: 'LINK' },
            { order: 50, link: '/fee', label: NAV_MENU.FEES, type: 'LINK' },
            { order: 60, link: '/merchant-items', label: NAV_MENU.MERCHANT_ITEMS, type: 'LINK' },
            { order: 70, link: '/catalogue', label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES, type: 'LINK' },
            { order: 80, link: '/orders', label: NAV_MENU.MERCHANT_ORDERS, type: 'LINK' }
        ]
    };

    private readonly adultsMenu: MenuItem = {
        order: 3,
        link: '/waki-users',
        label: 'WAKI Users',
        type: 'LINK',
        sub: [
            { order: 10, link: '/waki-users-list', label: 'WAKI Users List', type: 'LINK' },
            { order: 20, link: '/dependents', label: 'Dependents List', type: 'LINK' },
            { order: 30, link: '/transactions', label: 'Transactions', type: 'LINK' },
            { order: 40, link: '/refund-requests', label: 'Refund Request', type: 'LINK' },
            { order: 50, link: '/card-designs', label: 'Card Designs', type: 'LINK' },
            { order: 60, link: '/signup-requests', label: 'Signup Requests', type: 'LINK' },
        ]
    };

    private readonly notificationsMenu: MenuItem = {
        order: 5,
        link: '/notifications',
        label: 'Notifications',
        type: 'LINK',
        sub: [
            { order: 10, link: '/push-notifications', label: NAV_MENU.PUSH_NOTIFICATION, type: 'LINK' },
            { order: 20, link: '/history', label: NAV_MENU.NOTIFICATION_HX, type: 'LINK' },
        ]
    };

    private readonly businessStoreMenu: MenuItem = {
        order: 6,
        link: '/business-store',
        label: 'Business Store',
        type: 'LINK',
        sub: [
            { order: 10, link: '/store-items', label: 'Store Items', type: 'LINK' },
            { order: 20, link: '/orders', label: 'Orders', type: 'LINK' },
            { order: 30, link: '/devices', label: 'Devices', type: 'LINK' },
            { order: 40, link: '/card-store', label: 'Card Store', type: 'LINK' },
            { order: 50, link: '/card-orders', label: 'Card Orders', type: 'LINK' },
            { order: 60, link: '/discount-offers', label: 'Offers', type: 'LINK' },
            { order: 70, link: '/digital-card-store', label: 'Digital Cards Store', type: 'LINK' },
            { order: 80, link: '/digital-card-orders', label: 'Digital Cards Order', type: 'LINK' },
        ]
    }

    private readonly nonAdminBusinessStoreMenu: MenuItem = {
        order: 6,
        link: '/business-store',
        label: 'Business Store',
        type: 'ITEM',
        sub: [
            { order: 10, link: '/store', label: NAV_MENU.BUSINESS_STORE, type: 'LINK' },
            { order: 20, link: '/orders', label: NAV_MENU.BUSINESS_ORDERS, type: 'LINK' }
        ]
    }

    private readonly advertisementMenu: MenuItem = {
        order: 7,
        link: '/advertisements',
        label: 'ADS.Title',
        type: 'LINK',
        sub: [
            { order: 10, link: '/services', label: 'ADS.Services', type: 'LINK', hasAddButton: true, addButtonRoute: '/advertisements/services/new' },
            { order: 20, link: '/clients', label: 'ADS.Clients', type: 'LINK', hasAddButton: true, addButtonRoute: '/advertisements/clients/new' },
            { order: 30, link: '/agents', label: 'ADS.Agents', type: 'LINK', hasAddButton: true, addButtonRoute: '/advertisements/agents/new' },
            { order: 40, link: '/campaigns', label: 'ADS.Campaigns', type: 'LINK', hasAddButton: true, addButtonRoute: '/advertisements/campaigns/new' },
            { order: 50, link: '/schools', label: 'ADS.Schools', type: 'LINK' },
            { order: 60, link: '/orders', label: 'ADS.Orders', type: 'LINK' }
        ]
    }

    private readonly userMenu: MenuItem = {
        order: 8,
        link: '/users',
        label: 'Users',
        type: 'LINK',
        sub: [
            { order: 10, link: '/current-users', label: 'Admins', type: 'LINK' },
            { order: 20, link: '/business-users', label: 'Business Users', type: 'LINK' },
        ]
    };

    private readonly nonAdminUserMenu: MenuItem = {
        order: 8,
        link: '/users',
        label: 'Users',
        type: 'LINK',
        sub: [
            { order: 10, link: '/current-users', label: 'Users List', type: 'LINK' }
        ]
    };

    private readonly logsMenu: MenuItem = { order: 9, link: '/logs', label: 'Logs', type: 'LINK' };

    private readonly callSystemUserMenu: MenuItem = {
        order: 10,
        link: '/schools',
        label: 'Schools',
        type: 'ITEM',
        sub: [
            { order: 10, link: '/dismissal-call', label: 'Call System', type: 'LINK' },
        ]
    };

    private readonly EXPAND_BIZ_MENU = [
        {
            menuItem: this.homePage,
            type: 'general',
            value: [NAV_MENU.HOME],
        },
        {
            menuItem: this.reportPage,
            type: 'canteen',
            value: [
                NAV_MENU.CANTEENS_REPORTS
            ],
        },
        {
            menuItem: this.businessCanteenMenu,
            type: 'canteen',
            value: [
                NAV_MENU.TRANSACTIONS,
                NAV_MENU.TRANSFER_REQUESTS,
                NAV_MENU.MONEY_REQUESTS,
                NAV_MENU.FEES,
                NAV_MENU.FOOD_ITEMS,
                NAV_MENU.FOOD_MENU,
                NAV_MENU.FOOD_ORDERS,
                NAV_MENU.COUPONS,
                NAV_MENU.COUPON_ORDERS,
            ],
        },
        {
            menuItem: this.reportPage,
            type: 'merchant',
            value: [
                NAV_MENU.MERCHANTS_REPORTS
            ],
        },
        {
            menuItem: this.businessMerchantMenu,
            type: 'merchant',
            value: [
                NAV_MENU.TRANSACTIONS,
                NAV_MENU.TRANSFER_REQUESTS,
                NAV_MENU.FEES,
                NAV_MENU.MERCHANT_ITEMS,
                NAV_MENU.MERCHANT_PRODUCT_CATALOGUES,
                NAV_MENU.MERCHANT_ORDERS,
            ],
        },
        {
            menuItem: this.businessSchoolMenu,
            type: 'school',
            value: [
                NAV_MENU.STUDENTS,
                NAV_MENU.CLASSES,
                NAV_MENU.ATTENDANCE,
                NAV_MENU.DISMISSAL_CALL,
                NAV_MENU.DISMISSAL_CALL_LOGS,
                NAV_MENU.CANTEEN_SALES,
            ],
        },
        {
            menuItem: this.notificationsMenu,
            type: 'general',
            value: [
                NAV_MENU.PUSH_NOTIFICATION,
                NAV_MENU.NOTIFICATION_HX
            ],
        },
        {
            menuItem: this.nonAdminBusinessStoreMenu,
            type: 'general',
            value: [
                NAV_MENU.BUSINESS_STORE,
                NAV_MENU.BUSINESS_ORDERS
            ],
        },
    ];

    public generateAdminMenu = (): MenuItem[] => {
        return [
            this.homePage,
            this.reportPage,
            this.schoolMenu,
            this.canteenMenu,
            this.merchantMenu,
            this.adultsMenu,
            this.notificationsMenu,
            this.businessStoreMenu,
            this.advertisementMenu,
            this.userMenu,
            this.logsMenu,
        ];
    };

    public generateSuperSchoolMenu = (): MenuItem[] => {
        // Full claims as super user
        return [
            this.homePage,
            this.businessSchoolMenu,
            this.notificationsMenu,
            this.nonAdminBusinessStoreMenu,
            this.nonAdminUserMenu
        ];
    };

    public generateSuperCanteenMenu = (): MenuItem[] => {
        // Full claims as super user
        return [
            this.homePage,
            this.canteenReportPage,
            this.businessCanteenMenu,
            this.notificationsMenu,
            this.nonAdminBusinessStoreMenu,
            this.nonAdminUserMenu
        ];
    };

    public generateNormalSchoolMenu = (claims: string[] = []): MenuItem[] => {
        return this.getMenuWithUserClaims(claims, 'school');
    };

    public generateNormalCanteenMenu = (claims: string[] = []): MenuItem[] => {
        return this.getMenuWithUserClaims(claims, 'canteen');
    };

    public generateNormalMerchantMenu = (claims: string[] = []): MenuItem[] => {
        return this.getMenuWithUserClaims(claims, 'merchant');
    }

    public generateBusinessMerchantMenu = (): MenuItem[] => {
        return [
            this.homePage,
            this.merchantReportPage,
            this.businessMerchantMenu,
            this.nonAdminBusinessStoreMenu,
            this.nonAdminUserMenu
        ];
    };

    public generateCallSystemUserMenu = (): MenuItem[] => {
        return [this.callSystemUserMenu];
    }

    private getLabelClaim = (claims: string[] = []): string[] => {
        const labels: string[] = [];
        for (const element of CLAIMS) {
            if (element.claimValue?.length) {
                const hasPermission = element.claimValue.find(claim => claim.includes('.VIEW_') && claims.includes(claim));
                if (hasPermission) {
                    labels.push(element.claimLabel === CLAIM_LABEL.VIEW_ORDERS ? NAV_MENU.BUSINESS_ORDERS : element.label);
                }
            }
        }
        return _.uniq(labels);
    }

    private getMenuWithUserClaims(claims: string[], type: 'school' | 'canteen' | 'merchant'): MenuItem[] {
        const expanded_menus = this.getLabelClaim(claims);
        const result = [];
        const normalBizSidebar = this.EXPAND_BIZ_MENU.filter((i) => ['general', type].includes(i.type));
        for (const navItem of normalBizSidebar) {
            if (navItem) {
                const menus = expanded_menus.concat(navItem.value);

                if (_.uniq(menus)?.length < menus.length) {
                    if (navItem.value.length && navItem.menuItem.sub?.length) {
                        const filterExpandSub = navItem.menuItem.sub.filter(element => expanded_menus.includes(element.label) && element.visible);
                        navItem.menuItem.sub = filterExpandSub;
                        result.push(navItem.menuItem);
                    } else {
                        result.push(navItem.menuItem);
                    }
                }
            }
        }
        return result;
    }
}
