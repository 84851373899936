<div class="dialogContainer">
    <p class="header {{dialogType === DialogType.Warning && 'text-danger'}}">
        {{ title | translate }}
    </p>
    <div class="d-flex flex-column gap-1 px-3 align-items-center" translate>
        <p class="overflow-break-word mb-0 {{ messageSize }}" [innerHTML]="message | translate"></p>
    </div>
    <div class="d-flex gap-4 justify-content-center" >
        <app-primary-button  (clickButton)="continue()" [text]="buttonText | translate" addClass="primaryBtnSm {{dialogType === DialogType.Warning && 'bg-danger'}}"></app-primary-button>
        <app-secondary-button (clickButton)="cancel()" [text]="cancelButtonText | translate" addClass="secondaryBtnSm"></app-secondary-button>
    </div>
</div>
