<div class="content-container">
    <div class="content-form-container">
        <app-login-form
            [form]="form"
            [disableLoginButton]="!FormIsValid"
            [error]="alert"
            [isLoading]="isLoading"
            (login)="onLogin()"
            (goToForgotPassword)="goToForgotPassword()"></app-login-form>
    </div>
</div>
