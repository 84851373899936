<div class="login-container m-auto">
    <div class="login-form-container">
        <app-backup-login-form
            [form]="form"
            [disableLoginButton]="!FormIsValid"
            [error]="alert"
            [isLoading]="isLoading"
            (login)="onLogin()"
            (goToForgotPassword)="goToForgotPassword()"></app-backup-login-form>
    </div>
</div>
