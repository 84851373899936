import { IStudentCallLogFilter, IStudentFilter } from "./student.model";

export const defaultStudentFilter: IStudentFilter = {
    search: '',
    page: 1,
    limit: 100
};

export const defaultStudentCallLogFilter: IStudentCallLogFilter = {
    fromDate: new Date(),
    toDate: new Date(),
    page: 1,
    limit: 100
};

export const pickedByUserType: { [key: string]: string } = {
    PARENT: "Parent",
    GATE_GUARD_USER: "Gate Guard User",
    AUTHORIZED_PERSON_USER: "Authorized Person User"
};