import { UserRoles } from "../constants/user-roles.constants";
import { UserClientContext } from "../models/user-client-context.model";

export function hasAdminOrSuperAdminAccess(context: string[] = []) {
    return hasRoles([UserRoles.WAKI_SUPER_ADMIN, UserRoles.WAKI_ADMIN], context);
}

export function hasSuperAdminAccess(context: string[] = []) {
    return hasRoles([UserRoles.WAKI_SUPER_ADMIN], context);
}

export function hasAdminAccess(context: string[] = []) {
    return hasRoles([UserRoles.WAKI_SUPER_ADMIN, UserRoles.WAKI_ADMIN], context);
}

export function hasSchoolOrSuperSchoolAccess(context: string[] = []) {
    return hasRoles([UserRoles.SUPER_SCHOOL_USER, UserRoles.SCHOOL_USER], context);
}

export function hasSuperSchoolUserAccess(context: string[] = []) {
    return hasRoles([UserRoles.SUPER_SCHOOL_USER], context);
}

export function hasSchoolUserAccess(context: string[] = []) {
    return hasRoles([UserRoles.SCHOOL_USER], context);
}

export function hasCanteenOrSuperCanteenAccess(context: string[] = []) {
    return hasRoles([UserRoles.SUPER_CANTEEN_USER, UserRoles.CANTEEN_USER, UserRoles.CANTEEN_APP_USER], context);
}

export function hasSuperCanteenUserAccess(context: string[] = []) {
    return hasRoles([UserRoles.SUPER_CANTEEN_USER], context);
}

export function hasCanteenUserAccess(context: string[] = []) {
    return hasRoles([UserRoles.CANTEEN_USER], context);
}

export function hasCanteenAppUserAccess(context: string[] = []) {
    return hasRoles([UserRoles.CANTEEN_APP_USER], context);
}

export function hasCallSystemUserAccess(context: string[] = []) {
    return hasRoles([UserRoles.CALL_SYSTEM_WEB_USER], context);
}

export function hasMerchantOrSuperMerchantAccess(context: string[] = []) {
    return hasRoles([UserRoles.MERCHANT_APP_USER, UserRoles.MERCHANT_USER, UserRoles.SUPER_MERCHANT_USER], context);
}

export function hasSuperMerchantUserAccess(context: string[] = []) {
    return hasRoles([UserRoles.SUPER_MERCHANT_USER], context);
}

export function hasMerchantUserAccess(context: string[] = []) {
    return hasRoles([UserRoles.MERCHANT_USER], context);
}

export function hasMerchantAppUserAccess(context: string[] = []) {
    return hasRoles([UserRoles.MERCHANT_APP_USER], context);
}

export function hasPermissionToGetUserList(context: string[] = []) {
    return hasRoles([
        UserRoles.WAKI_SUPER_ADMIN,
        UserRoles.WAKI_ADMIN,
        UserRoles.SUPER_SCHOOL_USER,
        UserRoles.SUPER_CANTEEN_USER,
        UserRoles.SUPER_MERCHANT_USER,
        UserRoles.MERCHANT_USER
    ], context)
}

export function hasPermissionToAccessNonAdminBizStore(context: string[] = []) {
    return hasRoles([
        UserRoles.SUPER_SCHOOL_USER,
        UserRoles.SUPER_CANTEEN_USER,
        UserRoles.SUPER_MERCHANT_USER,
        UserRoles.MERCHANT_USER
    ], context)
}

export function canAccessSchools(context: UserClientContext): boolean {
    return hasRoles([
        UserRoles.WAKI_SUPER_ADMIN,
        UserRoles.WAKI_ADMIN,
        UserRoles.SUPER_SCHOOL_USER,
        UserRoles.SCHOOL_USER,
        UserRoles.ATTENDANCE_APP_USER,
        UserRoles.CALL_SYSTEM_WEB_USER,
        UserRoles.GATE_GUARD_USER
    ], context.roles);
}

export function canAccessCanteens(context: UserClientContext): boolean {
    return hasRoles([
        UserRoles.WAKI_SUPER_ADMIN,
        UserRoles.WAKI_ADMIN,
        UserRoles.SUPER_CANTEEN_USER,
        UserRoles.CANTEEN_USER,
        UserRoles.CANTEEN_APP_USER,
    ], context.roles);
}

export function canAccessMerchants(context: UserClientContext): boolean {
    return hasRoles([
        UserRoles.WAKI_SUPER_ADMIN,
        UserRoles.WAKI_ADMIN,
        UserRoles.SUPER_MERCHANT_USER,
        UserRoles.MERCHANT_USER,
        UserRoles.MERCHANT_APP_USER,
    ], context.roles);
}

export function canViewDashboardStats(context: UserClientContext): boolean {
    return hasClaimPermission(context, 'HOME.VIEW_DASHBOARD_STATS');
}

export function canViewDashboardOnlineOrder(context: UserClientContext): boolean {
    return hasCanteenOrSuperCanteenAccess(context.roles) && hasClaimPermission(context, 'HOME.VIEW_ONLINE_ORDERS');
}

// Schools
export function canViewSchools(context: UserClientContext): boolean {
    return hasAdminOrSuperAdminAccess(context.roles) || hasSuperSchoolUserAccess(context.roles);
}

export function canViewStudents(context: UserClientContext): boolean {
    return canAccessSchools(context) && hasClaimPermission(context, 'STUDENTS.VIEW_STUDENT_LIST');
}

export function canEditStudentInfo(context: UserClientContext): boolean {
    return canAccessSchools(context) && hasClaimPermission(context, 'STUDENTS.EDIT_STUDENT');
}

export function canChangeStudentCardStatus(context: UserClientContext): boolean {
    return canAccessSchools(context) && hasClaimPermission(context, 'STUDENTS.EDIT_CARD_STATUS');
}

export function canViewClasses(context: UserClientContext): boolean {
    return canAccessSchools(context) && hasClaimPermission(context, 'CLASSES.VIEW_CLASS_LIST');
}

export function canAddClass(context: UserClientContext): boolean {
    return canAccessSchools(context) && hasClaimPermission(context, 'CLASSES.CREATE_CLASS');
}

export function canEditClass(context: UserClientContext): boolean {
    return canAccessSchools(context) && hasClaimPermission(context, 'CLASSES.EDIT_CLASS');
}

export function canRegulationClass(context: UserClientContext): boolean {
    return canAccessSchools(context) && hasClaimPermission(context, 'CLASSES.EDIT_REGULATIONS');
}

export function canViewDevice(context: UserClientContext): boolean {
    return hasAdminOrSuperAdminAccess(context.roles);
}

export function canViewAttendance(context: UserClientContext): boolean {
    return canAccessSchools(context) && hasClaimPermission(context, 'ATTENDANCE.VIEW_ATTENDANCE_LIST');
}

export function canViewCanteenSale(context: UserClientContext): boolean {
    return canAccessSchools(context) && hasClaimPermission(context, 'CANTEEN_SALES.VIEW_SALES_LIST');
}

export function canViewDismissalCall(context: UserClientContext): boolean {
    return canAccessSchools(context) && hasClaimPermission(context, 'DISMISSAL_CALL.VIEW_PAGE');
}

export function canViewCallLog(context: UserClientContext): boolean {
    return canAccessSchools(context) && hasClaimPermission(context, 'DISMISSAL_CALL_LOGS.VIEW_CALL_LIST');
}

// Canteens
export function canViewTransactions(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'TRANSACTIONS.VIEW_TRANSACTION_LIST');
}

export function canViewTransferRequests(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'TRANSFER_REQUESTS.VIEW_TRANSFER_REQUEST_LIST');
}

export function canViewMoneyRequests(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'MONEY_REQUESTS.VIEW_MONEY_REQUEST_LIST');
}

export function canViewFees(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FEES.VIEW_FEE_LIST');
}

export function canViewFoodItems(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_ITEMS.VIEW_FOODITEM_LIST');
}

export function canAddFoodItem(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_ITEMS.ADD_ITEM');
}

export function canViewFoodItemDetails(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_ITEMS.VIEW_ITEM_DETAILS');
}

export function canAddCustomization(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_ITEMS.ADD_CUSTOMIZATION');
}

export function canViewSchoolFoodMenu(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_MENU.VIEW_MENU_LIST');
}

export function canEditSchoolFoodMenu(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_MENU.EDIT_SCHOOL_MENU');
}

export function canAddInventorySchoolFoodMenu(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_MENU.ADD_INVENTORY');
}

export function canEditInventorySchoolFoodMenu(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_MENU.EDIT_INVENTORY_NAME');
}

export function canApplyQuantityToAllSchoolFoodMenu(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_MENU.APPLY_QUANTITY_TO_ALL');
}

export function canResetPricesSchoolFoodMenu(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_MENU.RESET_PRICES');
}

export function canCustomizeColumnsSchoolFoodMenu(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_MENU.CUSTOMIZED_COLUMNS');
}

export function canAddFoodItemsToSchoolFoodMenu(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_MENU.ADD_FOOD_ITEMS');
}

export function canDeleteFoodItemsFromSchoolFoodMenu(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_MENU.DEL_FOOD_ITEMS');
}

export function canViewFoodOrders(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_ORDERS.VIEW_FOODORDER_LIST');
}

export function canChangeFoodOrderStatus(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_ORDERS.EDIT_ORDER_STATUS');
}

export function canMarkFoodOrdersDelivered(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_ORDERS.MARK_ALL_ORDERS_DELIVERED');
}

export function canMarkFoodOrdersRejected(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'FOOD_ORDERS.MARK_ALL_ORDERS_REJECTED');
}

export function canViewCoupons(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'COUPONS.VIEW_COUPON_LIST');
}

export function canAddCoupon(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'COUPONS.CREATE_COUPON');
}

export function canEditCouponDetails(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'COUPONS.EDIT_COUPON');
}

export function canChangeCouponStatus(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'COUPONS.PUBLISH_UNPUBLISH_COUPON');
}

export function canViewCouponOrders(context: UserClientContext): boolean {
    return canAccessCanteens(context) && hasClaimPermission(context, 'COUPON_ORDERS.VIEW_ORDER_LIST');
}

// Merchants
export function canViewMerchantEvents(context: UserClientContext): boolean {
    // TODO: add condition to check view events list permission
    return canAccessMerchants(context);
}
export function canAddMerchantEvent(context: UserClientContext): boolean {
    // TODO: add condition to check add new event permission
    return canAccessMerchants(context);
}
export function canEditMerchantEventDetails(context: UserClientContext): boolean {
    // TODO: add condition to check view/edit event details permission
    return canAccessMerchants(context);
}
export function canViewMerchantEventSales(context: UserClientContext): boolean {
    // TODO: add condition to check view events list permission
    return canAccessMerchants(context) && hasClaimPermission(context, 'MERCHANT_EVENT_SALES.VIEW_SALES');
}

// Notifications
export function canViewNotification(context: UserClientContext): boolean {
    return hasClaimPermission(context, 'NOTIFICATIONS.VIEW_PAGE');
}

export function canViewNotificationHistory(context: UserClientContext): boolean {
    return hasClaimPermission(context, 'NOTIFICATIONS.VIEW_NOTIFICATION_HISTORY');
}

export function hasNotificationsPermission(context: UserClientContext): boolean {
    return hasAdminOrSuperAdminAccess(context.roles) || canViewNotification(context) || canViewNotificationHistory(context);
}

// Business Store
export function canViewBusinessStoreItems(context: UserClientContext): boolean {
    return hasClaimPermission(context, 'BUSINESS_STORE.VIEW_STORE_ITEM_LIST');
}

export function canBuyStoreItems(context: UserClientContext): boolean {
    return hasClaimPermission(context, 'BUSINESS_STORE.BUYING');
}

export function canViewBusinessStoreOrders(context: UserClientContext): boolean {
    return hasClaimPermission(context, 'BUSINESS_ORDERS.VIEW_ORDER_LIST');
}

// Main functions
const hasRoles = (requiredClaims: string[], context: string[] = []): boolean => {
    if (!requiredClaims.length) {
        return true;
    }
    return !!requiredClaims.filter((value) => context.includes(value))?.length;
}

const hasClaimPermission = (context: UserClientContext, requiredClaim: string): boolean =>
    context && context.claims!.includes(requiredClaim);
