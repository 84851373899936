/* eslint-disable prettier/prettier */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'statistics' })
export class StatisticsPipe implements PipeTransform {
    transform(value = 'DEFAULT'): string {
        const types: any = {
            ['totalFundingAmount']: 'Funding Amount',
            ['totalPurchasedAmount']: 'Purchased Amount',
            ['totalNoOfPurchased']: '# of Purchased',
            ['totalNoOfTransactions']: '# of Transactions',
            ['totalCanteens']: 'Canteens',
            ['totalSchools']: 'Schools',
            ['totalRegisteredAdults']: 'Registered Adults',
            ['totalRegisteredStudents']: 'Registered Students',
            ['netFundingAmount']: 'Net Fund',
            ['totalCardOrdersAmount']: 'Card orders amount',
            ['totalWristbandOrdersAmount']: 'Wristband orders amount',
            ['totalInventories']: 'Inventories',
            ['totalBranches']: 'Branches',
            ['totalTransactionsAmount']: 'Total Sales',
            ['wakiTotalSales']: 'Total Sales',
            ['DEFAULT']: ''
        };
        return types[value];
    }
}
