/* eslint-disable prettier/prettier */
import { Pipe, PipeTransform } from '@angular/core';
import { TransactionType, TransactionTypeEnum } from '../models/transaction.model';
@Pipe({ name: 'transactionType', pure: false })
export class TransactionTypePipe implements PipeTransform {
    transform(value = 'NONE'): string {
        const typeKey = Object.keys(TransactionTypeEnum)
            .find((key: string) => TransactionTypeEnum[key] === value)

        if (!typeKey) {
            return ''
        }
        return TransactionType[typeKey]
    }
}
