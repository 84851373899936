<form *ngIf="form" [formGroup]="form">
    <div class="container">
        <div class="">
            <div class="row">
                <!-- <ng-container *ngFor="let item of form.controls | keyvalue; let index = index">
                    <ng-container *ngIf="item.key as control" class="col-12 mt-3"> -->
                        <div class="col-12 mt-3">
                            {{'National ID' | translate}} <span class="asterix">*</span>
                            <div class="d-flex align-items-center gap-3">
                                <label for="nationalID" class="doc-field" translate>
                                    <div class="form-control form-control-sm"
                                        [class.is-invalid]="form.get('nationalID')?.invalid && (form.get('nationalID')?.dirty || form.get('nationalID')?.touched)">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="31" height="29" rx="9.5" fill="#544D7E"
                                                stroke="#544D7E" />
                                            <path
                                                d="M15.125 18.5V11.3687L12.85 13.6437L11.625 12.375L16 8L20.375 12.375L19.15 13.6437L16.875 11.3687V18.5H15.125ZM10.75 22C10.2688 22 9.85663 21.8285 9.51363 21.4855C9.17063 21.1425 8.99942 20.7307 9 20.25V17.625H10.75V20.25H21.25V17.625H23V20.25C23 20.7312 22.8285 21.1434 22.4855 21.4864C22.1425 21.8294 21.7307 22.0006 21.25 22H10.75Z"
                                                fill="white" />
                                        </svg>
                                        <input id="nationalID"
                                               type="file" 
                                               name="nationalID" 
                                               accept=".pdf,.png,.jpg,.jpeg"
                                               [class.is-invalid]="form.get('nationalID')?.invalid && (form.get('nationalID')?.dirty || form.get('nationalID')?.touched)"
                                               (change)="onFilePicked($event, 'nationalID')" />
                                        <span> {{form.get('nationalID')?.value?.name }}</span>
                                        <span *ngIf="!form.get('nationalID')?.value"> {{(disableViewIcon('nationalID') ? "Choose file" : "Reupload file") | translate}}</span>
                                    </div>
                                    <div *ngIf="form.get('nationalID')?.invalid && (form.get('nationalID')?.dirty || form.get('nationalID')?.touched)"
                                        class="text-danger" translate>
                                        Required
                                    </div>
                                </label>
                                <ng-container *ngIf="isAdmin">
                                    <button [disabled]="disableViewIcon('nationalID')" class="dataButton" (click)="viewDoc('nationalId')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 23 24 "><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                        
                        <div *ngIf="dataForm?.get('merchantBusinessType')?.value === merchantSignUpEnum.CommercialRecord" class="col-12 mt-3">
                            {{'Commercial Records' | translate}} <span class="asterix">*</span>
                            <div class="d-flex align-items-center gap-3">
                                <label for="commercialRecords" class="doc-field" translate>
                                    <div class="form-control form-control-sm"
                                        [class.is-invalid]="form.get('commercialRecords')?.invalid && (form.get('commercialRecords')?.dirty || form.get('commercialRecords')?.touched)">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="31" height="29" rx="9.5" fill="#544D7E"
                                                stroke="#544D7E" />
                                            <path
                                                d="M15.125 18.5V11.3687L12.85 13.6437L11.625 12.375L16 8L20.375 12.375L19.15 13.6437L16.875 11.3687V18.5H15.125ZM10.75 22C10.2688 22 9.85663 21.8285 9.51363 21.4855C9.17063 21.1425 8.99942 20.7307 9 20.25V17.625H10.75V20.25H21.25V17.625H23V20.25C23 20.7312 22.8285 21.1434 22.4855 21.4864C22.1425 21.8294 21.7307 22.0006 21.25 22H10.75Z"
                                                fill="white" />
                                        </svg>
                                        <input id="commercialRecords"
                                               type="file" 
                                               name="commercialRecords" 
                                               accept=".pdf,.png,.jpg,.jpeg"
                                               [class.is-invalid]="form.get('commercialRecords')?.invalid && (form.get('commercialRecords')?.dirty || form.get('commercialRecords')?.touched)"
                                               (change)="onFilePicked($event, 'commercialRecords')" />
                                        <span> {{form.get('commercialRecords')?.value?.name }}</span>
                                        <span *ngIf="!form.get('commercialRecords')?.value"> {{(disableViewIcon('commercialRecords') ? "Choose file" : "Reupload file") | translate}}</span>
                                    </div>
                                    <div *ngIf="form.get('commercialRecords')?.invalid && (form.get('commercialRecords')?.dirty || form.get('commercialRecords')?.touched)"
                                        class="text-danger" translate>
                                        Required
                                    </div>
                                </label>
                                <ng-container *ngIf="isAdmin">
                                    <button [disabled]="disableViewIcon('commercialRecords')" class="dataButton" (click)="viewDoc('commercialRecords')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 23 24 "><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>
                                    </button>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            {{'National Address' | translate}}
                            <div class="d-flex align-items-center gap-3">
                                <label for="nationalAddress" class="doc-field" translate>
                                    <div class="form-control form-control-sm"
                                        [class.is-invalid]="form.get('nationalAddress')?.invalid && (form.get('nationalAddress')?.dirty || form.get('nationalAddress')?.touched)">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="31" height="29" rx="9.5" fill="#544D7E"
                                                stroke="#544D7E" />
                                            <path
                                                d="M15.125 18.5V11.3687L12.85 13.6437L11.625 12.375L16 8L20.375 12.375L19.15 13.6437L16.875 11.3687V18.5H15.125ZM10.75 22C10.2688 22 9.85663 21.8285 9.51363 21.4855C9.17063 21.1425 8.99942 20.7307 9 20.25V17.625H10.75V20.25H21.25V17.625H23V20.25C23 20.7312 22.8285 21.1434 22.4855 21.4864C22.1425 21.8294 21.7307 22.0006 21.25 22H10.75Z"
                                                fill="white" />
                                        </svg>
                                        <input id="nationalAddress"
                                               type="file" 
                                               name="nationalAddress" 
                                               accept=".pdf,.png,.jpg,.jpeg"
                                               [class.is-invalid]="form.get('nationalAddress')?.invalid && (form.get('nationalAddress')?.dirty || form.get('nationalAddress')?.touched)"
                                               (change)="onFilePicked($event, 'nationalAddress')" />
                                        <span> {{form.get('nationalAddress')?.value?.name }}</span>
                                        <span *ngIf="!form.get('nationalAddress')?.value">{{(disableViewIcon('nationalAddress') ? "Choose file" : "Reupload file") | translate}}</span>
                                    </div>
                                    <div *ngIf="form.get('nationalAddress')?.invalid && (form.get('nationalAddress')?.dirty || form.get('nationalAddress')?.touched)"
                                        class="text-danger" translate>
                                        Required
                                    </div>
                                </label>
                                <ng-container *ngIf="isAdmin">
                                    <button [disabled]="disableViewIcon('nationalAddress')" class="dataButton" (click)="viewDoc('nationalAddress')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 23 24 "><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>
                                    </button>
                                </ng-container>
                            </div>
                        </div>

                        <div *ngIf="dataForm?.get('hasVAT')?.value" class="col-12 mt-3">
                            {{'VAT Certificate' | translate}} <span class="asterix">*</span>
                            <div class="d-flex align-items-center gap-3">
                                <label for="vatCertificate" class="doc-field" translate>
                                    <div class="form-control form-control-sm"
                                        [class.is-invalid]="form.get('vatCertificate')?.invalid && (form.get('vatCertificate')?.dirty || form.get('vatCertificate')?.touched)">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="31" height="29" rx="9.5" fill="#544D7E"
                                                stroke="#544D7E" />
                                            <path
                                                d="M15.125 18.5V11.3687L12.85 13.6437L11.625 12.375L16 8L20.375 12.375L19.15 13.6437L16.875 11.3687V18.5H15.125ZM10.75 22C10.2688 22 9.85663 21.8285 9.51363 21.4855C9.17063 21.1425 8.99942 20.7307 9 20.25V17.625H10.75V20.25H21.25V17.625H23V20.25C23 20.7312 22.8285 21.1434 22.4855 21.4864C22.1425 21.8294 21.7307 22.0006 21.25 22H10.75Z"
                                                fill="white" />
                                        </svg>
                                        <input id="vatCertificate"
                                               type="file" 
                                               name="vatCertificate" 
                                               accept=".pdf,.png,.jpg,.jpeg"
                                               [class.is-invalid]="form.get('vatCertificate')?.invalid && (form.get('vatCertificate')?.dirty || form.get('vatCertificate')?.touched)"
                                               (change)="onFilePicked($event, 'vatCertificate')" />
                                        <span> {{form.get('vatCertificate')?.value?.name }}</span>
                                        <span *ngIf="!form.get('vatCertificate')?.value"> {{(disableViewIcon('vatCertificate') ? "Choose file" : "Reupload file") | translate}}</span>
                                    </div>
                                    <div *ngIf="form.get('vatCertificate')?.invalid && (form.get('vatCertificate')?.dirty || form.get('vatCertificate')?.touched)"
                                        class="text-danger" translate>
                                        Required
                                    </div>
                                </label>
                                <ng-container *ngIf="isAdmin">
                                    <button [disabled]="disableViewIcon('vatCertificate')" class="dataButton" (click)="viewDoc('vatCertificate')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 23 24 "><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>
                                    </button>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            {{'IBAN Certificate' | translate}} 
                            <div class="d-flex align-items-center gap-3">
                                <label for="IBANCertificate" class="doc-field" translate>
                                    <div class="form-control form-control-sm"
                                        [class.is-invalid]="form.get('IBANCertificate')?.invalid && (form.get('IBANCertificate')?.dirty || form.get('IBANCertificate')?.touched)">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="31" height="29" rx="9.5" fill="#544D7E"
                                                stroke="#544D7E" />
                                            <path
                                                d="M15.125 18.5V11.3687L12.85 13.6437L11.625 12.375L16 8L20.375 12.375L19.15 13.6437L16.875 11.3687V18.5H15.125ZM10.75 22C10.2688 22 9.85663 21.8285 9.51363 21.4855C9.17063 21.1425 8.99942 20.7307 9 20.25V17.625H10.75V20.25H21.25V17.625H23V20.25C23 20.7312 22.8285 21.1434 22.4855 21.4864C22.1425 21.8294 21.7307 22.0006 21.25 22H10.75Z"
                                                fill="white" />
                                        </svg>
                                        <input id="IBANCertificate"
                                               type="file" 
                                               name="IBANCertificate" 
                                               accept=".pdf,.png,.jpg,.jpeg"
                                               [class.is-invalid]="form.get('IBANCertificate')?.invalid && (form.get('IBANCertificate')?.dirty || form.get('IBANCertificate')?.touched)"
                                               (change)="onFilePicked($event, 'IBANCertificate')" />
                                        <span> {{form.get('IBANCertificate')?.value?.name }}</span>
                                        <span *ngIf="!form.get('IBANCertificate')?.value"> {{(disableViewIcon('IBANCertificate') ? "Choose file" : "Reupload file") | translate}}</span>
                                    </div>
                                    <div *ngIf="form.get('IBANCertificate')?.invalid && (form.get('IBANCertificate')?.dirty || form.get('IBANCertificate')?.touched)"
                                        class="text-danger" translate>
                                        Required
                                    </div>
                                </label>
                                <ng-container *ngIf="isAdmin">
                                    <button [disabled]="disableViewIcon('IBANCertificate')" class="dataButton" (click)="viewDoc('IBANCertificate')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 23 24 "><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>
                                    </button>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            {{'Logo' | translate }} 
                            <div class="d-flex align-items-center gap-3">
                                <label for="logo" class="doc-field" translate>
                                    <div class="form-control form-control-sm"
                                        [class.is-invalid]="form.get('logo')?.invalid && (form.get('logo')?.dirty || form.get('logo')?.touched)">
                                        <svg width="32" height="30" viewBox="0 0 32 30" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="31" height="29" rx="9.5" fill="#544D7E"
                                                stroke="#544D7E" />
                                            <path
                                                d="M15.125 18.5V11.3687L12.85 13.6437L11.625 12.375L16 8L20.375 12.375L19.15 13.6437L16.875 11.3687V18.5H15.125ZM10.75 22C10.2688 22 9.85663 21.8285 9.51363 21.4855C9.17063 21.1425 8.99942 20.7307 9 20.25V17.625H10.75V20.25H21.25V17.625H23V20.25C23 20.7312 22.8285 21.1434 22.4855 21.4864C22.1425 21.8294 21.7307 22.0006 21.25 22H10.75Z"
                                                fill="white" />
                                        </svg>
                                        <input id="logo"
                                               type="file" 
                                               name="logo" 
                                               accept=".pdf,.png,.jpg,.jpeg"
                                               [class.is-invalid]="form.get('logo')?.invalid && (form.get('logo')?.dirty || form.get('logo')?.touched)"
                                               (change)="onFilePicked($event, 'logo')" />
                                        <span> {{form.get('logo')?.value?.name }}</span>
                                        <span *ngIf="!form.get('logo')?.value"> {{(disableViewIcon('logo') ? "Choose file" : "Reupload file") | translate}}</span>
                                    </div>
                                    <div *ngIf="form.get('logo')?.invalid && (form.get('logo')?.dirty || form.get('logo')?.touched)"
                                        class="text-danger" translate>
                                        Required
                                    </div>
                                </label>
                                <ng-container *ngIf="isAdmin">
                                    <button [disabled]="disableViewIcon('logo')" class="dataButton" (click)="viewDoc('logo')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 23 24 "><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    <!-- </ng-container>
                </ng-container> -->
            </div>

        </div>
    </div>
</form>