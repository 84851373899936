<form *ngIf="form" [formGroup]="form" (submit)="verify.emit()">
    <div class="d-flex justify-content-center">
        <h3 class="text-primary" translate>OTP Verification</h3>
    </div>
    <div class="card p-2">
        <div class="row">
            <div class="container">
                <div class="form-group col-12 mt-2">
                    <label
                        class="w-100 text-center"
                        for="otp" translate>
                        Enter OTP
                    </label>
                    <div class="input-group">
                        <span class="input-group-text fa fa-lock"></span>
                        <input
                            type="password"
                            class="form-control"
                            formControlName="otp"
                            [placeholder]="'OTP' | translate">
                    </div>
                </div>
                <div
                    *ngIf="error && error.length"
                    class="text-danger my-2">
                    {{ error }}
                </div>
                <div class="d-flex justify-content-center mt-2">
                    <button
                        type="submit"
                        class="btn btn-primary"
                        [disabled]="disableVerifyButton || isLoading">
                        <span *ngIf="!isLoading" class="fa fa-sign-in-alt"></span>
                        <span *ngIf="!isLoading" class="pl-2" translate>Verify & Login</span>
                        <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm"></span>
                        <span *ngIf="isLoading" class="pl-2" translate>Verifying</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
