/* eslint-disable prettier/prettier */
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { WakiDatePipe } from './pipes/waki-date.pipe';
import { SharedComponentModule } from './components/shared-component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WakiCurrencyPipe } from './pipes/waki-currency.pipe';
import { TransactionTypePipe } from './pipes/transaction-type.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { YesNoBooleanPipe } from './pipes/yes-no-boolean.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CouponStatusPipe } from './pipes/coupon-status.pipe';
import { ShortenPipe } from './pipes/shorten.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';
import { PurposeOfRefundPipe } from './pipes/purpose-of-refund.pipe';
import { PurposeOfTransferPipe } from './pipes/purpose-of-transfer.pipe';
import { WakiTimePipe } from './pipes/waki-time.pipe';
import { PaymentPipe } from './pipes/payment.pipe';
import { StatisticsPipe } from './pipes/stats.pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageTxtPipe } from './pipes/language.pipe';
import { SubscriptionPeriodPipe } from './pipes/subscription-period.pipe';
import { CalledByUserPipe } from './pipes/called-by-user.pipe';
import { MaterialModule } from './material.module';
import { TokenMethodPipe } from './pipes/token-method.pipe';
import { PaymentTokenPipe } from './pipes/payment-token.pipe';
import { PaymentMethodPipe } from './pipes/payment-method.pipe';
import { PaymentMethodTextPipe } from './pipes/payment-method-text.pipe';
import { OrderTypePipe } from './pipes/order-type.pipe';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

const PIPES = [
    WakiDatePipe,
    WakiTimePipe,
    WakiCurrencyPipe,
    YesNoBooleanPipe,
    TransactionTypePipe,
    CouponStatusPipe,
    ShortenPipe,
    GenderPipe,
    PurposeOfRefundPipe,
    PurposeOfTransferPipe,
    PaymentPipe,
    StatisticsPipe,
    LanguageTxtPipe,
    SubscriptionPeriodPipe,
    CalledByUserPipe,
    TokenMethodPipe,
    PaymentTokenPipe,
    PaymentMethodPipe,
    PaymentMethodTextPipe,
    OrderTypePipe,
];

@NgModule({
    imports: [
        CommonModule,
        SharedComponentModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaFormsModule,
        RecaptchaModule,
        NgSelectModule,
        FontAwesomeModule,
        NgbModule,
        BreadcrumbModule,
        TranslateModule.forChild(),
        MaterialModule
    ],
    declarations: [...PIPES],
    exports: [
        ...PIPES, 
        FormsModule,
        ReactiveFormsModule, 
        RecaptchaFormsModule,
        RecaptchaModule,
        NgSelectModule, 
        FontAwesomeModule, 
        BreadcrumbModule, 
        TranslateModule, 
        SharedComponentModule,
        MaterialModule
    ],
    providers: [
        ...PIPES,
        DatePipe,
        CurrencyPipe,
        DecimalPipe,
        PaymentPipe,
        LanguageTxtPipe,
        BreadcrumbService,
        TranslateService,
        TitleCasePipe
    ],
})
export class SharedModule {}
