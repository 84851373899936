/* eslint-disable prettier/prettier */
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'purposeOfTransfer', pure: false })
export class PurposeOfTransferPipe implements PipeTransform {
    transform(value = 'NONE'): string {
        const types: any = {
            ['FAMILY_AND_FRIENDS']: 'Family and Friends',
            ['ITEM_PURCHASES']: 'Item purchases',
            ['EDUCATIONAL']: 'Educational',
            ['LOAN_FROM_CANTEEN']: 'Loan From Canteen',
        };

        return types[value] ?? '';
    }
}
