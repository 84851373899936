<div *ngIf="form" class="imageContainer">
    <form [formGroup]="form" class="content">
        <div class="header">
            <h5 translate>
                <ng-container *ngIf="currentStep === 1">
                    Forgot Password
                </ng-container>
                <ng-container *ngIf="currentStep === 2">
                    OTP Verification
                </ng-container>
                <ng-container *ngIf="currentStep === 3">
                    Reset Password
                </ng-container>
            </h5>
            <p *ngIf="currentStep !== 3" class="text-center" translate>
                <ng-container *ngIf="currentStep === 1">
                    Enter your email and we’ll send you a verification code
                </ng-container>
                <ng-container *ngIf="currentStep === 2">
                    Enter the OTP Verification sent to your email
                </ng-container>
            </p>
        </div>
        <div *ngIf="currentStep !== 2" class="inputs">
            <ng-container *ngIf="currentStep === 1">
                <div class="form-group w-100">
                    <label
                        class="inputLabel"
                        for="email" 
                        translate>
                        Email
                    </label>
                    <input
                        type="text"
                        class="form-control {{isArabic && 'rtl'}}"
                        formControlName="email"
                        [placeholder]="'youremail@example.com' | translate">
                    <div class="validationFeedback {{displayValidation('email') && 'displayValidation'}}">
                        <small class="text-danger">
                            {{emailErrors | translate}}
                        </small>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentStep === 3">
                <div class="form-group w-100">
                    <label
                        class="inputLabel"
                        for="password" translate>
                        New Password
                    </label>
                    <div class="inputGroup {{passwordValidationStatus()}}">
                        <input
                            [type]="passwordEye ? 'text' :'password'"
                            class="form-control addon"
                            formControlName="password">
                        <label class="inputGroupAddon" (click)="showPassword()">
                            <svg *ngIf="!passwordEye" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3c1.641 0 3-1.358 3-3c0-1.641-1.359-3-3-3"/><path fill="currentColor" d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316l-.105-.316C21.927 11.617 19.633 5 12 5m0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5c-.504 1.158-2.578 5-7.926 5"/></svg>
                            <svg *ngIf="passwordEye" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757c-.273.021-.55.038-.841.038c-5.351 0-7.424-3.846-7.926-5a8.642 8.642 0 0 1 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379a.994.994 0 0 0 0 .633C2.073 12.383 4.367 19 12 19m0-14c-1.837 0-3.346.396-4.604.981L3.707 2.293L2.293 3.707l18 18l1.414-1.414l-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657a.994.994 0 0 0 0-.633C21.927 11.617 19.633 5 12 5m4.972 10.558l-2.28-2.28c.19-.39.308-.819.308-1.278c0-1.641-1.359-3-3-3c-.459 0-.888.118-1.277.309L8.915 7.501A9.26 9.26 0 0 1 12 7c5.351 0 7.424 3.846 7.926 5c-.302.692-1.166 2.342-2.954 3.558"/></svg>
                        </label>
                    </div>
                    <div class="validationFeedback {{displayValidation('password') && 'displayValidation'}}">
                        <small class="text-danger">
                            {{passwordErrors | translate}}
                        </small>
                    </div>
                </div>
                <div class="form-group w-100">
                    <label
                        class="inputLabel "
                        for="confirmPassword" translate>
                        Confirm New Password
                    </label>
                    <div class="inputGroup {{passwordValidationStatus()}}">
                        <input
                            [type]="passwordEye ? 'text' :'password'"
                            class="form-control addon"
                            formControlName="confirmPassword">
                        <label class="inputGroupAddon" (click)="showPassword()">
                            <svg *ngIf="!passwordEye" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3c1.641 0 3-1.358 3-3c0-1.641-1.359-3-3-3"/><path fill="currentColor" d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316l-.105-.316C21.927 11.617 19.633 5 12 5m0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5c-.504 1.158-2.578 5-7.926 5"/></svg>
                            <svg *ngIf="passwordEye" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757c-.273.021-.55.038-.841.038c-5.351 0-7.424-3.846-7.926-5a8.642 8.642 0 0 1 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379a.994.994 0 0 0 0 .633C2.073 12.383 4.367 19 12 19m0-14c-1.837 0-3.346.396-4.604.981L3.707 2.293L2.293 3.707l18 18l1.414-1.414l-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657a.994.994 0 0 0 0-.633C21.927 11.617 19.633 5 12 5m4.972 10.558l-2.28-2.28c.19-.39.308-.819.308-1.278c0-1.641-1.359-3-3-3c-.459 0-.888.118-1.277.309L8.915 7.501A9.26 9.26 0 0 1 12 7c5.351 0 7.424 3.846 7.926 5c-.302.692-1.166 2.342-2.954 3.558"/></svg>
                        </label>
                    </div>
                    <div class="validationFeedback {{displayValidation('confirmPassword') && 'displayValidation'}}">
                        <small class="text-danger">
                            {{confirmPasswordErrors | translate}}
                        </small>
                    </div>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="currentStep === 2">
            <div class="otp">
                <input
                    #otp1
                    type="text"
                    class="form-control"
                    [maxLength]="1"
                    (focus)="forceSelectAll(1)"
                    (click)="forceSelectAll(1)"
                    (paste)="pasteHandler($event)"
                    (keypress)="validateNumericInput($event, 1)"
                    (keydown)="backspaceHandler($event,1)"
                    inputmode="numeric"
                    formControlName="otp1">
                <input
                    #otp2
                    type="text"
                    class="form-control"
                    [maxLength]="1"
                    (focus)="forceSelectAll(2)"
                    (click)="forceSelectAll(2)"
                    (paste)="pasteHandler($event)"
                    (keypress)="validateNumericInput($event, 2)"
                    (keydown)="backspaceHandler($event,2)"
                    inputmode="numeric"
                    formControlName="otp2">
                <input
                    #otp3
                    type="text"
                    class="form-control"
                    [maxLength]="1"
                    (focus)="forceSelectAll(3)"
                    (click)="forceSelectAll(3)"
                    (paste)="pasteHandler($event)"
                    (keypress)="validateNumericInput($event, 3)"
                    (keydown)="backspaceHandler($event,3)"
                    inputmode="numeric"
                    formControlName="otp3">
                <input
                    #otp4
                    type="text"
                    class="form-control"
                    [maxLength]="1"
                    (focus)="forceSelectAll(4)"
                    (click)="forceSelectAll(4)"
                    (paste)="pasteHandler($event)"
                    (keypress)="validateNumericInput($event, 4)"
                    (keydown)="backspaceHandler($event,4)"
                    inputmode="numeric"
                    formControlName="otp4">
            </div>
        </ng-container>
        <app-primary-button
            *ngIf="currentStep === 1"
            text="Send"
            type="submit"
            addClass="primaryBtnLg mx-2 mt-4"
            (click)="stepOneClick.emit()"
            [isLoading]="isLoading"
            [disabled]="!form.get('email')?.valid || isLoading">
        </app-primary-button>
        <app-primary-button
            *ngIf="currentStep === 2"
            text="Verify"
            type="submit"
            addClass="primaryBtnLg mx-2 mt-4"
            (click)="stepTwoClick.emit()"
            [isLoading]="isLoading"
            [disabled]="otpStatus || isLoading">
        </app-primary-button>
        <app-primary-button
            *ngIf="currentStep === 3"
            text="Reset"
            type="submit"
            addClass="primaryBtnLg mx-2 mt-4"
            (click)="stepThreeClick.emit()"
            [isLoading]="isLoading"
            [disabled]="!form.get('password')?.valid || !form.get('confirmPassword')?.valid || isLoading">
        </app-primary-button>
        <div
        class="failSignin {{error && error.length && error !== '.' && 'showFailSigninMsg'}}"
        >
        {{ error | translate }}
        </div>
    </form>
    <img src="../../../../../assets/images/signin/{{isArabic ? 'ar-desktop.png' : 'en-desktop.png'}}" alt="" class="placeholderDesktopImage">
</div>
