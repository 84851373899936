/* eslint-disable prettier/prettier */
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-input-required-error-message',
    templateUrl: './input-required-error-message.component.html',
    styleUrls: ['./input-required-error-message.component.scss']
})
export class InputRequiredErrorMessageComponent implements OnInit {
    @Input() message = '';
    @Input() control: AbstractControl | undefined;

    ngOnInit(): void {
        if (this.control && this.control.invalid) {
            Object.entries(this.control.errors || {}).forEach(([key, value]) => {
                if (key === 'pattern') {
                    this.message = value.message ?? 'Must be numberic' ?? 'Invalid pattern';
                    return;
                }
                if (key === 'required') {
                    this.message = value.message ?? 'Required';
                    return;
                }
                this.message = value.message ?? '';
            })
        }
    }
}
