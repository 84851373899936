<div class="login-container m-auto">
    <div class="login-form-container">
        <app-backup-otp-login-form
            [form]="form"
            [disableVerifyButton]="!FormIsValid"
            [isLoading]="isLoading"
            [error]="error"
            (verify)="onVerify()"></app-backup-otp-login-form>
    </div>
</div>
