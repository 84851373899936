/* eslint-disable prettier/prettier */
import { FormGroup, Validators  } from '@angular/forms';
import * as _ from 'lodash';

const isNullOrEmpty = (e: string) => (e === null || e === undefined || _.trim(e) === '');

export class FormValidators {

    public static validatorsVAT = [Validators.minLength(15), Validators.pattern(/^\d+$/)];

    public static mustNumberic = Validators.pattern(/^-?\d+$/);
    public static phoneValidator = Validators.pattern(/^\966\d{1,9}$/);
    public static emailValidator = Validators.pattern(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

    public static vatConditionalValidator = () => {
        return (formGroup: FormGroup) => {
            if (!formGroup || !formGroup.controls) {
                return;
            }

            const vat = formGroup.controls['vat'];

            if (vat.value === null || vat.value === undefined || _.trim(vat.value) === '') {
                return;
            }
            return FormValidators.validatorsVAT;
        }
    }

    public static vatPercentageValidator = () => {
        return (formGroup: FormGroup) => {
            const vat = formGroup.controls['vat'];
            const vatPercentage = formGroup.controls['vatPercentage'];

            if (vatPercentage.errors && !vatPercentage.errors.invalidVatPercentage) {
                return;
            }

            // only validate the vatPercentage if there's value in it and there's no value in VAT
            if (vat.value && isNullOrEmpty(vatPercentage.value)) {
                vatPercentage.setErrors({ invalidVatPercentage: true });
            }
            else {
                vatPercentage.setErrors(null);
            }
        }
    }
}
