/* eslint-disable prettier/prettier */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@portal/auth/services/auth.service';
import { ComponentBase } from '@portal/shared/components/component-base';
import { IApiResult } from '@portal/shared/models/api-result.model';
import { NotifyService } from '@portal/shared/services/notify.service';
import { mustMatch } from '@portal/shared/validators/password-validators';
import { of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-forgot-password-page',
    templateUrl: './forgot-password-page.component.html',
    styleUrls: ['../auth.component.scss']
})
export class ForgotPasswordPageComponent extends ComponentBase implements OnInit {
    public currentStep: number = 1;
    public isLoading = false;
    public form: FormGroup = this.fb.group(
        {
            email: [null, [Validators.required, Validators.email]],
            validationCode: [''],
            password: [null, [Validators.required, , Validators.minLength(8)]],
            confirmPassword: [null, [Validators.required, , Validators.minLength(8)]],
            otp1: ['', [Validators.required]],
            otp2: ['', [Validators.required]],
            otp3: ['', [Validators.required]],
            otp4: ['', [Validators.required]]
        },
        {
            validators: mustMatch('password', 'confirmPassword')
        }
    );
    public notValid: boolean = false;
    public error: string = ''

    private autoVerify: boolean = false

    private get email() {
        return this.form.get('email') as FormControl;
    }

    public get validationCode() {
        return this.form.get('validationCode') as FormControl;
    }

    get password() {
        return this.form.get('password') as FormControl;
    }

    public get isArabic(): boolean{
        return localStorage.getItem('lang') === "ar"
    }

    private get isOTPValid(): boolean {
        return (this.form.get('otp1')?.valid && this.form.get('otp2')?.valid && this.form.get('otp3')?.valid && this.form.get('otp4')?.valid) || false
    }

    constructor(
        private authService: AuthService,
        private router: Router,
        private fb: FormBuilder,
        private notify: NotifyService
    ) {
        super();
    }
    
    ngOnInit(): void {
        for(let i = 0; i < 5; i++){
            this.form.get(`otp${i + 1}`)?.valueChanges.subscribe(() => {
            this.autoVerify = true
            })
        }

        this.form.valueChanges.subscribe(() => {
            if(this.isOTPValid && this.autoVerify) {
                this.autoVerify = false
                this.processStepTwo()
            }
        })
    }

    processStepOne = () => {
        if (this.currentStep !== 1 || !this.email.valid) {
            return;
        }
        this.error = ''
        this.isLoading = true;

        this.authService
            .forgotPasswordStepOne(this.email.value)
            .pipe(
                tap((result: IApiResult) => {
                    this.nextStep();
                    this.isLoading = false;
                }),
                takeUntil(this.destroyed$),
                catchError((err) => {
                    this.isLoading = false;
                    this.error = 'Invalid email'

                    return of();
                })
            )
            .subscribe();
    };

    processStepTwo = () => {
        if (this.currentStep !== 2 || !this.validationCode.valid) {
            return;
        }
        this.isLoading = true;
        this.notValid = false;
        this.error = ''

        this.setOtp()

        this.authService
            .forgotPasswordStepTwo(this.email.value, this.validationCode.value)
            .pipe(
                tap((result: IApiResult) => {
                    if (result.success) {
                        this.nextStep();
                    } else {
                        this.notValid = true;
                    }
                    this.isLoading = false;
                }),
                takeUntil(this.destroyed$),
                catchError((err) => {
                    this.isLoading = false;
                    this.error = 'Password recovery faild'
                    return of();
                })
            )
            .subscribe();
    };

    processStepThree = () => {
        if (this.currentStep !== 3 || !this.password.valid) {
            return;
        }
        this.isLoading = true;
        this.error = ''

        this.authService
            .forgotPasswordStepThree(this.email.value, this.password.value)
            .pipe(
                tap((result: IApiResult) => {
                    if (result.success) {
                        this.notify.success('Forgot Password', 'Password change success, please proceed to login');
                        this.goToLoginPage();
                    } else {
                        this.notValid = true;
                        this.isLoading = false;
                    }
                }),
                takeUntil(this.destroyed$),
                catchError((err) => {
                    this.notify.error('Forgot Password', 'Password recovery faild, please try again later', err);
                    this.isLoading = false;
                    this.error = 'Invalid OTP'
                    return of();
                })
            )
            .subscribe();
    };

    private nextStep = () => {
        this.currentStep++;
    };

    private previousStep = () => {
        this.currentStep--;
    };

    private goToLoginPage = () => {
        this.router.navigateByUrl('login');
    };

    private goToFirstStep = () => {
        this.currentStep = 1;
    };

    private setOtp(): void{
        let otp = ''
        for(let i = 0; i < 5; i++){
            otp += this.form.get(`otp${i + 1}`)?.value || ''
        }

        this.form.get('validationCode')?.patchValue(otp)

        this.form.get('validationCode')?.updateValueAndValidity()
    }
}
