/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { hasRequired } from '@portal/shared/functions/has-required';
import { isTimeBeforeTime } from '@portal/shared/functions/dates/date-comparison.functions';
import { JwtTokenParser } from '@portal/shared/services/helpers/jwt-token-parser.utility';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.isAuthorised(next);
    }

    isAuthorised(next: ActivatedRouteSnapshot) {
        if (!this.authService.isLoggedIn() || this.isTokenExpired(this.authService.getToken() ?? '')) {
            localStorage.setItem('waki_redirect', window.localStorage.href);

            this.router.navigateByUrl('/login');
            return false;
        }

        const required = (next.data.claims as string[]) || [];
        const having = this.authService.setUserRoles() || [];

        return hasRequired(required, having);
    }
    
    private isTokenExpired(refreshToken: string): boolean {
        return refreshToken ? isTimeBeforeTime(JwtTokenParser.getExp(refreshToken), new Date()) : true;
    }
}
