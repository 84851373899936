import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faColumns, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { AppComponent } from '@portal/app.component';

@Component({
    selector: 'app-customize-column-button',
    templateUrl: './customize-column-button.component.html',
    styleUrls: ['./customize-column-button.component.scss'],
})
export class CustomizeColumnButtonComponent {
    @Input() disabled: boolean = false;
    @Input() text = 'Customize columns';
    @Input() icon: IconDefinition = faColumns;
    @Input() showIcon: Boolean = true;
    @Input() customClass = '';
    @Input() visibleColumns: any[] = [];
    @Input() totalColumns: any[] = [];
    @Output() cancel = new EventEmitter();

    constructor() {}
}
