import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class RoutingNavigateService {
    constructor(
        private router: Router,
    ) { }

    public unauthorizedPage(): void {
        this.router.navigate([this.handleRoute('unauthorized-page')]);
    }

    public backupLogin(): void {
        this.router.navigate([this.handleRoute('backup-login')]);
    }

    public login(): void {
        this.router.navigate([this.handleRoute('login')]);
    }

    public loginWithOTP(): void {
        this.router.navigate([this.handleRoute('login/otp')]);
    }

    public forgotPassword(): void {
        this.router.navigate([this.handleRoute('auth/forgot-password')]);
    }

    public home(): void {
        this.router.navigate([this.handleRoute('home')]);
    }

    public canteens(): void {
        this.router.navigate([this.handleRoute('canteens')]);
    }

    public schools(): void {
        this.router.navigate([this.handleRoute('schools')]);
    }

    public merchants(): void {
        this.router.navigate([this.handleRoute('merchants')]);
    }

    public wakiUsers(): void {
        this.router.navigate([this.handleRoute('waki-users')]);
    }

    public notifications(): void {
        this.router.navigate([this.handleRoute('notifications')]);
    }

    public businessStore(): void {
        this.router.navigate([this.handleRoute('business-store')]);
    }

    public advertisements(): void {
        this.router.navigate([this.handleRoute('advertisements')]);
    }

    public users(): void {
        this.router.navigate([this.handleRoute('users')]);
    }

    public navigateByUrl(url: string, state?: object): void {
        this.router.navigateByUrl(url, { state });
    }

    public navigateToPage(url: string, state?: object): void {
        this.router.navigate([this.handleRoute(url)], { state });
    }

    public isCallSystemScreen(): boolean {
        const callSystemRoutes = ['call-system'];
        return window.location.pathname.split('/').findIndex((f) => callSystemRoutes.includes(f.toLocaleLowerCase())) > 0;
    }

    public handleRoute(route: string): string {
        return decodeURIComponent(route);
    }
}
