/* eslint-disable prettier/prettier */
export class UserRoles {
    public static readonly WAKI_ADMIN = 'WAKI_ADMIN';
    public static readonly WAKI_SUPER_ADMIN = 'WAKI_SUPER_ADMIN';
    public static readonly PARENT = 'PARENT';

    public static readonly SUPER_SCHOOL_USER = 'SUPER_SCHOOL_USER';
    public static readonly SCHOOL_USER = 'SCHOOL_USER';
    public static readonly ATTENDANCE_APP_USER = 'ATTENDANCE_APP_USER';
    public static readonly GATE_GUARD_USER = 'GATE_GUARD_USER';
    public static readonly CALL_SYSTEM_WEB_USER = 'CALL_SYSTEM_WEB_USER';

    public static readonly SUPER_CANTEEN_USER = 'SUPER_CANTEEN_USER';
    public static readonly CANTEEN_USER = 'CANTEEN_USER';
    public static readonly CANTEEN_APP_USER = 'CANTEEN_APP_USER';

    public static readonly SUPER_MERCHANT_USER = 'SUPER_MERCHANT_USER';
    public static readonly MERCHANT_USER = 'MERCHANT_USER';
    public static readonly MERCHANT_APP_USER = 'MERCHANT_APP_USER';
}

export const userRolesOptions = () => {
    return [
        {
            label: 'USER.ROLES.WAKI_ADMIN',
            value: UserRoles.WAKI_ADMIN
        },
        {
            label: 'USER.ROLES.WAKI_SUPER_ADMIN',
            value: UserRoles.WAKI_SUPER_ADMIN
        },
        {
            label: 'USER.ROLES.SUPER_SCHOOL_USER',
            value: UserRoles.SUPER_SCHOOL_USER
        },
        {
            label: 'USER.ROLES.SCHOOL_USER',
            value: UserRoles.SCHOOL_USER
        },
        {
            label: 'USER.ROLES.SUPER_CANTEEN_USER',
            value: UserRoles.SUPER_CANTEEN_USER
        },
        {
            label: 'USER.ROLES.CANTEEN_USER',
            value: UserRoles.CANTEEN_USER
        },
        {
            label: 'USER.ROLES.SUPER_MERCHANT_USER',
            value: UserRoles.SUPER_MERCHANT_USER
        },
        {
            label: 'USER.ROLES.MERCHANT_USER',
            value: UserRoles.MERCHANT_USER
        },
        {
            label: 'USER.ROLES.PARENT',
            value: UserRoles.PARENT
        },
        {
            label: 'USER.ROLES.ATTENDANCE_APP_USER',
            value: UserRoles.ATTENDANCE_APP_USER
        },
        {
            label: 'USER.ROLES.CANTEEN_APP_USER',
            value: UserRoles.CANTEEN_APP_USER
        },
        {
            label: 'USER.ROLES.MERCHANT_APP_USER',
            value: UserRoles.MERCHANT_APP_USER
        },
        {
            label: 'USER.ROLES.GATE_GUARD_USER',
            value: UserRoles.GATE_GUARD_USER
        },
        {
            label: 'USER.ROLES.CALL_SYSTEM_WEB_USER',
            value: UserRoles.CALL_SYSTEM_WEB_USER
        }
    ];
};

export const getRoleLabelByValue = (role: string) => {
    const userRole = userRolesOptions().find(u => u.value == role);
    return userRole ? userRole.label : role;
}
