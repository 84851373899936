import { Buffer } from 'buffer';
import { TokenInvalidError } from "./token-invalid.error";

export class JwtTokenParser {
    private static expirationTimeKey = 'exp';

    public static getExp(token: string): Date {
        const tokenObj = this.decodeToken(token);
        const expirationTimeInSecond = Number(tokenObj[this.expirationTimeKey]);
        if (isNaN(expirationTimeInSecond)) {
            throw Error('Invalid input');
        }
        return new Date(expirationTimeInSecond * 1000);
    }

    public static decodeToken(token: string): any {
        if (!token) {
            throw new TokenInvalidError();
        }
        const base64 = token.split('.')[1];
        return JSON.parse(Buffer.from(base64, 'base64').toString());
    }
}