/* eslint-disable prettier/prettier */
import { EventEmitter, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from '@portal/app.component';
import { AuthService } from '@portal/auth/services/auth.service';
import { getPaginationTxt } from '@portal/shared/functions/get-pagination-text';
import * as _ from 'lodash';
import * as moment from 'moment';
import { additionalPageSizeOption, defaultPageSizeOptions, defaultPaginationPageSize, defaultPaginationRoute, getPageNumberList } from './shared/pagination.model';
@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {
    @Input() currentPage = 1;
    @Input() maxPage = 1;
    @Input() totalRecords = 0;
    @Input() infoText = '';
    @Input() pageOptionsShown = 3;
    @Input() showPageSizeOption = true;
    @Output() goToPage = new EventEmitter<number>();
    @Output() changePageSize = new EventEmitter<number>();

    public app = AppComponent;
    public isAdmin = true;
    public currentPageSize = defaultPaginationPageSize;
    public sizeOptions = defaultPageSizeOptions;

    public isSetSpecificPage = false;
    public form = new FormGroup({
        specificPage: new FormControl(null)
    })

    private currentUrl = defaultPaginationRoute;
    private pageSizeConfigs: any = JSON.parse(localStorage.getItem('pageSizeConfigs') || '{}');
    private today = moment();
    private currentLogInDate = moment(localStorage.getItem('CurrentLogInDate'), 'MM-DD-YYYY');
    private pagesUsed10kExport = (localStorage.getItem('pagesUsed10kExport') || '').split(',');

    constructor(private router: Router, private authServ: AuthService) {
        this.isAdmin = this.authServ.isAdminOrSuperAdmin();
        this.currentUrl = this.router.url.split('?')[0];
        this.currentPageSize = (this.pageSizeConfigs[this.currentUrl] || defaultPaginationPageSize);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.totalRecords && changes.totalRecords.currentValue) {
            if (this.isAdmin && changes.totalRecords.currentValue >= additionalPageSizeOption) {
                this.sizeOptions = [...defaultPageSizeOptions, additionalPageSizeOption]
            } else {
                this.sizeOptions = defaultPageSizeOptions;
            }
        }
    }

    public get paginationTxt(): string {
        if (!this.infoText.length) {
            this.infoText = `Page ${this.currentPage} of ${this.maxPage} from ${this.totalRecords} records`;
        }
        return getPaginationTxt(this.infoText);
    }

    public get pagesForPagination(): number[] {
        return getPageNumberList(this.currentPage, this.maxPage)
    }

    // public get showAdditionalPageSizeOption(): boolean {
    //     return !(this.pagesUsed10kExport.includes(this.currentUrl) && this.today.isSame(this.currentLogInDate, 'day'))
    // }

    public setSizePage = (size: number): void => {
        if (!this.currentLogInDate || this.today.isAfter(this.currentLogInDate)) {
            this.currentLogInDate = this.today;
            localStorage.setItem('CurrentLogInDate', this.currentLogInDate.format('MM-DD-YYYY'));
        }
        this.storageConfig(size);
        this.changePageSize.emit(size);
    }

    public goToSpecificPage = (): void => {
        const formValue = this.form.getRawValue();
        const specificPage = +formValue.specificPage || 1;
        this.goToPage.emit(specificPage > this.maxPage ? this.maxPage : specificPage);
    }

    private storageConfig(size = 100): void {
        this.currentPageSize = size;
        this.pageSizeConfigs = { ...this.pageSizeConfigs, [this.currentUrl]: size };
        localStorage.setItem('pageSizeConfigs', JSON.stringify(this.pageSizeConfigs));

        if (size === 10000 && this.today.isSame(moment(this.currentLogInDate, 'MM-DD-YYYY'), 'day')) {
            this.pagesUsed10kExport = _.union([...this.pagesUsed10kExport, ...[this.router.url.split('?')[0]]]);
            localStorage.setItem('pagesUsed10kExport', this.pagesUsed10kExport.filter((p) => !this.isNullOrEmpty(p)).join(','))
        }
    }

    private isNullOrEmpty = (value: any): boolean => {
        return value === null || value === undefined || _.trim(value).length === 0;
    }
}
