import { Pipe, PipeTransform } from "@angular/core";
import { ITransactionFoodOrderId } from "@portal/shared/services/transactions/transaction.model";
import { TransactionTypeEnum } from "../models/transaction.model";
import { PaymentMethod } from "../constants/payment.constants";

@Pipe({ name: 'paymentMethod', pure: false })
export class PaymentMethodPipe implements PipeTransform {
    transform(source: ITransactionFoodOrderId | undefined, trType: string = ''): string {
        if (!source) {
            return '';
        }
        
        if (source.paymentMethod === PaymentMethod.CreditCard.toUpperCase()) {
            return PaymentMethod.CreditCard;
        }
        if (source.paymentMethod === PaymentMethod.Mada.toUpperCase()) {
            return PaymentMethod.Mada.toUpperCase();
        }
        if (trType === TransactionTypeEnum.WalkInOrder || trType === TransactionTypeEnum.MerchantWalkInOrder) {
            return source.cardOrWristbandScanned?.cardSerialNo || '';
        }

        return source.paymentMethod ?? 'WAKI_WALLET';
        // if (source.paidByFace) {
        //     return PaymentMethod.FaceID;
        // }
        // if (source.paidByQRCode) {
        //     return PaymentMethod.QRCode;
        // }

        // return (source.student?.user?.cardSerialNo || source.adult?.cardSerialNo || '');
    }
}