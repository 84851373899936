
export const defaultPaginationRoute = '/home';
export const defaultPaginationPageSize = 100;
export const defaultPageSizeOptions = [100, 200, 500];
export const additionalPageSizeOption = 10000;


export function getPageNumberList(currentPage: number, maxPage: number): number[] {
    if (maxPage === 1 || maxPage === 0) {
        return [1];
    }

    if (maxPage === 2) {
        return [1, 2];
    }

    if (currentPage === 1) {
        return [1, 2, 3];
    }

    if (currentPage + 1 <= maxPage) {
        return [currentPage - 1, currentPage, currentPage + 1];
    }

    return [maxPage - 2, maxPage - 1, maxPage];
}