import { Injectable } from '@angular/core';
import { RouteAuthGuardService } from '@portal/shared/guards/route-auth-guard.service';
import { UserClientContext } from '@portal/shared/models/user-client-context.model';
import { hasAdminOrSuperAdminAccess } from '@portal/shared/permissions/permission';

@Injectable({
  providedIn: 'root',
})
export class AdvertisementGuardService extends RouteAuthGuardService {
  static canAccess = (context: UserClientContext): boolean => hasAdminOrSuperAdminAccess(context.roles);

  protected canAccess = (context: UserClientContext): boolean => AdvertisementGuardService.canAccess(context);
}
