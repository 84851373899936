/* eslint-disable prettier/prettier */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faPlus, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { AppComponent } from '@portal/app.component';

@Component({
    selector: 'app-add-button',
    templateUrl: './add-button.component.html',
    styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent {
    @Input() disabled: boolean = false;
    @Input() text: string = 'Add';
    @Input() icon: IconDefinition = faPlus;
    @Input() showIcon: boolean = true;
    @Input() customClass: string = '';
    @Input() size: string = AppComponent.isDesktop ? '' : 'btn-sm';
    @Output() cancel = new EventEmitter();

    constructor() {}
}
