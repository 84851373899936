import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentBase } from '@portal/shared/components/component-base';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['../../auth.component.scss']
})
export class LoginFormComponent extends ComponentBase implements OnInit {
    @Input() form!: FormGroup;
    @Input() disableLoginButton: boolean = true;
    @Input() isLoading: boolean = false;
    @Input() error: string = '.';
    @Output() login = new EventEmitter();
    @Output() goToForgotPassword = new EventEmitter();

    public passwordEye: boolean = false

    constructor() {
        super();
    }

    public get emailErrors():string {
        if (this.form.get('email')?.hasError('email')){
            return 'Email must be valid'
        }
        else if (this.form.get('email')?.hasError('required')){
            return 'Required'
        }

        return ''
    }

    public get passwordErrors():string {
        if (this.form.get('password')?.hasError('minlength')){
            return 'Password must be at least 8 characters'
        }
        else if (this.form.get('password')?.hasError('required')){
            return 'Required'
        }

        return ''
    }

    public showPassword(): void{
        this.passwordEye = !this.passwordEye
    }

    public passwordValidationStatus(): string {
        let passwordForm = this.form.get('password')
        if (passwordForm?.invalid && passwordForm.touched) {
            return 'border-danger'
        }
        return ''
    }

    public displayValidation(formName: any): any {
        return (this.form?.get(formName)?.invalid && this.form?.get(formName)?.errors && this.form?.get(formName)?.touched)
    }

    public get isArabic(): boolean{
        return localStorage.getItem('lang') === "ar"
    }
}
