/* eslint-disable prettier/prettier */
import { v4 as uuid } from 'uuid';
import { IBaseModel, IBaseQuery } from "@portal/shared/models/base.model";
import { ISelectOption } from "@portal/shared/models/select-option.model";
import { IUser } from "@portal/shared/models/user.model";
import { ConsumableOrderForWhom } from "./order.model";

export interface ICartItem {
    _id?: string
    consumable: any;
    pricePerItem: number;
    quantity: number;
    canteenSubscriptionForSchools?: any;
    forSchools?: any;
    forCanteens?: any;
}

export type CartItem = ICartItem & {
    selectedSchools?: ISelectOption[];
    selectedCanteens?: ISelectOption[];
    belongsToCurrentUser?: boolean;
}

export interface ICart extends IBaseModel {
    user: IUser | string;
    items: CartItem[];
    subTotal: number;
    vatAmount: number;
    totalAmount: number;
    discountAmount?: number;
    createdBy: IUser | string;
    forWhom?: ConsumableOrderForWhom;
    canteen?: any;
    school?: any;
}

export type CartDetails = ICart & {
    totalAfterDiscount?: number;
}

export function emptyCart(): CartDetails {
    return {
        _id: '',
        items: [] as ICartItem[],
        totalAmount: 0,
        subTotal: 0,
        vatAmount: 0,
        createdBy: uuid(),
        user: uuid()
    }
}


export type ICartFilter = IBaseQuery & {
    isCountOnly?: boolean,
};