<div class="imageContainer">
    <form *ngIf="form" [formGroup]="form" class="content" (submit)="verify.emit()">
        <div class="header">
            <h5 translate>OTP Verification</h5>
            <p class="text-center" translate>Enter the OTP Verification sent to your email</p>
        </div>
            <div class="otp">
                <input
                    #otp1
                    type="text"
                    class="form-control"
                    [maxLength]="1"
                    (focus)="forceSelectAll(1)"
                    (click)="forceSelectAll(1)"
                    (paste)="pasteHandler($event)"
                    (keypress)="validateNumericInput($event, 1)"
                    (keydown)="backspaceHandler($event,1)"
                    inputmode="numeric"
                    formControlName="otp1">
                <input
                    #otp2
                    type="text"
                    class="form-control"
                    [maxLength]="1"
                    (focus)="forceSelectAll(2)"
                    (click)="forceSelectAll(2)"
                    (paste)="pasteHandler($event)"
                    (keypress)="validateNumericInput($event, 2)"
                    (keydown)="backspaceHandler($event,2)"
                    inputmode="numeric"
                    formControlName="otp2">
                <input
                    #otp3
                    type="text"
                    class="form-control"
                    [maxLength]="1"
                    (focus)="forceSelectAll(3)"
                    (click)="forceSelectAll(3)"
                    (paste)="pasteHandler($event)"
                    (keypress)="validateNumericInput($event, 3)"
                    (keydown)="backspaceHandler($event,3)"
                    inputmode="numeric"
                    formControlName="otp3">
                <input
                    #otp4
                    type="text"
                    class="form-control"
                    [maxLength]="1"
                    (focus)="forceSelectAll(4)"
                    (click)="forceSelectAll(4)"
                    (paste)="pasteHandler($event)"
                    (keypress)="validateNumericInput($event, 4)"
                    (keydown)="backspaceHandler($event,4)"
                    inputmode="numeric"
                    formControlName="otp4">
            </div>
        <app-primary-button [disabled]="form.invalid" [isLoading]="isLoading" type="submit" addClass="primaryBtnLg mx-2" text="Verify"></app-primary-button>
        <div
            class="failSignin {{error && error.length && 'showFailSigninMsg'}}"
            translate
            >
            Invalid OTP
        </div>
    </form>
    <img src="../../../../../assets/images/signin/{{isArabic ? 'ar-desktop.png' : 'en-desktop.png'}}" alt="" class="placeholderDesktopImage">
</div>
