<form [formGroup]="form" (submit)="login.emit()">
    <div class="d-flex justify-content-center">
        <h3 class="text-primary" translate>Login</h3>
    </div>
    <div class="card p-2">
        <div class="row">
            <div class="container">
                <div class="form-group col-12 mt-2">
                    <label
                        class="w-100 text-center"
                        for="email"
                        translate>
                        Email
                    </label>
                    <div class="input-group">
                        <span class="input-group-text fa fa-envelope"></span>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="email"
                            [placeholder]="'email' | translate">
                    </div>
                </div>
                <div class="form-group col-12 mt-2">
                    <label
                        class="w-100 text-center"
                        for="password"
                        translate>
                        Password
                    </label>
                    <div class="input-group">
                        <span class="input-group-text fa fa-lock"></span>
                        <input
                            type="password"
                            class="form-control"
                            formControlName="password"
                            [placeholder]="'password' | translate">
                    </div>
                </div>
                <div
                    *ngIf="error && error.length"
                    class="text-danger my-2">
                    {{ error }}
                </div>
                <div class="d-flex justify-content-center mt-2">
                    <button
                        type="submit"
                        class="btn btn-primary"
                        [disabled]="disableLoginButton || isLoading">
                        <span *ngIf="!isLoading" class="fa fa-sign-in-alt"></span>
                        <span *ngIf="!isLoading" translate> Login</span>
                        <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm"></span>
                        <span *ngIf="isLoading" translate> Logging In</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center mt-4">
        <a class="text-primary forgot-password" (click)="goToForgotPassword.emit()" translate>Forgot Password?</a>
    </div>
</form>
