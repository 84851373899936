/* eslint-disable prettier/prettier */
import { HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiResult } from '@portal/shared/models/api-result.model';
import { Observable, of } from 'rxjs';
import { IGradeFilter } from '../models/grade.model';
import { ISchoolFilter } from '../models/school-filter.model';
import { ISchool } from '../models/school.model';
import { GetDisplayDate } from '@portal/shared/functions/get-display-date';
import { DEFAULT_SELECT_FIELDS, INTERCEPTOR_SKIP_HEADER, dummyErrorResponse } from '@portal/shared/constants/common.constants';
import { catchError } from 'rxjs/operators';
import { HttpService } from '@portal/shared/services/http/http-service';

@Injectable({
    providedIn: 'root'
})
export class SchoolService {
    constructor(private httpService: HttpService) { }

    public getSchools = (filter?: ISchoolFilter): Observable<HttpResponse<IApiResult>> => {
        let url = `schools?`
            .concat('page=', (filter?.page || 1).toString())
            .concat('&limit=', (filter?.limit || 1000).toString());

        if (filter?.search && filter?.search?.length) {
            url = url.concat('&search=', filter.search);
        }
        if (filter?.city && filter?.city?.length) {
            url = url.concat('&city=', filter.city);
        }

        if (filter?.cardStatus && filter.cardStatus.length) {
            url = url.concat('&cardStatus=', filter.cardStatus)
                .concat('&fromDate=', GetDisplayDate(filter.fromDate))
                .concat('&toDate=', GetDisplayDate(filter.toDate));;
        }

        return this.httpService.v2Get(url, 'response');
    };

    public getGrades = (schoolId: string, filter: IGradeFilter): Observable<HttpResponse<IApiResult>> => {
        let url = `schools/${schoolId}/grades?`
            .concat('page=', (filter?.page || 1).toString())
            .concat('&limit=', (filter?.limit || 1000).toString());

        if (filter?.search && filter?.search?.length) {
            url = url.concat('&search=', filter.search);
        }

        return this.httpService.v2Get(url, 'response');
    };

    public getSectionsByGrade = (schoolId: string, gradeId: string): Observable<IApiResult> => {
        return this.httpService.v2Get(`schools/${schoolId}/grades/${gradeId}/sections`)
            .pipe(catchError(() => of<IApiResult>(dummyErrorResponse)));
    };

    public getSectionsByGrades = (schoolId: string, gradeIds: string[]): Observable<IApiResult> => {
        return this.httpService.v2Get(`schools/${schoolId}/grades/sections?grade=${gradeIds}`)
            .pipe(catchError(() => of<IApiResult>(dummyErrorResponse)));
    };

    public getSelectSchools = (select = DEFAULT_SELECT_FIELDS, canteenId?: string, schoolFilter?: ISchoolFilter): Observable<IApiResult> => {
        let url = `schools?select=${(select || []).join(',')}`;

        if (canteenId && canteenId.length) {
            url = url.concat(`&canteen=${canteenId}`);
        }

        if (schoolFilter?.cardStatus && schoolFilter.cardStatus.length) {
            url = url.concat(`&cardStatus=${schoolFilter.cardStatus}`)
                .concat('&fromDate=', GetDisplayDate(schoolFilter.fromDate))
                .concat('&toDate=', GetDisplayDate(schoolFilter.toDate));
        }

        return this.httpService.v2Get(url).pipe(catchError(() => of<IApiResult>(dummyErrorResponse)));
    };

    public getSchool = (schoolId: string): Observable<IApiResult> => {
        return this.httpService.v2Get(`schools/${schoolId}`);
    };

    public lookupSchool = (schoolId: string): Observable<IApiResult> => {
        return this.httpService.v2Get(`schools?schoolRegistrationCode=${schoolId}`);
    };

    public createSchool = (school: ISchool, logo?: Blob): Observable<HttpEvent<IApiResult>> => {
        const headers = new HttpHeaders()
        headers.append('Content-Type', 'multipart/form-data');

        const formData = new FormData()
        if (logo) {
            const originalname = `schoolLogo.${logo.type.split('/')[1]}`; // Required by BE
            formData.append('logo', logo, originalname)
        }
        formData.append('postData', JSON.stringify(school))

        return this.httpService.v2Post(
            `schools`,
            formData,
            headers.append(INTERCEPTOR_SKIP_HEADER, ''),
            'events',
            true
        );
    };

    public patchSchool = (schoolId: string, school: ISchool, logo?: Blob): Observable<HttpEvent<IApiResult>> => {
        const headers = new HttpHeaders()
        headers.append('Content-Type', 'multipart/form-data');

        const formData = new FormData()
        if (logo) {
            const originalname = `schoolLogo.${logo.type.split('/')[1]}`; // Required by BE
            formData.append('logo', logo, originalname)
        }
        formData.append('postData', JSON.stringify(school))

        return this.httpService.v2Patch(
            `schools/${schoolId}`,
            formData,
            headers.append(INTERCEPTOR_SKIP_HEADER, ''),
            'events',
            true
        );
    };

    public getGrade = (schoolId: string, gradeId: string): Observable<IApiResult> => {
        return this.httpService.v2Get(`schools/${schoolId}/grades/${gradeId}`);
    };

    public getSelectGrades = (schoolId: string, select: string[]): Observable<IApiResult> => {
        const url = `schools/${schoolId}/grades?page=1&limit=1000`
            .concat('&select=', (select || []).join(','));

        return this.httpService.v2Get(url).pipe(catchError(() => of<IApiResult>(dummyErrorResponse)) );
    };

    public createGrade = (schoolId: string, grade: string): Observable<IApiResult> => {
        return this.httpService.v2Post(`schools/${schoolId}/grades`, { grade: grade });
    };

    public patchGrade = (schoolId: string, gradeId: string, grade: string): Observable<IApiResult> => {
        return this.httpService.v2Patch(`schools/${schoolId}/grades/${gradeId}`, { grade: grade });
    };

    public deleteGrade = (schoolId: string, gradeId: string): Observable<IApiResult> => {
        return this.httpService.v2Delete(`schools/${schoolId}/grades/${gradeId}`);
    };

    public emptySchool(): ISchool {
        const school: ISchool = {
            _id: '',
            id: '',
            // details
            translations: {
                en: '',
                ar: ''
            },
            schoolName: '',
            schoolRegistrationCode: '',
            vat: '',
            //contact info
            address: '',
            streetAddress: {
                unitNo: '',
                buildingNo: '',
                street: '',
                district: '',
                city: '',
                zipCode: '',
                country: '',
                additional: '',
            },
            geoLocation: {
                coordinates: [0, 0],
                type: 'Point'
            },
            phone: '',
            mobileNo: '',
            email: '',
            // other
            noOfStudents: 0,
            initDailyPurchase: 0,
            creditLimit: 0,
            currency: '',
            currentAttendanceSubscriptionEnds: new Date(),
            currentAttendanceSubscriptionStarts: new Date(),
            currentSubscriptionEnds: new Date(),
            currentSubscriptionStarts: new Date(),
            physicalDevices: [],
            subscriptionHistory: [],
            hasUpdatedStudentData: false,
            // services
            hasCanteenAccess: true,
            hasAttendanceAccess: false,
            hasDismissalCallAccess: false,
            hasDigitalCardsAccess: true,
            hasFacePayAccess: false,
            // canteen
            canteens: [],
            // attendance services
            toRunAbsenceScript:false,
            toSendAbsenceNotifications:false,
            // timestamp
            isDeleted: false,
            isValid: false,
            createdBy: '',
            createdAt: new Date(),
            updatedAt: new Date(),
        };

        return school;
    };
}
