/* eslint-disable prettier/prettier */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './auth/services/auth.service';
import { IUser } from './shared/models/user.model';
import { ScreenResolutionService } from './shared/services/screen-resolution.service';
import * as moment from 'moment';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localeEN from '@angular/common/locales/en';
import localeAR from '@angular/common/locales/ar';
import { LanguageService } from './shared/services/language/language.service';
import { ArabicLanguage, DefaultLanguage, LanguageKey } from './shared/services/language/language.model';
import { RoutingNavigateService } from './shared/services/routing-navigate/routing-navigate.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    public currentUser: IUser | null | undefined;
    public title = 'waki-frontend';
    public app = AppComponent;
    public isLoadingFullscreen = false;

    public static textDir: string = 'ltr';
    public static get isRTL(): boolean { return AppComponent.textDir == 'rtl' };
    public static isDesktop = true as boolean;
    public static isMobile = false as boolean;
    public static showSidebar = true as boolean;
    public static isCallPage = false as boolean;

    private interval: NodeJS.Timeout;
    private subs = new Subscription();

    constructor(
        private router: Router,
        private authService: AuthService,
        private screenResolutionService: ScreenResolutionService,
        private languageService: LanguageService,
        private routingNavigateService: RoutingNavigateService,
    ) {
        this.router.events.subscribe((e: Event) => {
            if (e instanceof NavigationEnd && e.url) {
                const exceptList = ['/schools/dismissal-call/call-system'];
                AppComponent.isCallPage = exceptList.includes(e.url?.toString()?.split('?')[0]);
            }
        })

        this.interval = setInterval(() => {
            const localStorageDate = localStorage.getItem('CurrentLogInDate');

            if (!localStorageDate) {
                localStorage.setItem('CurrentLogInDate', moment().format('MM-DD-YYYY'));
            }

            if (localStorageDate && moment().isAfter(moment(localStorageDate, 'MM-DD-YYYY'), 'day')) {
                localStorage.setItem('CurrentLogInDate', moment().format('MM-DD-YYYY'));
                localStorage.removeItem('pagesUsed10kExport');
            }
        }, 1000);

        // Listen for changes in localStorage
        window.addEventListener('storage', this.handleStorageChange);
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
        this.subs.unsubscribe();
        window.addEventListener('storage', this.handleStorageChange);

        if (!this.currentUser) {
            clearInterval(undefined);
            this.authService.logout();
            window.location.href = 'login';
        }
    }

    ngOnInit(): void {
        this.initApp();
        this.useLocalization();
        this.subs.add(
            this.languageService.languageChanged().subscribe((langCode) => {
                localStorage.setItem(LanguageKey, langCode);
                if (langCode === ArabicLanguage) {
                    document.body.setAttribute('dir', 'rtl');
                    AppComponent.textDir = 'rtl';
                } else {
                    document.body.removeAttribute('dir');
                    AppComponent.textDir = 'ltr';
                }
            })
        )
    }

    private useLocalization(): this {
        registerLocaleData(localeEN);
        registerLocaleData(localeAR);
        this.subs.add(this.setupLanguage());
        return this;
    }

    private setupLanguage(): Subscription {
        return of(localStorage.getItem(LanguageKey))
            .subscribe((lang) => {
                this.languageService.updateLanguage(lang ?? DefaultLanguage)
            });
    }

    private initApp() {
        this.currentUser = this.authService.localStorageUser;
        this.subs.add(this.onCurrentPlatform());

        // if (!this.authService.isAdminOrSuperAdmin()) {
        //     this.script.addScript();
        // }
        // if (!this.currentUser || !this.currentUser?.refreshToken) {
        //     this.authService.removeZohoChat();
        // }
    }

    public onCurrentPlatform = (): void => {
        AppComponent.isDesktop = this.screenResolutionService.isDesktop();
        AppComponent.isMobile = this.screenResolutionService.isMobile();
        AppComponent.showSidebar = AppComponent.isDesktop;
    }

    private handleStorageChange = (event: StorageEvent): void => {
        // Check if localStorage has been cleared
        if (event.key === null) {
            this.isLoadingFullscreen = true;
            setTimeout(() => {
                this.authService.logout();
                this.routingNavigateService.login();
                this.isLoadingFullscreen = false;
            }, 1000);
        }
    };
}
