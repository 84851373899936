export function removeNullOrEmpty(object: any) {
    Object.keys(object).map((key) => {
        if (!object[key]) {
            delete object[key];
        } else if (Array.isArray(object[key])) {
            object[key] = (object[key] || []).map((a: any) =>
                removeNullOrEmpty(a)
            );
        }
    });
    return object;
}
