/* eslint-disable prettier/prettier */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faPlus, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { AppComponent } from '@portal/app.component';

@Component({
  selector: 'app-bulk-upload-button',
  templateUrl: './bulk-upload-button.component.html',
  styleUrls: ['./bulk-upload-button.component.scss']
})
export class BulkUploadButtonComponent {
  @Input() disabled: boolean = false;
  @Input() icon: IconDefinition = faPlus;
  @Input() showIcon: Boolean = true;
  @Input() customClass: string = '';
  @Input() size: string = AppComponent.isDesktop ? '' : 'btn-sm';
  @Input() text: string = '';
  @Output() cancel = new EventEmitter();

  constructor() { }

}
