<div class="forgot-password-container m-auto">
    <div class="forgot-password-form-container">
        <app-backup-forgot-password-form
            [form]="form"
            [isLoading]="isLoading"
            [currentStep]="currentStep"
            [notValid]="notValid"
            (stepOneClick)="processStepOne()"
            (stepTwoClick)="processStepTwo()"
            (stepThreeClick)="processStepThree()"></app-backup-forgot-password-form>
    </div>
</div>
