import { Injectable } from "@angular/core";
import { HttpService } from "./http-service";
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from "@angular/common/http";
import { INTERCEPTOR_SKIP_HEADER } from "@portal/shared/constants/common.constants";
import { IBaseQuery } from "@portal/shared/models/base.model";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { IApiResult } from "@portal/shared/models/api-result.model";

export const isNullOrEmpty = (value: any): boolean => (!value || _.isEmpty(value));

/**
 * A parent service for all the REST API endpoint services of the app.
 */
@Injectable()
export class BaseResourceService extends HttpService {
  protected resourceUrl: string = '';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  /**
   * Gets a list of the specified resource from the server.
   * @param query
   */
  public getList(query?: IBaseQuery) {
    if (!query) {
      return this.v2Get(this.resourceUrl);
    }
    let url = `${this.resourceUrl}?v3`;
    Object.entries(query).forEach(([key, value]) => {
      if (_.isEmpty(value)) {
        return;
      }
      url = url.concat(`&${key}=${value}`);
    })
    return this.v2Get(url);
  }

  /**
   * Gets a paged list of the specified resource from the server.
   * @param query
   */
  public pagedList(query: IBaseQuery): Observable<HttpResponse<IApiResult>> {
    let queryParams = `page=${query.page}&limit=${query.limit}`;
    Object.entries(query).forEach(([key, value]) => {
      if (['page', 'limit'].includes(key) || isNullOrEmpty(value)) {
        return;
      }
      queryParams = queryParams.concat(`&${key}=${value}`);
    })
    return this.v2Get(`${this.resourceUrl}?${queryParams}`, 'response');
  }

  /**
   * Get a single resource from the server based on the resourceId.
   * @param resourceId
   */
  public getById(resourceId: string): Observable<IApiResult> {
    if (resourceId === null || resourceId === undefined) {
      throw new Error(
        `Required parameter id was null or undefined when calling ${this.resourceUrl} .`
      );
    }
    return this.v2Get(`${this.resourceUrl}/${encodeURIComponent(String(resourceId))}`);
  }

  /**
   * Creates a new resource if resourceId is not defined, otherwise update it.
   * @param body 
   * @param resourceId - Optional.
   */
  public saveRecord(body: any, resourceId?: string): Observable<IApiResult> {
    if (!resourceId) {
      return this.v2Post(this.resourceUrl, body);
    }

    return this.v2Patch(`${this.resourceUrl}/${resourceId}`, body);
  }

  public saveRecordAsEvent(body: any, resourceId?: string): Observable<HttpEvent<IApiResult>> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append(INTERCEPTOR_SKIP_HEADER, '');

    if (!resourceId) {
      return this.v2Post(this.resourceUrl, body, headers, 'events', true);
    }

    return this.v2Patch(`${this.resourceUrl}/${resourceId}`, body, headers, 'events', true);
  }

  /**
   * ---------------------------------------------------------------
   */

  /**
   * Gets a list of the specified resource from the server.
   */
  public list(queryParams?: string) {
    const url = queryParams ? `${this.resourceUrl}?${queryParams}` : this.resourceUrl;
    return this.get(url);
  }

  /**
   * Gets a list of the specified resource from the server.
   */
  public paginatedList(page?: number, limit?: number, queryParams?: string) { //&${queryParams}
    let url = page ? `${this.resourceUrl}?page=${page}&limit=${limit}` : this.resourceUrl;
    url = queryParams ? `${url}&${queryParams}` : url;
    return this.get(url);
  }

  /**
   * Get a single resource from the server based on the id.
   * @param id - the id of the resource
   */
  public find(id: string) {
    return this.get(`${this.resourceUrl}/${id}`);
  }

  /**
   * Creates a new resource if <code>id</code> is not defined, otherwise update it.
   * @param body - the data to be saved
   * @param id - Optional. The id of the existing resource to be updated.
   */
  public save(body: any, id?: string) {
    if (!id) {
      return this.post(this.resourceUrl, body);
    }

    return this.patch(`${this.resourceUrl}/${id}`, body);
  }

  public saveWithImage(body: any, id?: string) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append(INTERCEPTOR_SKIP_HEADER, '');

    if (!id) {
      return this.post(this.resourceUrl, body, headers);
    }

    return this.patch(`${this.resourceUrl}/${id}`, body, headers);
  }

  /**
   * Deletes a resource from the server based on the id.
   * @param id - the id of the resource to be deleted
   */
  public remove(id: number) {
    return this.delete(`${this.resourceUrl}/${id}`);
  }

  /**
   * Restores a resource from the server based on the id
   * @param id - the id of the resource to be restored
   */
  public restore(id: number, body: any) {
    return this.post(`${this.resourceUrl}/${id}/restore`, body);
  }

  /**
   * Permanently deletes a resource from the server based on the id
   * @param id - the id of the resource to be deleted
   */
  public forceDelete(id: number) {
    return this.post(`${this.resourceUrl}/${id}/force-delete`, {});
  }

  public search(searchParams: Array<any>, page?: number) {
    return this.post(`${this.resourceUrl}/search?page=${page}`, { searchParams });
  }
}
