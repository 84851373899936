import { Injectable } from '@angular/core';
import { RouteAuthGuardService } from '@portal/shared/guards/route-auth-guard.service';
import { UserClientContext } from '@portal/shared/models/user-client-context.model';
import { hasPermissionToGetUserList } from '@portal/shared/permissions/permission';

@Injectable({
    providedIn: 'root',
})
export class UsersGuardService extends RouteAuthGuardService {
    static canAccess = (context: UserClientContext): boolean => hasPermissionToGetUserList(context.roles);

    protected canAccess = (context: UserClientContext): boolean => UsersGuardService.canAccess(context);
}