import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

const EXCEPT_URLS = [
  'login',
  'otp',
  'forgot-password',
  'unauthorized-page',
];

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedService {
  constructor(private router: Router) {}

  public handle(): UrlTree {
    const exceptedPageInd = window.location.pathname.split('/').findIndex(f => EXCEPT_URLS.includes(f.toLocaleLowerCase()));
    if (exceptedPageInd <= 0) {
      return this.router.parseUrl('/unauthorized-page');
    }

    return this.router.parseUrl(EXCEPT_URLS[exceptedPageInd] ?? '/login');
  }
}
