import { BehaviorSubject, Observable } from "rxjs";
import { AuthService } from "@portal/auth/services/auth.service";
import { first, map, tap } from "rxjs/operators";
import { Inject, Injectable } from "@angular/core";
import { CachedRequestResponse } from "../cache/cached-request-response";
import { UserClientContext } from "../models/user-client-context.model";
import cacheSettings from "../cache/cache-settings";
import { IBaseConfig } from "@portal/common/config/config.model";

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    public readonly claims: string[];
    public readonly config: IBaseConfig;
    public readonly featureFlags: IBaseConfig['featureFlags'];

    private getUserClientContextResponse = new CachedRequestResponse<UserClientContext>(
        () => this.authService.getUserClientContext(),
        cacheSettings.clientContext.get
    );
    private claims$ = new BehaviorSubject<string[]>([]);

    constructor(
        @Inject('environment') env: IBaseConfig,
        private authService: AuthService,
    ) { 
        this.config = { ...env };
        this.featureFlags = this.config.featureFlags;
        this.claims = this.claims$.getValue();
    }

    public getUserClaims$(): Observable<string[]> {
        if (this.claims$.getValue() === undefined) {
            return this.userClientContext$.pipe(
                map((res) => <string[]>(res.claims ?? [])),
                tap((claims) => {
                    this.claims$.next(claims);
                }),
                first(),
            )
        } else {
            return this.claims$.asObservable();
        }
    }

    get userClientContext$(): Observable<UserClientContext> {
        return this.getUserClientContextResponse.get();
    }

    public getUserClientContext(): Observable<UserClientContext> {
        return this.userClientContext$.pipe(first());
    }
}