import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IApiFailure, IApiResult } from '../models/api-result.model';
import { catchError, first, map } from 'rxjs/operators';
import { NotifyService } from './notify.service';
import { ICountry } from '../models/country.model';
import { ICity } from '../models/city.model';
import { CachedRequestResponse } from '../cache/cached-request-response';
import cacheSettings from '../cache/cache-settings';
import { HttpService } from './http/http-service';

const emptyArray: any[] = [];
const dummyErrorResponse = {
    success: false,
    msg: 'common.error_message.retrieve_data_fail',
} as IApiResult;

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    private getCountriesResponse = new CachedRequestResponse<ICountry[]>(
        () => this.httpService.v2Get(`users/countries`).pipe(
            catchError(() => of<IApiResult>(dummyErrorResponse)),
            map((result: IApiResult) => <ICountry[]>(result.countries || emptyArray))
        ),
        cacheSettings.locations.get
    );

    constructor(private httpService: HttpService, private notify: NotifyService) { }

    get countries$(): Observable<ICountry[]> {
        return this.getCountriesResponse.get();
    }

    // TODO OBSOLETE: Should use getCountryList instead
    getCountries = (): Observable<IApiResult> => {
        return this.httpService.v2Get(`users/countries`);
    };

    getCities = (countryId: string): Observable<IApiResult> => {
        return this.httpService.v2Get(`users/cities?country=${countryId}`);
    };

    public getCountryList(): Observable<ICountry[]> {
        return this.countries$.pipe(first());
    }

    public getUserCountryCities(countryId: string, showNotify = true): Observable<ICity[]> {
        return this.httpService.v2Get(`users/cities?country=${countryId}`).pipe(
            catchError((error: IApiFailure) => {
                if (showNotify) {
                    this.notify.error('Cities', dummyErrorResponse.msg, error);
                }
                return of<IApiResult>(dummyErrorResponse);
            }),
            map((result: IApiResult) => <ICity[]>(result.cities || emptyArray))
        );
    };
}
