/* eslint-disable prettier/prettier */
import { ICanteen } from '@portal/canteen/models/canteen.model';
import { ISchool } from '@portal/school/models/school.model';
import { UserRoles } from '../constants/user-roles.constants';
import { IBaseModel } from './base.model';
import { ITranslation } from './translation.model';
import { IMerchant } from '@portal/merchant/models/merchant.model';

export type IResendOTPType = 'VERIFICATION' | 'LOGIN' | 'FORGOT_PWD';
export const IResendOTPType = {
    verification: 'VERIFICATION' as IResendOTPType,
    login: 'LOGIN' as IResendOTPType,
    forgotPassword: 'FORGOT_PWD' as IResendOTPType,
}

export type AppUserSubType = 'MENU_CATALOGUE' | 'DIRECT_PAYMENTS' | 'TABLE';
export const AppUserSubType = {
    menuCatalogue: 'MENU_CATALOGUE' as AppUserSubType,
    directPayments: 'DIRECT_PAYMENTS' as AppUserSubType,
    table: 'TABLE' as AppUserSubType,
}

export interface Credentials {
    email: string;
    password: string;
}

export interface ILoginOTP {
    email?: string;
    mobileNo?: string;
    otp?: string;
    type?: IResendOTPType;
}

export interface IForgotPassword {
    email: string;
    verificationCode?: string;
    newPassword?: string;
}

export interface IUser extends IBaseModel {
    translations?: ITranslation;
    fullName: string;
    firstName: { en: string },
    email: string;
    deviceSecurityPIN: string,
    mobileNo: string;
    sex?: string;
    nationalId?: number;
    currentBalance?: number;
    orderStatus?: any;
    isProfileSetup: boolean;
    isValid?: boolean;
    roles: string[];
    permissions: string[];
    claims?: any[];
    token?: string;
    refreshToken?: string;
    emailVerificationStatus?: string;
    mobileNoVerificationStatus?: string;
    toShowFacePay?: any;
    businessAppPaymentMethods?: IUserBizAppPaymentMethods;
    creditCardLogosEnabled?: IUserBizAppCreditCardLogosEnabled;
    nearpayTerminalId?: string;
    businessAppUserSubType?: AppUserSubType;
    resendSMSAttempts?: number;
    allowTeacherToDismissFromScreenEnabled?: boolean;

    // School/Canteen
    schools: ISchool[];
    canteens: ICanteen[];
    canteenAppUserType?: string;
    canteenInventory?: any;

    // Timestamps
    createdAt?: Date;
    lastSuccessfullLogin?: any;
    lastFailedLogin?: any;

    // Image
    profilePic?: string;
    facePayPic?: string;
    facePicUploadHistory?:[]

    // Face Recognition
    payByFaceEnabled?: boolean;
    innovatricsFaceIds?: string;
    innovatricsWatchListMemberId?: any

    // QR Code
    payByQRCodeEnabled?: boolean;
    paymentQRCodeGenerationHistory?:[]

    // Card
    cardId?: string;
    cardSerialNo?: string;
    cardColor?: string;
    virtualCardDesign?: string;
    isCardEnabled?: boolean;

    accountType?: string;
    maxAmount?: number;

    //Merchant
    merchants: IMerchant[];
    merchantBranches?: any;
    merchantBranchInventory?: any;

    // Settings
    isLoggedIn?: boolean;
    isMasterCanteenAppUser?: boolean;
}

export const defaultUser: IUser = {
    _id: '',
    fullName: '',
    firstName: { en: "" },
    email: '',
    deviceSecurityPIN: "",
    mobileNo: '',
    isProfileSetup: false,
    permissions: [],
    schools: [],
    canteens: [],
    merchants:[],
    roles: [],
    translations: { en: '', ar: '' }
};

export interface IUSerSchool extends IBaseModel {
    id: string;
    schoolRegistrationCode: string;
    schoolName: string;
    translations: ITranslation;
}

export interface IUserCanteen extends IBaseModel {
    id: string;
    canteenId: string;
    canteenName: string;
    translations: ITranslation;
}

export interface IUserFilter {
    search?: string;
    page?: number;
    limit?: number;
    schoolId?: string;
    roles?: string;
    canteenId?: string;
    school?: string;
    canteen?: string;
    merchant?: string;
    canteenAppUserType?: string;
    nearpayTerminalId?: boolean;
    hasMadaPayEnabled?: boolean;
    status?: boolean;
}

export const defaultUserFilter: IUserFilter = {
    search: '',
    page: 1,
    limit: 100
};

export const defaultBusinessUserFilter: IUserFilter = {
    search: '',
    page: 1,
    limit: 100,
    roles: [
        UserRoles.SUPER_CANTEEN_USER,
        UserRoles.CANTEEN_USER,
        UserRoles.CANTEEN_APP_USER,
        UserRoles.SUPER_SCHOOL_USER,
        UserRoles.SCHOOL_USER,
        UserRoles.ATTENDANCE_APP_USER,
        UserRoles.GATE_GUARD_USER,
        UserRoles.CALL_SYSTEM_WEB_USER,
        UserRoles.SUPER_MERCHANT_USER,
        UserRoles.MERCHANT_USER,
        UserRoles.MERCHANT_APP_USER,
    ].join(',')
};

export const userFilterByRoles = (isAdmin = false, isSchoolUser = false, isCanteenUser = false, isMerchantUser = false): IUserFilter => {
    if (isAdmin) {
        return {
            ...defaultUserFilter,
            roles: [
                UserRoles.WAKI_ADMIN,
                UserRoles.WAKI_SUPER_ADMIN
            ].join(',')
        };

    } else if (isCanteenUser) {
        return {
            ...defaultUserFilter,
            roles: [
                UserRoles.SUPER_CANTEEN_USER,
                UserRoles.CANTEEN_USER,
                UserRoles.CANTEEN_APP_USER
            ].join(',')
        };

    } else if (isSchoolUser) {
        return {
            ...defaultUserFilter,
            roles: [
                UserRoles.SUPER_SCHOOL_USER,
                UserRoles.SCHOOL_USER,
                UserRoles.ATTENDANCE_APP_USER,
                UserRoles.GATE_GUARD_USER
            ].join(',')
        };
    } else if (isMerchantUser) {
        return {
            ...defaultUserFilter,
            roles: [
                UserRoles.SUPER_MERCHANT_USER,
                UserRoles.MERCHANT_USER,
                UserRoles.MERCHANT_APP_USER
            ].join(',')
        };
    }

    return defaultUserFilter;
}

export interface IUserBizAppPaymentMethods {
    allowCardOrders: boolean;
    hasCashPayEnabled: boolean;
    hasFacePayAccess: boolean;
    hasMadaPayEnabled: boolean;
    hasQRCodePayEnabled: boolean;
}

export const defaultUserBizAppPaymentMethods: IUserBizAppPaymentMethods = {
    allowCardOrders: false,
    hasCashPayEnabled: false,
    hasFacePayAccess: false,
    hasMadaPayEnabled: false,
    hasQRCodePayEnabled: false,
}
export interface IUserBizAppCreditCardLogosEnabled {
    mada: boolean;
    visa: boolean;
    mastercard: boolean;
}

export const defaultUserBizAppCreditCardLogosEnabled: IUserBizAppCreditCardLogosEnabled = {
    mada: false,
    visa: false,
    mastercard: false,
}
