import * as _ from "lodash";
import { CLAIM_LABEL, NAV_MENU } from "../../enums/nav-menu.enum";
import { IUserPermissions, IUserType } from "../permission.model";
import { getPageClaimValues, getClaimLabelsByType, getClaimValuesByType } from "./claims.function";

/*
--- Always add the `View list page` permission as the first object to ensure not impact the rule of other claims.
*/
export const PERMISSIONS: IUserPermissions = {
    HOME: {
        VIEW_HOME: {
            VIEW_DASHBOARD_STATS: 'VIEW_DASHBOARD_STATS',
        }
    },
    REPORTS: {
        VIEW_CANTEENS_REPORTS: {
            VIEW_PAGE: 'VIEW_PAGE',
            EXPORT_DATA: 'EXPORT_DATA',
            DOWNLOAD_INVENTORY_REPORT: 'DOWNLOAD_INVENTORY_REPORT',
        },
        VIEW_MERCHANTS_REPORTS: {
            VIEW_PAGE: 'VIEW_PAGE',
            EXPORT_DATA: 'EXPORT_DATA',
            DOWNLOAD_INVENTORY_REPORT: 'DOWNLOAD_INVENTORY_REPORT',
        }
    },
    SCHOOLS: {
        STUDENTS: {
            VIEW_STUDENTS: {
                VIEW_STUDENT_LIST: 'VIEW_STUDENT_LIST',
                PRINT_STUDENT_QR_CODES: 'PRINT_STUDENT_QR_CODES',
                EXPORT_DATA: 'EXPORT_DATA',
            },
            EDIT_CARD_STATUS: {
                EDIT_CARD_STATUS: 'EDIT_CARD_STATUS'
            },
            EDIT_STUDENT: {
                VIEW_STUDENT_DETAILS: 'VIEW_STUDENT_DETAILS',
                EDIT_STUDENT: 'EDIT_STUDENT',
            }
        },
        CLASSES: {
            VIEW_CLASSES: {
                VIEW_CLASS_LIST: 'VIEW_CLASS_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
            },
            ACTIONS_CLASS_DETAILS: {
                VIEW_CLASS_DETAILS: 'VIEW_CLASS_DETAILS',
                EDIT_CLASS: 'EDIT_CLASS',
            },
            ADD_CLASS: {
                CREATE_CLASS: 'CREATE_CLASS',
            },
            EDIT_REGULATIONS: {
                EDIT_REGULATIONS: 'EDIT_REGULATIONS',
            },
        },
        ATTENDANCE: {
            VIEW_LOGS: {
                VIEW_ATTENDANCE_LIST: 'VIEW_ATTENDANCE_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
                EDIT_CHECKIN_STATUS: 'EDIT_CHECKIN_STATUS',
                EDIT_CHECKOUT_STATUS: 'EDIT_CHECKOUT_STATUS',
            }
        },
        DISMISSAL_CALL: {
            VIEW_DISMISSAL_CALL: {
                VIEW_PAGE: 'VIEW_PAGE',
            }
        },
        DISMISSAL_CALL_LOGS: {
            VIEW_LOGS: {
                VIEW_CALL_LIST: 'VIEW_CALL_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
            }
        },
        CANTEEN_SALES: {
            VIEW_SALES: {
                VIEW_SALES_LIST: 'VIEW_SALES_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
            }
        }
    },
    //--------
    TRANSACTIONS: {
        VIEW_TRANSACTIONS: {
            VIEW_TRANSACTION_LIST: 'VIEW_TRANSACTION_LIST',
            EXPORT_DATA: 'EXPORT_DATA',
        }
    },
    TRANSFER_REQUESTS: {
        VIEW_TRANSFER_REQUESTS: {
            VIEW_TRANSFER_REQUEST_LIST: 'VIEW_TRANSFER_REQUEST_LIST',
            EXPORT_DATA: 'EXPORT_DATA',
        }
    },
    FEES: {
        VIEW_FEES: {
            VIEW_FEE_LIST: 'VIEW_FEE_LIST',
            EMAIL_INVOICE: 'EMAIL_INVOICE',
            PRINT_INVOICE: 'PRINT_INVOICE',
            EXPORT_DATA: 'EXPORT_DATA',
        }
    },
    CANTEENS: {
        HOME: {
            VIEW_HOME: {
                VIEW_ONLINE_ORDERS: 'VIEW_ONLINE_ORDERS',
            }
        },
        MONEY_REQUESTS: {
            VIEW_MONEY_REQUESTS: {
                VIEW_MONEY_REQUEST_LIST: 'VIEW_MONEY_REQUEST_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
            },
        },
        FOOD_ITEMS: {
            VIEW_CANTEEN_ITEMS: {
                VIEW_FOODITEM_LIST: 'VIEW_FOODITEM_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
            },
            EDIT_ITEM_STATUS: {
                ENABLE_DISABLE_ITEM: 'ENABLE_DISABLE_ITEM'
            },
            ADD_FOOD_ITEM: {
                ADD_ITEM: 'ADD_ITEM',
            },
            EDIT_FOOD_ITEM: {
                VIEW_ITEM_DETAILS: 'VIEW_ITEM_DETAILS',
                EDIT_ITEM: 'EDIT_ITEM',
            },
            ADD_CUSTOMIZATION: {
                ADD_CUSTOMIZATION: 'ADD_CUSTOMIZATION',
            },
        },
        FOOD_MENU: {
            VIEW_SCHOOL_FOOD_MENU: {
                VIEW_MENU_LIST: 'VIEW_MENU_LIST',
            },
            EDIT_SCHOOL_FOOD_MENU: {
                EDIT_SCHOOL_MENU: 'EDIT_SCHOOL_MENU',
                SORT_MENU_ITEMS: 'SORT_MENU_ITEMS',
            },
            ADD_INVENTORY: {
                ADD_INVENTORY: 'ADD_INVENTORY',
            },
            EDIT_INVENTORY: {
                EDIT_INVENTORY_NAME: 'EDIT_INVENTORY_NAME',
            },
            APPLY_QUANTITY_TO_ALL: {
                APPLY_QUANTITY_TO_ALL: 'APPLY_QUANTITY_TO_ALL',
                MODIFY_QUANTITIES: 'MODIFY_QUANTITIES',
            },
            RESET_PRICES: {
                RESET_PRICES: 'RESET_PRICES'
            },
            CUSTOMIZED_COLUMNS: {
                CUSTOMIZED_COLUMNS: 'CUSTOMIZED_COLUMNS'
            },
            ADD_FOOD_ITEMS: {
                ADD_FOOD_ITEMS: 'ADD_FOOD_ITEMS'
            },
            DELETE_FOOD_ITEMS: {
                DEL_FOOD_ITEMS: 'DEL_FOOD_ITEMS'
            }
        },
        FOOD_ORDERS: {
            VIEW_CANTEEN_ORDERS: {
                VIEW_FOODORDER_LIST: 'VIEW_FOODORDER_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
                EXPORT_PENDING_ONLINE_ORDERS: 'EXPORT_PENDING_ONLINE_ORDERS',
                DOWNLOAD_INVENTORY_REPORT: 'DOWNLOAD_INVENTORY_REPORT',
                EMAIL_INVOICE: 'EMAIL_INVOICE',
                PRINT_INVOICE: 'PRINT_INVOICE',
            },
            CHANGE_ORDER_STATUS: {
                EDIT_ORDER_STATUS: 'EDIT_ORDER_STATUS',
            },
            MARK_ALL_ORDERS_DELIVERED: {
                MARK_ALL_ORDERS_DELIVERED: 'MARK_ALL_ORDERS_DELIVERED',
            },
            MARK_ALL_ORDERS_REJECTED: {
                MARK_ALL_ORDERS_REJECTED: 'MARK_ALL_ORDERS_REJECTED',
            },
        },
        COUPONS: {
            VIEW_COUPONS: {
                VIEW_COUPON_LIST: 'VIEW_COUPON_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
            },
            CREATE_COUPON: {
                CREATE_COUPON: 'CREATE_COUPON',
            },
            EDIT_COUPON: {
                VIEW_COUPON_DETAILS: 'VIEW_COUPON_DETAILS',
                EDIT_COUPON: 'EDIT_COUPON',
            },
            PUBLISH_UNPUBLISH_COUPON: {
                PUBLISH_UNPUBLISH_COUPON: 'PUBLISH_UNPUBLISH_COUPON',
            },
        },
        COUPON_ORDERS: {
            VIEW_COUPON_ORDERS: {
                VIEW_ORDER_LIST: 'VIEW_ORDER_LIST',
                EMAIL_INVOICE: 'EMAIL_INVOICE',
                DOWNLOAD_INVOICE: 'DOWNLOAD_INVOICE',
                EXPORT_DATA: 'EXPORT_DATA',
            }
        },
    },
    MERCHANTS: {
        HOME: {
            VIEW_HOME: {
                VIEW_ONLINE_ORDERS: 'VIEW_ONLINE_ORDERS',
            }
        },
        MERCHANT_ITEMS: {
            VIEW_ITEMS: {
                VIEW_MERCHANT_ITEM_LIST: 'VIEW_MERCHANT_ITEM_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
            },
            ADD_ITEM: {
                ADD_ITEM: 'ADD_ITEM',
            },
            EDIT_ITEM_STATUS: {
                EDIT_ITEM_STATUS: 'EDIT_ITEM_STATUS',
            },
            EDIT_ITEM: {
                VIEW_ITEM_DETAILS: 'VIEW_ITEM_DETAILS',
                EDIT_ITEM: 'EDIT_ITEM',
            },
            ADD_CUSTOMIZATION: {
                ADD_CUSTOMIZATION: 'ADD_CUSTOMIZATION',
            },
        },
        MERCHANT_PRODUCT_CATALOGUES: {
            VIEW_CATALOGUE: {
                VIEW_CATALOGUE_LIST: 'VIEW_CATALOGUE_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
            },
            CREATE_BRANCH_CATALOGUE: {
                CREATE_BRANCH_CATALOGUE: 'CREATE_BRANCH_CATALOGUE',
            },
            EDIT_BRANCH_CATALOGUE: {
                VIEW_BRANCH_CATALOGUE_DETAILS: 'VIEW_BRANCH_CATALOGUE_DETAILS',
                EDIT_BRANCH_CATALOGUE: 'EDIT_BRANCH_CATALOGUE',
                SORT_CATALOGUE_ITEMS: 'SORT_CATALOGUE_ITEMS',
            },
            ADD_INVENTORY: {
                ADD_INVENTORY: 'ADD_INVENTORY',
            },
            EDIT_INVENTORY: {
                EDIT_INVENTORY_NAME: 'EDIT_INVENTORY_NAME',
            },
            CHANGE_QUANTITY: {
                MODIFY_QUANTITIES: 'MODIFY_QUANTITIES',
            },
            RESET_PRICES: {
                RESET_PRICES: 'RESET_PRICES',
            },
            CUSTOMIZED_COLUMNS: {
                CUSTOMIZED_COLUMNS: 'CUSTOMIZED_COLUMNS',
            },
            ADD_ITEMS: {
                ADD_ITEMS: 'ADD_ITEMS',
            },
            DELETE_ITEMS: {
                DEL_ITEMS: 'DEL_ITEMS',
            },
        },
        MERCHANT_ORDERS: {
            VIEW_ORDERS: {
                VIEW_MERCHANT_ORDER_LIST: 'VIEW_MERCHANT_ORDER_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
                EXPORT_PENDING_ONLINE_ORDERS: 'EXPORT_PENDING_ONLINE_ORDERS',
                DOWNLOAD_INVENTORY_REPORT: 'DOWNLOAD_INVENTORY_REPORT',
                EMAIL_INVOICE: 'EMAIL_INVOICE',
                PRINT_INVOICE: 'PRINT_INVOICE',
            }
        },
        MERCHANT_EVENTS: {
            VIEW_EVENTS: {
                VIEW_EVENT_LIST: 'VIEW_EVENT_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
            },
            CREATE_EVENT: {
                CREATE_EVENT: 'CREATE_EVENT',
            },
            EDIT_EVENT: {
                VIEW_EVENT_DETAILS: 'VIEW_EVENT_DETAILS',
                EDIT_EVENT: 'EDIT_EVENT',
            }
        },
        MERCHANT_EVENT_SALES: {
            VIEW_SALES: {
                VIEW_SALES_LIST: 'VIEW_SALES_LIST',
                EXPORT_DATA: 'EXPORT_DATA',
            }
        }
    },
    NOTIFICATIONS: {
        VIEW_NOTIFICATION: {
            VIEW_PAGE: 'VIEW_PAGE',
            PUSH_NOTIFICATION: 'PUSH_NOTIFICATION'
        },
        VIEW_HISTORY: {
            VIEW_NOTIFICATION_HISTORY: 'VIEW_NOTIFICATION_HISTORY',
        }
    },
    BUSINESS_STORE: {
        VIEW_STORE_ITEMS: {
            VIEW_STORE_ITEM_LIST: 'VIEW_STORE_ITEM_LIST',
        },
        BUY_ITEMS: {
            BUYING: 'BUYING',
            PAYMENT: 'PAYMENT',
        }
    },
    BUSINESS_ORDERS: {
        ORDERS: {
            VIEW_ORDER_LIST: 'VIEW_ORDER_LIST',
            DOWNLOAD_INVOICE: 'DOWNLOAD_INVOICE',
        }
    },
}

export const CLAIMS = [
    //**HOME */
    {
        label: NAV_MENU.HOME,
        claimLabel: CLAIM_LABEL.VIEW_HOME,
        claimValue: getPageClaimValues('HOME', { ...PERMISSIONS.HOME.VIEW_HOME, ...PERMISSIONS.CANTEENS.HOME.VIEW_HOME })
    },
    //** REPORTS */
    {
        label: NAV_MENU.CANTEENS_REPORTS,
        claimLabel: CLAIM_LABEL.VIEW_CANTEENS_REPORTS,
        claimValue: getPageClaimValues('CANTEENS_REPORTS', PERMISSIONS.REPORTS.VIEW_CANTEENS_REPORTS)
    },
    {
        label: NAV_MENU.MERCHANTS_REPORTS,
        claimLabel: CLAIM_LABEL.VIEW_MERCHANTS_REPORTS,
        claimValue: getPageClaimValues('MERCHANTS_REPORTS', PERMISSIONS.REPORTS.VIEW_MERCHANTS_REPORTS)
    },
    //**STUDENTS */
    {
        label: NAV_MENU.STUDENTS,
        claimLabel: CLAIM_LABEL.VIEW_STUDENTS,
        claimValue: getPageClaimValues('STUDENTS', PERMISSIONS.SCHOOLS.STUDENTS.VIEW_STUDENTS)
    },
    {
        label: NAV_MENU.STUDENTS,
        claimLabel: CLAIM_LABEL.EDIT_CARD_STATUS,
        claimValue: getPageClaimValues('STUDENTS', PERMISSIONS.SCHOOLS.STUDENTS.EDIT_CARD_STATUS)
    },
    {
        label: NAV_MENU.STUDENTS,
        requiredViewPermission: true,
        claimLabel: CLAIM_LABEL.EDIT_STUDENT,
        claimValue: getPageClaimValues('STUDENTS', PERMISSIONS.SCHOOLS.STUDENTS.EDIT_STUDENT)
    },
    //**CLASS */
    {
        label: NAV_MENU.CLASSES,
        claimLabel: CLAIM_LABEL.VIEW_CLASSES,
        claimValue: getPageClaimValues('CLASSES', PERMISSIONS.SCHOOLS.CLASSES.VIEW_CLASSES)
    },
    {
        label: NAV_MENU.CLASSES,
        requiredViewPermission: true,
        claimLabel: CLAIM_LABEL.ACTIONS_CLASS_DETAILS,
        claimValue: getPageClaimValues('CLASSES', PERMISSIONS.SCHOOLS.CLASSES.ACTIONS_CLASS_DETAILS)
    },
    {
        label: NAV_MENU.CLASSES,
        claimLabel: CLAIM_LABEL.ADD_CLASS,
        claimValue: getPageClaimValues('CLASSES', PERMISSIONS.SCHOOLS.CLASSES.ADD_CLASS)
    },
    {
        label: NAV_MENU.CLASSES,
        claimLabel: CLAIM_LABEL.EDIT_REGULATIONS,
        claimValue: getPageClaimValues('CLASSES', PERMISSIONS.SCHOOLS.CLASSES.EDIT_REGULATIONS)
    },
    //**ATTENDANCE LOGS */
    {
        label: NAV_MENU.ATTENDANCE,
        claimLabel: CLAIM_LABEL.VIEW_LOGS,
        claimValue: getPageClaimValues('ATTENDANCE', PERMISSIONS.SCHOOLS.ATTENDANCE.VIEW_LOGS)
    },
    //**DISMISSAL CALL */
    {
        label: NAV_MENU.DISMISSAL_CALL,
        claimLabel: CLAIM_LABEL.VIEW_DISMISSAL_CALL,
        claimValue: getPageClaimValues('DISMISSAL_CALL', PERMISSIONS.SCHOOLS.DISMISSAL_CALL.VIEW_DISMISSAL_CALL)
    },
    //**CALL LOGS */
    {
        label: NAV_MENU.DISMISSAL_CALL_LOGS,
        claimLabel: CLAIM_LABEL.VIEW_LOGS,
        claimValue: getPageClaimValues('DISMISSAL_CALL_LOGS', PERMISSIONS.SCHOOLS.DISMISSAL_CALL_LOGS.VIEW_LOGS)
    },
    //**CANTEEN SALES */
    {
        label: NAV_MENU.CANTEEN_SALES,
        claimLabel: CLAIM_LABEL.VIEW_SALES,
        claimValue: getPageClaimValues('CANTEEN_SALES', PERMISSIONS.SCHOOLS.CANTEEN_SALES.VIEW_SALES)
    },
    //**TRANSACTIONS */
    {
        label: NAV_MENU.TRANSACTIONS,
        claimLabel: CLAIM_LABEL.VIEW_TRANSACTIONS,
        claimValue: getPageClaimValues('TRANSACTIONS', PERMISSIONS.TRANSACTIONS.VIEW_TRANSACTIONS)
    },
    //**TRANSFER REQUESTS */
    {
        label: NAV_MENU.TRANSFER_REQUESTS,
        claimLabel: CLAIM_LABEL.VIEW_TRANSFER_REQUESTS,
        claimValue: getPageClaimValues('TRANSFER_REQUESTS', PERMISSIONS.TRANSFER_REQUESTS.VIEW_TRANSFER_REQUESTS)
    },
    //**MONEY REQUESTS */
    {
        label: NAV_MENU.MONEY_REQUESTS,
        claimLabel: CLAIM_LABEL.VIEW_MONEY_REQUESTS,
        claimValue: getPageClaimValues('MONEY_REQUESTS', PERMISSIONS.CANTEENS.MONEY_REQUESTS.VIEW_MONEY_REQUESTS)
    },
    //**FEES */
    {
        label: NAV_MENU.FEES,
        claimLabel: CLAIM_LABEL.VIEW_FEES,
        claimValue: getPageClaimValues('FEES', PERMISSIONS.FEES.VIEW_FEES)
    },
    //**FOOD ITEMS */
    {
        label: NAV_MENU.FOOD_ITEMS,
        claimLabel: CLAIM_LABEL.VIEW_CANTEEN_ITEMS,
        claimValue: getPageClaimValues('FOOD_ITEMS', PERMISSIONS.CANTEENS.FOOD_ITEMS.VIEW_CANTEEN_ITEMS)
    },
    {
        label: NAV_MENU.FOOD_ITEMS,
        claimLabel: CLAIM_LABEL.ADD_FOOD_ITEM,
        claimValue: getPageClaimValues('FOOD_ITEMS', PERMISSIONS.CANTEENS.FOOD_ITEMS.ADD_FOOD_ITEM)
    },
    {
        label: NAV_MENU.FOOD_ITEMS,
        claimLabel: CLAIM_LABEL.EDIT_FOOD_ITEM,
        requiredViewPermission: true,
        claimValue: getPageClaimValues('FOOD_ITEMS', PERMISSIONS.CANTEENS.FOOD_ITEMS.EDIT_FOOD_ITEM)
    },
    {
        label: NAV_MENU.FOOD_ITEMS,
        claimLabel: CLAIM_LABEL.ADD_CUSTOMIZATION,
        claimValue: getPageClaimValues('FOOD_ITEMS', PERMISSIONS.CANTEENS.FOOD_ITEMS.ADD_CUSTOMIZATION)
    },
    //**FOOD MENU */
    {
        label: NAV_MENU.FOOD_MENU,
        claimLabel: CLAIM_LABEL.VIEW_SCHOOL_FOOD_MENU,
        claimValue: getPageClaimValues('FOOD_MENU', PERMISSIONS.CANTEENS.FOOD_MENU.VIEW_SCHOOL_FOOD_MENU)
    },
    {
        label: NAV_MENU.FOOD_MENU,
        requiredViewPermission: true,
        claimLabel: CLAIM_LABEL.EDIT_SCHOOL_FOOD_MENU,
        claimValue: getPageClaimValues('FOOD_MENU', PERMISSIONS.CANTEENS.FOOD_MENU.EDIT_SCHOOL_FOOD_MENU)
    },
    {
        label: NAV_MENU.FOOD_MENU,
        claimLabel: CLAIM_LABEL.ADD_INVENTORY,
        claimValue: getPageClaimValues('FOOD_MENU', PERMISSIONS.CANTEENS.FOOD_MENU.ADD_INVENTORY)
    },
    {
        label: NAV_MENU.FOOD_MENU,
        claimLabel: CLAIM_LABEL.EDIT_INVENTORY,
        claimValue: getPageClaimValues('FOOD_MENU', PERMISSIONS.CANTEENS.FOOD_MENU.EDIT_INVENTORY)
    },
    {
        label: NAV_MENU.FOOD_MENU,
        claimLabel: CLAIM_LABEL.APPLY_QUANTITY_TO_ALL,
        claimValue: getPageClaimValues('FOOD_MENU', PERMISSIONS.CANTEENS.FOOD_MENU.APPLY_QUANTITY_TO_ALL)
    },
    {
        label: NAV_MENU.FOOD_MENU,
        claimLabel: CLAIM_LABEL.RESET_PRICES,
        claimValue: getPageClaimValues('FOOD_MENU', PERMISSIONS.CANTEENS.FOOD_MENU.RESET_PRICES)
    },
    {
        label: NAV_MENU.FOOD_MENU,
        claimLabel: CLAIM_LABEL.CUSTOMIZED_COLUMNS,
        claimValue: getPageClaimValues('FOOD_MENU', PERMISSIONS.CANTEENS.FOOD_MENU.CUSTOMIZED_COLUMNS)
    },
    {
        label: NAV_MENU.FOOD_MENU,
        claimLabel: CLAIM_LABEL.ADD_FOOD_ITEMS,
        claimValue: getPageClaimValues('FOOD_MENU', PERMISSIONS.CANTEENS.FOOD_MENU.ADD_FOOD_ITEMS)
    },
    {
        label: NAV_MENU.FOOD_MENU,
        claimLabel: CLAIM_LABEL.DELETE_FOOD_ITEMS,
        claimValue: getPageClaimValues('FOOD_MENU', PERMISSIONS.CANTEENS.FOOD_MENU.DELETE_FOOD_ITEMS)
    },
    //**FOOD ORDERS */
    {
        label: NAV_MENU.FOOD_ORDERS,
        claimLabel: CLAIM_LABEL.VIEW_CANTEEN_ORDERS,
        claimValue: getPageClaimValues('FOOD_ORDERS', PERMISSIONS.CANTEENS.FOOD_ORDERS.VIEW_CANTEEN_ORDERS)
    },
    {
        label: NAV_MENU.FOOD_ORDERS,
        claimLabel: CLAIM_LABEL.CHANGE_ORDER_STATUS,
        claimValue: getPageClaimValues('FOOD_ORDERS', PERMISSIONS.CANTEENS.FOOD_ORDERS.CHANGE_ORDER_STATUS)
    },
    {
        label: NAV_MENU.FOOD_ORDERS,
        claimLabel: CLAIM_LABEL.MARK_ALL_ORDERS_DELIVERED,
        claimValue: getPageClaimValues('FOOD_ORDERS', PERMISSIONS.CANTEENS.FOOD_ORDERS.MARK_ALL_ORDERS_DELIVERED)
    },
    {
        label: NAV_MENU.FOOD_ORDERS,
        claimLabel: CLAIM_LABEL.MARK_ALL_ORDERS_REJECTED,
        claimValue: getPageClaimValues('FOOD_ORDERS', PERMISSIONS.CANTEENS.FOOD_ORDERS.MARK_ALL_ORDERS_REJECTED)
    },
    //**COUPONS */
    {
        label: NAV_MENU.COUPONS,
        claimLabel: CLAIM_LABEL.VIEW_COUPONS,
        claimValue: getPageClaimValues('COUPONS', PERMISSIONS.CANTEENS.COUPONS.VIEW_COUPONS)
    },
    {
        label: NAV_MENU.COUPONS,
        claimLabel: CLAIM_LABEL.CREATE_COUPON,
        claimValue: getPageClaimValues('COUPONS', PERMISSIONS.CANTEENS.COUPONS.CREATE_COUPON)
    },
    {
        label: NAV_MENU.COUPONS,
        requiredViewPermission: true,
        claimLabel: CLAIM_LABEL.EDIT_COUPON,
        claimValue: getPageClaimValues('COUPONS', PERMISSIONS.CANTEENS.COUPONS.EDIT_COUPON)
    },
    {
        label: NAV_MENU.COUPONS,
        claimLabel: CLAIM_LABEL.PUBLISH_UNPUBLISH_COUPON,
        claimValue: getPageClaimValues('COUPONS', PERMISSIONS.CANTEENS.COUPONS.PUBLISH_UNPUBLISH_COUPON)
    },
    //**COUPON ORDERS */
    {
        label: NAV_MENU.COUPON_ORDERS,
        claimLabel: CLAIM_LABEL.VIEW_COUPON_ORDERS,
        claimValue: getPageClaimValues('COUPON_ORDERS', PERMISSIONS.CANTEENS.COUPON_ORDERS.VIEW_COUPON_ORDERS)
    },
    //** MERCHANT ITEMS */
    {
        label: NAV_MENU.MERCHANT_ITEMS,
        claimLabel: CLAIM_LABEL.VIEW_ITEMS,
        claimValue: getPageClaimValues('MERCHANT_ITEMS', PERMISSIONS.MERCHANTS.MERCHANT_ITEMS.VIEW_ITEMS)
    },
    {
        label: NAV_MENU.MERCHANT_ITEMS,
        claimLabel: CLAIM_LABEL.ADD_ITEM,
        claimValue: getPageClaimValues('MERCHANT_ITEMS', PERMISSIONS.MERCHANTS.MERCHANT_ITEMS.ADD_ITEM)
    },
    {
        label: NAV_MENU.MERCHANT_ITEMS,
        claimLabel: CLAIM_LABEL.EDIT_ITEM_STATUS,
        claimValue: getPageClaimValues('MERCHANT_ITEMS', PERMISSIONS.MERCHANTS.MERCHANT_ITEMS.EDIT_ITEM_STATUS)
    },
    {
        label: NAV_MENU.MERCHANT_ITEMS,
        requiredViewPermission: true,
        claimLabel: CLAIM_LABEL.EDIT_ITEM,
        claimValue: getPageClaimValues('MERCHANT_ITEMS', PERMISSIONS.MERCHANTS.MERCHANT_ITEMS.EDIT_ITEM)
    },
    {
        label: NAV_MENU.MERCHANT_ITEMS,
        claimLabel: CLAIM_LABEL.ADD_CUSTOMIZATION,
        claimValue: getPageClaimValues('MERCHANT_ITEMS', PERMISSIONS.MERCHANTS.MERCHANT_ITEMS.ADD_CUSTOMIZATION)
    },
    //** CATALOGUE */
    {
        label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES,
        claimLabel: CLAIM_LABEL.VIEW_CATALOGUE,
        claimValue: getPageClaimValues('MERCHANT_PRODUCT_CATALOGUES', PERMISSIONS.MERCHANTS.MERCHANT_PRODUCT_CATALOGUES.VIEW_CATALOGUE)
    },
    {
        label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES,
        claimLabel: CLAIM_LABEL.CREATE_BRANCH_CATALOGUE,
        claimValue: getPageClaimValues('MERCHANT_PRODUCT_CATALOGUES', PERMISSIONS.MERCHANTS.MERCHANT_PRODUCT_CATALOGUES.CREATE_BRANCH_CATALOGUE)
    },
    {
        label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES,
        requiredViewPermission: true,
        claimLabel: CLAIM_LABEL.EDIT_BRANCH_CATALOGUE,
        claimValue: getPageClaimValues('MERCHANT_PRODUCT_CATALOGUES', PERMISSIONS.MERCHANTS.MERCHANT_PRODUCT_CATALOGUES.EDIT_BRANCH_CATALOGUE)
    },
    {
        label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES,
        claimLabel: CLAIM_LABEL.ADD_INVENTORY,
        claimValue: getPageClaimValues('MERCHANT_PRODUCT_CATALOGUES', PERMISSIONS.MERCHANTS.MERCHANT_PRODUCT_CATALOGUES.ADD_INVENTORY)
    },
    {
        label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES,
        claimLabel: CLAIM_LABEL.EDIT_INVENTORY,
        claimValue: getPageClaimValues('MERCHANT_PRODUCT_CATALOGUES', PERMISSIONS.MERCHANTS.MERCHANT_PRODUCT_CATALOGUES.EDIT_INVENTORY)
    },
    {
        label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES,
        claimLabel: CLAIM_LABEL.CHANGE_QUANTITY,
        claimValue: getPageClaimValues('MERCHANT_PRODUCT_CATALOGUES', PERMISSIONS.MERCHANTS.MERCHANT_PRODUCT_CATALOGUES.CHANGE_QUANTITY)
    },
    {
        label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES,
        claimLabel: CLAIM_LABEL.RESET_PRICES,
        claimValue: getPageClaimValues('MERCHANT_PRODUCT_CATALOGUES', PERMISSIONS.MERCHANTS.MERCHANT_PRODUCT_CATALOGUES.RESET_PRICES)
    },
    {
        label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES,
        claimLabel: CLAIM_LABEL.CUSTOMIZED_COLUMNS,
        claimValue: getPageClaimValues('MERCHANT_PRODUCT_CATALOGUES', PERMISSIONS.MERCHANTS.MERCHANT_PRODUCT_CATALOGUES.CUSTOMIZED_COLUMNS)
    },
    {
        label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES,
        claimLabel: CLAIM_LABEL.ADD_ITEMS,
        claimValue: getPageClaimValues('MERCHANT_PRODUCT_CATALOGUES', PERMISSIONS.MERCHANTS.MERCHANT_PRODUCT_CATALOGUES.ADD_ITEMS)
    },
    {
        label: NAV_MENU.MERCHANT_PRODUCT_CATALOGUES,
        claimLabel: CLAIM_LABEL.DELETE_ITEMS,
        claimValue: getPageClaimValues('MERCHANT_PRODUCT_CATALOGUES', PERMISSIONS.MERCHANTS.MERCHANT_PRODUCT_CATALOGUES.DELETE_ITEMS)
    },
    //** MERCHANT ORDERS */
    {
        label: NAV_MENU.MERCHANT_ORDERS,
        claimLabel: CLAIM_LABEL.VIEW_ORDERS,
        claimValue: getPageClaimValues('MERCHANT_ORDERS', PERMISSIONS.MERCHANTS.MERCHANT_ORDERS.VIEW_ORDERS)
    },
    //** MERCHANT EVENTS */
    {
        label: NAV_MENU.MERCHANT_EVENTS,
        claimLabel: CLAIM_LABEL.VIEW_EVENTS,
        claimValue: getPageClaimValues('MERCHANT_EVENTS', PERMISSIONS.MERCHANTS.MERCHANT_EVENTS.VIEW_EVENTS)
    },
    {
        label: NAV_MENU.MERCHANT_EVENTS,
        claimLabel: CLAIM_LABEL.CREATE_EVENT,
        claimValue: getPageClaimValues('MERCHANT_EVENTS', PERMISSIONS.MERCHANTS.MERCHANT_EVENTS.CREATE_EVENT)
    },
    {
        label: NAV_MENU.MERCHANT_EVENTS,
        claimLabel: CLAIM_LABEL.EDIT_EVENT,
        claimValue: getPageClaimValues('MERCHANT_EVENTS', PERMISSIONS.MERCHANTS.MERCHANT_EVENTS.EDIT_EVENT)
    },
    //** MERCHANT EVENT SALES */
    {
        label: NAV_MENU.MERCHANT_EVENT_SALES,
        claimLabel: CLAIM_LABEL.VIEW_SALES,
        claimValue: getPageClaimValues('MERCHANT_EVENT_SALES', PERMISSIONS.MERCHANTS.MERCHANT_EVENT_SALES.VIEW_SALES)
    },
    //** NOTIFICATIONS */
    {
        label: NAV_MENU.PUSH_NOTIFICATION,
        claimLabel: CLAIM_LABEL.VIEW_NOTIFICATION,
        claimValue: getPageClaimValues('NOTIFICATIONS', PERMISSIONS.NOTIFICATIONS.VIEW_NOTIFICATION)
    },
    {
        label: NAV_MENU.NOTIFICATION_HX,
        claimLabel: CLAIM_LABEL.VIEW_HISTORY,
        claimValue: getPageClaimValues('NOTIFICATIONS', PERMISSIONS.NOTIFICATIONS.VIEW_HISTORY)
    },
    //** BUSINESS STORE */
    {
        label: NAV_MENU.BUSINESS_STORE,
        claimLabel: CLAIM_LABEL.VIEW_STORE_ITEMS,
        claimValue: getPageClaimValues('BUSINESS_STORE', PERMISSIONS.BUSINESS_STORE.VIEW_STORE_ITEMS)
    },
    {
        label: NAV_MENU.BUSINESS_STORE,
        claimLabel: CLAIM_LABEL.BUY_ITEMS,
        claimValue: getPageClaimValues('BUSINESS_STORE', PERMISSIONS.BUSINESS_STORE.BUY_ITEMS)
    },
    {
        label: NAV_MENU.BUSINESS_STORE,
        claimLabel: CLAIM_LABEL.VIEW_ORDERS,
        claimValue: getPageClaimValues('BUSINESS_ORDERS', PERMISSIONS.BUSINESS_ORDERS.ORDERS)
    },
]

export const SCHOOL_CLAIM_LABELS = getClaimLabelsByType(IUserType.School);
export const CANTEEN_CLAIM_LABELS = getClaimLabelsByType(IUserType.Canteen);
export const MERCHANT_CLAIM_LABELS = getClaimLabelsByType(IUserType.Merchant);

export const SCHOOL_CLAIMS = getClaimValuesByType(IUserType.School);
export const CANTEEN_CLAIMS = getClaimValuesByType(IUserType.Canteen);
export const MERCHANT_CLAIMS = getClaimValuesByType(IUserType.Merchant);