import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { AppComponent } from '@portal/app.component';
import { ISchool } from '@portal/school/models/school.model';

import { ComponentBase } from '@portal/shared/components/component-base';
import { ConfirmDialogComponent } from '@portal/shared/components/confirm-dialog/confirm-dialog.component';
import { allowedSectionOptions } from '@portal/shared/constants/sections';
import { GetDisplayDate } from '@portal/shared/functions/get-display-date';
import { removeNullOrEmpty } from '@portal/shared/functions/remove-null-or-empty';
import { isNullOrEmptyString } from '@portal/shared/functions/string.function';
import { IAdultSignupRequest, IAdultSignupRequestDependent } from '@portal/shared/models/adult-signup-request.model';
import { IApiResult } from '@portal/shared/models/api-result.model';
import { ISelectOption } from '@portal/shared/models/select-option.model';
import { ITranslation } from '@portal/shared/models/translation.model';
import { DefaultLanguage } from '@portal/shared/services/language/language.model';
import { SchoolService } from '@portal/school/services/school.service';
import { ScreenResolutionService } from '@portal/shared/services/screen-resolution.service';
import { ConditionalValidator } from '@portal/shared/validators/conditional.validator';
import { environment } from 'src/environments/environment';
import { TitleCasePipe } from '@angular/common';

interface CustomSelectOption {
    value: string;
    label: string;
    translations: ITranslation;
}
const DATE_FORMAT = 'YYYY-MM-DD';
@Component({
    selector: 'app-sign-up-adult-form',
    templateUrl: './sign-up-adult-form.component.html',
    styleUrls: ['./sign-up-adult-form.component.scss'],
})
export class SignUpAdultFormComponent extends ComponentBase {
    @Input() signupRequest!: IAdultSignupRequest;
    @Input() isLoading = false;
    @Input() useNewSignUpForm = false;
    @Output() signUp = new EventEmitter();
    @Output() approve = new EventEmitter();
    @Output() reject = new EventEmitter();
    @Output() exit = new EventEmitter();

    public app = AppComponent;
    public form: FormGroup | undefined;
    public isEditing = false;

    public captchaRequired = false;
    public captchaSolved = false;
    public minDate = moment('1900-01-01').format(DATE_FORMAT);
    public maxDate = moment().startOf('day').format(DATE_FORMAT);
    public studentIdErrorMessage = '';

    public genderOptions: ISelectOption[] = [
        { value: 'MALE', label: 'Male' },
        { value: 'FEMALE', label: 'Female' },
    ];
    public schoolOptions: CustomSelectOption[] = [];
    public gradesOptions: ISelectOption[][] = [];
    public sectionOptions = allowedSectionOptions;

    public newSignUpSchoolOptions: CustomSelectOption[] = [
        {
            value:'Al Manhal (At Taawaun)',
            label:'',
            translations:{en: "Al Manhal (At Taawaun)", ar: "المنهل (التعاون)"}
        },
        {
            value:'Al Manhal (Al Murabba)',
            label:'',
            translations:{en: "Al Manhal (Al Murabba)", ar: "المنهل (المربع)"}
        },

    ]

    public studentsOrderArray = [
        "First Student",
        "Second Student",
        "Third Student",
        "Fourth Student",
        "Fifth Student",
        "Sixth Student",
        "Seventh Student",
        "Eighth Student",
        "Ninth Student",
        "Tenth Student",
    ]

    public studentExpanded = true;
    public checkboxValue = false;

    public userSchoolNameInput: string[] = [];
    public userGradeInput: string[] = [];
    public userSectionInput: string[] = [];

    dependent = (i: number) => this.dependents.controls[i] as FormGroup;

    constructor(
        private fb: FormBuilder,
        private modalService: NgbModal,
        private schoolService: SchoolService,
        private translateService: TranslateService,
        public screenServ: ScreenResolutionService,
        private titleCasePipe: TitleCasePipe,
    ) {
        super();
    }

    public toggleCheckBox(): void {
        this.checkboxValue = !this.checkboxValue
    }

    public toggleExpanded(): void {
        this.studentExpanded = !this.studentExpanded
    }

    public get width(): number {
        return this.screenServ.width();
    }

    public get currentLanguage(): string {
        return localStorage.getItem('lang') || DefaultLanguage;
    }

    public get nameTranslations() {
        return this.form?.get('nameTranslations') as FormGroup;
    }

    public get dependents(): FormArray {
        return this.form?.get('dependents') as FormArray;
    }

    public get disableForm(): boolean {
        return this.isLoading || !this.requestPending;
    }

    public get requestPending(): boolean {
        if (!this.signupRequest) {
            return true;
        }
        return this.signupRequest?.status === 'PENDING';
    }

    public get shouldDisableSignUpButton(): boolean {
        return (!this.form?.valid || (this.captchaRequired && !this.captchaSolved));
    }

    public get duplicateMobileNoMatch(): boolean {
        return !!this.signupRequest?.validations?.duplicateMobileNoMatch;
    }

    public get hasValidationErrors(): boolean {
        if (this.duplicateMobileNoMatch) {
            return true;
        }
        const hasDependantValidations = this.dependents.controls.some(
            (dependentC) =>
                !this.isValidField(dependentC.value, 'nationalId') ||
                !this.isValidField(dependentC.value, 'studentId') ||
                !this.isValidField(dependentC.value, 'school') ||
                !this.isValidField(dependentC.value, 'grade') ||
                !this.isValidField(dependentC.value, 'section')
        );
        return hasDependantValidations;
    }

    ngOnInit(): void {
        this.isEditing = this.signupRequest !== undefined;
        this.captchaRequired = !isNullOrEmptyString(environment.recaptcha.siteKey) && !this.isEditing;
        if (this.isEditing) {
            this.getSchools()
            for (let i in this.signupRequest.dependents) {
                this.userSchoolNameInput.push(this.signupRequest.dependents[i].schoolName)
                this.userGradeInput.push(this.signupRequest.dependents[i].grade)
                this.userSectionInput.push(this.signupRequest.dependents[i].section)
            }
        }
        this.initRegistrationForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.signupRequest?.previousValue && changes.signupRequest?.currentValue) {
            this.signupRequest = changes.signupRequest?.currentValue;
            this.initRegistrationForm();
        }
    }

    public isValidField(dependent: any, fieldName: string): boolean {
        if (!this.signupRequest || !fieldName) return true;
        let fieldToCheck = '', validValue = true;
        switch (fieldName) {
            case 'nationalId':
                fieldToCheck = 'duplicateNationalIdMatch';
                validValue = false;
                break;
            case 'studentId':
                fieldToCheck = 'duplicateStudentIdMatch';
                validValue = false;
                break;
            case 'school':
                fieldToCheck = 'schoolMatch';
                break;
            case 'grade':
                fieldToCheck = 'gradeMatch';
                break;
            case 'section':
                fieldToCheck = 'sectionMatch';
                break;
        }
        if (!dependent._id) return true;
        return (
            dependent._id &&
            validValue === !!dependent.validations?.[fieldToCheck]
        );
    }

    public onChanged(event: any, i: number): void {
        const schoolInd = this.schoolOptions.findIndex((schoolName) => schoolName.translations.en === event)
        if (schoolInd > -1) {
            this.getGrades(this.schoolOptions[schoolInd].value, i)
        }
    }

    private getSchools(): void {
        this.schoolService.getSelectSchools(['id', 'translations']).pipe(
            tap((result: IApiResult) => {
                if (result.success) {
                    const filteredSchools = [...(result.schools ?? [])].filter((s: ISchool) => {
                        const schoolName = (s.translations.en ?? '').replace(/\s/g, "").toLowerCase()
                        return (!this.isEditing && this.useNewSignUpForm ? ['almanhal(attaawaun)', 'almanhal(almurabba)'].includes(schoolName) : true)
                    })
                    this.schoolOptions = filteredSchools.map((s) => ({
                        value: s._id,
                        label: s.translations.en,
                        translations: s.translations
                    }));
                    for (let i in this.signupRequest.dependents) {
                        this.onChanged(this.signupRequest.dependents[i].schoolName, +i)
                    }
                }
            }),
            takeUntil(this.destroyed$),
            catchError(() => of())
        ).subscribe();
    };

    private getGrades(school: string, i: number): void {
        this.schoolService.getSelectGrades(school, ['id', 'grade']).pipe(
            tap((res) => {
                this.gradesOptions[i] = (res.grades ?? []).map((item) => ({ value: item._id, label: item.grade }));
            }),
            takeUntil(this.destroyed$),
            catchError(() => of())
        ).subscribe();
    }

    public addDependant = (dependent?: IAdultSignupRequestDependent): void => {
        this.dependents.push(this.createDependantForm(dependent));
    };

    public removeDependant = (dependentIndex: number): void => {
        const modalRef = this.modalService.open(ConfirmDialogComponent, {
            centered: true,
            size: 'md',
        });

        const studentOrder = this.translateService.instant(this.studentsOrderArray[dependentIndex]);
        modalRef.componentInstance.title = this.translateService.instant('SIGNUP_REQUEST.REMOVE_STUDENT', { order: studentOrder });
        modalRef.componentInstance.message = 'SIGNUP_REQUEST.REMOVE_STUDENT_MSG';
        modalRef.componentInstance.buttonText = 'common.YES';
        modalRef.componentInstance.cancelButtonText = 'common.NO';

        modalRef.result.then((res) => {
            if (res === true) {
                this.dependents.removeAt(dependentIndex)
            };
        }).catch();
    };

    public submit(): void {
        if (this.form?.invalid || (this.captchaRequired && !this.captchaSolved)) {
            return;
        }
        this.signUp.emit(this.map());
    }

    public captchaResolved(captchaResponse: any): void {
        this.captchaSolved = captchaResponse && captchaResponse.length > 0;
    }

    public captchaErrored(error: any): void {
        console.error(`reCAPTCHA error encountered`, error);
    }

    private initRegistrationForm(): void {
        this.form = this.fb.group({
            nameTranslations: this.fb.group({
                en: [this.signupRequest?.nameTranslations?.en || ''],
                ar: [
                    this.signupRequest?.nameTranslations?.ar || '',
                    [Validators.required],
                ],
            }),
            mobileNo: [
                this.signupRequest?.mobileNo || '',
                [Validators.required, Validators.maxLength(9), Validators.minLength(9)],
            ],
            dependents: this.fb.array([]),
            validations: [this.signupRequest?.validations],
        }, {
            validators: this.startCorrect('mobileNo')
        });
        if (this.signupRequest) {
            if (this.signupRequest?.dependents?.length) {
                this.signupRequest.dependents.map((dependent: IAdultSignupRequestDependent) => this.addDependant(dependent));
            }
        } else {
            this.addDependant();
        }
    }

    private createDependantForm(dependent?: IAdultSignupRequestDependent) {
        const form = new FormGroup({
            _id: new FormControl(dependent?._id || ''),
            nameTranslations: new FormGroup({
                en: new FormControl(dependent?.nameTranslations.en || ''),
                ar: new FormControl(dependent?.nameTranslations.ar || '', [Validators.required]),
            }),
            mobileNo: new FormControl(dependent?.mobileNo || '', [Validators.pattern(/\b5\d{8}/), Validators.minLength(9)]),
            nationalId: new FormControl(dependent?.nationalId || '', [Validators.required]),
            studentId: new FormControl(
                dependent?.studentId || '',
                [Validators.pattern(new RegExp("^[a-zA-Z0-9 ]+$")), Validators.maxLength(15)]
            ),
            dob: new FormControl(dependent ? GetDisplayDate(dependent?.dob) : '', [Validators.required]),
            sex: new FormControl(dependent?.sex || ''),
            schoolName: new FormControl(dependent?.schoolName || '', [Validators.required]),
            grade: new FormControl(dependent?.grade || '', [Validators.required]),
            section: new FormControl(dependent?.section || '', [Validators.required]),
            validations: new FormControl(dependent?.validations),
        });

        if (!this.useNewSignUpForm) {
            form.controls.studentId.disable();
        } else {
            !this.isEditing ? form.controls.dob.disable() : form.controls.dob.clearValidators();
            form.controls.nationalId.clearValidators();
            const studentIdControl = form.get('studentId') as FormControl;
            const nationalIdControl = form.get('nationalId') as FormControl;
            form.setValidators([
                ConditionalValidator.failWhen(
                    () => {
                        return (isNullOrEmptyString(studentIdControl.value) && isNullOrEmptyString(nationalIdControl.value))
                    },
                    studentIdControl,
                    'STUDENT.required_studentid_or_nationalid'
                )
            ])
            form.updateValueAndValidity();
            this.checkStudentId(studentIdControl);

            form.valueChanges.subscribe(() => {
                this.checkStudentId(studentIdControl);
            })
        }
        form.controls.nationalId.valueChanges.subscribe((value) => {
            if (value) {
                //TODO: implement later
            }
        })
        return form;
    }

    private checkStudentId(studentIdControl: FormControl): void {
        if (studentIdControl.invalid && studentIdControl.errors) {
            Object.entries(studentIdControl.errors).forEach(([key, value]) => {
                if (['pattern', 'maxLength'].includes(key)) {
                    this.studentIdErrorMessage = '';
                    return;
                }
                this.studentIdErrorMessage = value.message || '';
            });
        }
    }

    private map() {
        const formValue: IAdultSignupRequest = this.form?.getRawValue();
        const adultRequest = {
            nameTranslations: {
                ...formValue.nameTranslations,
                en: this.titleCasePipe.transform(formValue.nameTranslations.en)
            },
            mobileNo: formValue.mobileNo,
            dependents: formValue.dependents.map((dependent) => {
                return {
                    _id: dependent._id,
                    nameTranslations: {
                        ...dependent.nameTranslations,
                        en: this.titleCasePipe.transform(dependent.nameTranslations.en)
                    },
                    mobileNo: dependent.mobileNo,
                    nationalId: dependent.nationalId,
                    studentId: dependent.studentId,
                    dob: dependent.dob,
                    sex: dependent.sex,
                    schoolName: dependent.schoolName,
                    grade: dependent.grade,
                    section: dependent.section,
                };
            }),
        };
        return this.isEditing ? adultRequest : removeNullOrEmpty(adultRequest);
    }

    @ViewChild('mobileNo') mobileNo!: ElementRef;

    focusOnElement(element: string) {
        switch (element) {
            case "mobileNo":
                this.mobileNo.nativeElement.focus();
                break
        }
    }

    displayValidation(formName: any) {
        return (this.form?.get(formName)?.invalid && this.form?.get(formName)?.errors && (this.form?.get(formName)?.dirty || this.form?.get(formName)?.touched))
    }

    startCorrect(controlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            if (control.value[0] != "5" && control.value) {
                control.setErrors({ pattern: true })
            }
        };
    }

    langTimeout: any
    englishInput(evt: any, formValidation: any) {
        formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity()
        var theEvent = evt || window.event;
        clearTimeout(this.langTimeout)
        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[a-zA-Z\0-9\!@#\$%\^\&*\)\(+=._-]/;
        if (key == '.' || !regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) {
                theEvent.preventDefault()
                formValidation.markAsDirty()
                formValidation.setErrors({ wrongInput: true })
                this.langTimeout = setTimeout(() => { formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity() }, 3000)
            };
        }
    }
    arabicInput(evt: any, formValidation: any) {
        formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity()
        var theEvent = evt || window.event;
        clearTimeout(this.langTimeout)
        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[\u0600-\u06FF\0-9\!@#\$%\^\&*\)\(+=._-]/;
        if (key == '.' || !regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) {
                theEvent.preventDefault()
                formValidation.markAsDirty()
                formValidation.setErrors({ wrongInput: true })
                this.langTimeout = setTimeout(() => { formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity() }, 3000)
            };
        }
    }


    numberTimeout: any
    validateNumericInput(evt: any, formValidation: any) {
        formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity()
        var theEvent = evt || window.event;
        clearTimeout(this.numberTimeout)
        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (key == '.' || !regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) {
                theEvent.preventDefault();
                formValidation.markAsDirty()
                formValidation.setErrors({ wrongInput: true })
                this.numberTimeout = setTimeout(() => { formValidation.setErrors({ wrongInput: null }); formValidation.updateValueAndValidity() }, 3000)
            }
        }
    }
}
