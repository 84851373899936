import { Injectable } from '@angular/core';
import { ICustomizeColumnConfig } from '../models/customize-column.model';

const LOCAL_STORAGE_KEY = 'columnConfigs';

@Injectable({
    providedIn: 'root',
})
export class CustomizeColumnService {
    columnConfig: ICustomizeColumnConfig;

    constructor() {
        this.columnConfig = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_KEY) || '{}'
        );
        localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(this.columnConfig)
        );
    }

    public getVisibleColumns(pageName: string, _id: string): string[] {
        const customizeColumns = this.columnConfig[pageName];
        if (!customizeColumns) {
            return [];
        }
        return customizeColumns.find((c) => c._id == _id)?.columns ?? [];
    }

    public setVisibleColumns(pageName: string, _id: string, columns: string[]): void {
        const customizeColumns = (this.columnConfig[pageName] || []).filter(c => c._id != _id);
        this.columnConfig = {
            ...this.columnConfig,
            [pageName]: [...customizeColumns, { _id, columns }],
        };
        this.updateConfigToLocalStorage();
    }

    private updateConfigToLocalStorage(): void {
        localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(this.columnConfig)
        );
    }
}
