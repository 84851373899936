import { AbstractControl, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { addErrors, clearError, createCustomError, getValidationError } from './validation-errors.functions';
import { IValidationError } from './validation-error.model';

export abstract class AbstractValidator implements Validator {
    private readonly id: string;
    private controls!: AbstractControl[];

    constructor() {
        this.id = uuid();
    }

    public getValidatorFn(): ValidatorFn {
        return this.validate.bind(this);
    }

    public registerOnValidatorChange(fn: () => void): void {
        // intentionally empty
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        if (this.controls && this.controls.length > 0) {
            const errors = Object.assign(
                {},
                this.controls.map((validatedControl) => this.doValidate(validatedControl))
            );
            if (Object.keys(errors).length > 0) {
                addErrors(control, errors);
            } else {
                clearError(control, this.id);
            }
            return null;
        } else {
            return this.doValidate(control);
        }
    }

    /**
     * Sets the controls to validate against (when validating control groups)
     */
    protected setControls(...controls: AbstractControl[]): void {
        this.controls = controls;
    }

    /**
     * Returns a custom validation error or NULL if no errors for specified control
     * @param control
     */
    abstract getValidationErrors(control: AbstractControl): string | any | null;

    /**
     * Returns the string corresponding to the error returned from getValidationErrors
     * @param errors
     */
    protected getErrorMessage(errors: string | any): string {
        return errors.toString();
    }

    private doValidate(control: AbstractControl): ValidationErrors | null {
        const errors = this.getValidationErrors(control);
        if (errors === null) {
            clearError(control, this.id);
            return null;
        }

        const message = this.getErrorMessage(errors);
        addErrors(control, createCustomError(this.id, <IValidationError>{ message }));
        return getValidationError(control, this.id);
    }
}
