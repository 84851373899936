/* eslint-disable prettier/prettier */
import { ChangeDetectorRef, Component, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ILoginResult } from '@portal/auth/models/login-result.model';
import { AuthService } from '@portal/auth/services/auth.service';
import { ComponentBase } from '@portal/shared/components/component-base';
import { Credentials, IUser } from '@portal/shared/models/user.model';
import { NotifyService } from '@portal/shared/services/notify.service';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserRoles } from '@portal/shared/constants/user-roles.constants';
import { IApiResult } from '@portal/shared/models/api-result.model';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent extends ComponentBase {
    form: FormGroup = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
    });
    inputType: string = 'password';
    visible: boolean = false;
    url: string = '';
    isProduction = environment.production;
    alert: string = '';
    isLoading: boolean = false;

    public static isLogging: EventEmitter<any>;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef,
        private fb: FormBuilder,
        public modalService: NgbModal,
        private notify: NotifyService
    ) {
        super();
        this.url = this.route.snapshot.params.url;

        if (this.authService.isLoggedIn()) {
            this.router.navigate([this.authService.defaultNavigationPath]);
        }
    }

    public onLogin = () => {
        if (!this.FormIsValid) {
            return;
        }

        this.isLoading = true;
        this.alert = '';
        const formValue = this.form.getRawValue();
        this.cd.detectChanges();

        const credentials: Credentials = {
            email: formValue.email,
            password: formValue.password
        };

        this.authService.login(credentials)
            .pipe(
                takeUntil(this.destroyed$),
                catchError((err) => {
                    if (err.status && err.status === 504) {
                        this.alert = 'Please check your internet connection';
                    } else {
                        this.alert = err.error?.msg || err.error?.message;
                    }
                    return of<ILoginResult>();
                })
            )
            .subscribe((res) => {
                const { otpSent, roles } = res;
                if (roles && roles.includes(UserRoles.CALL_SYSTEM_WEB_USER)) {
                    this.authService.setLoginResult(res);
                    this.loadUserProfile();
                    return;
                }
                
                if (otpSent) {
                    this.notify.success('Login', res.msg);
                    this.authService.setUserEmail(credentials.email);
                    this.goToOtpPage();
                    localStorage.setItem('CurrentLogInDate', moment().format('MM-DD-YYYY'));
                } else {
                    this.alert = 'Something went wrong, please try again'; 
                }
            })
            .add(() => {
                this.isLoading = false;
            });
    };

    public goToForgotPassword = () => {
        this.router.navigateByUrl('/backup-auth/backup-forgot-password');
    };

    get FormIsValid() {
        return this.form && this.form.valid;
    }

    private goToOtpPage = () => {
        this.router.navigateByUrl('/backup-login/backup-otp');
    };

    private loadUserProfile = () => {
        this.authService.getUserProfile()
            .pipe(takeUntil(this.destroyed$), catchError(() => of<IApiResult>()))
            .subscribe((res: IApiResult) => {
                if (res.success && res.profile) {
                    this.notify.success('Login', res.msg);

                    LoginPageComponent.isLogging = new EventEmitter();
                    LoginPageComponent.isLogging.subscribe(() => {
                        if (!this.authService.isLoggedIn()) {
                            this.authService.logout();
                        }
                    });

                    this.authService.redirectToPage();
                } else {
                    this.alert = 'Something went wrong, please try again';
                }
            })
            .add(() => this.isLoading = false);
    };
}
