export type CanteenTrTypeEnum =
    'APP_FOOD_ORDER'
    | 'WALK_IN_FOOD_ORDER'
    | 'RETURN_ORDER'
    | 'COUPON_PURCHASE'
    | 'WAKI_COUPON_FEE'
    | 'TRANSFER_FEE'
    | 'TRANSFER_TO_CANTEEN'
    | 'VAT'
    | 'CANTEEN_DIRECT_PAYMENT';
export const CanteenTrTypeEnum = {
    OnlineOrder: 'APP_FOOD_ORDER' as CanteenTrTypeEnum,
    WalkInOrder: 'WALK_IN_FOOD_ORDER' as CanteenTrTypeEnum,
    ReturnOrder: 'RETURN_ORDER' as CanteenTrTypeEnum,
    CouponPurchase: 'COUPON_PURCHASE' as CanteenTrTypeEnum,
    WakiCouponFee: 'WAKI_COUPON_FEE' as CanteenTrTypeEnum,
    TransactionFee: 'TRANSFER_FEE' as CanteenTrTypeEnum,
    TransferToCanteen: 'TRANSFER_TO_CANTEEN' as CanteenTrTypeEnum,
    Vat: 'VAT' as CanteenTrTypeEnum,
    CanteenDirectPayment: 'CANTEEN_DIRECT_PAYMENT' as CanteenTrTypeEnum,
}

export type MerchantTrTypeEnum =
    'APP_ONLINE_ORDER'
    | 'APP_FOOD_ORDER'
    | 'MERCHANT_WALK_IN_ORDER'
    | 'MERCHANT_RETURN_ORDER'
    | 'MERCHANT_TRANSFER_FEE'
    | 'TRANSFER_TO_MERCHANT'
    | 'VAT'
    | 'MERCHANT_DIRECT_PAYMENT';
export const MerchantTrTypeEnum = {
    OnlineOrder: 'APP_ONLINE_ORDER' as MerchantTrTypeEnum,
    WalkInOrder: 'MERCHANT_WALK_IN_ORDER' as MerchantTrTypeEnum,
    ReturnOrder: 'MERCHANT_RETURN_ORDER' as MerchantTrTypeEnum,
    TransactionFee: 'MERCHANT_TRANSFER_FEE' as MerchantTrTypeEnum,
    TransferToCanteen: 'TRANSFER_TO_MERCHANT' as MerchantTrTypeEnum,
    Vat: 'VAT' as MerchantTrTypeEnum,
    MerchantDirectPayment: 'MERCHANT_DIRECT_PAYMENT' as MerchantTrTypeEnum,
}

export type AdultTrTypeEnum =
    'APP_ONLINE_ORDER'
    | 'APP_FOOD_ORDER'
    | 'WALK_IN_FOOD_ORDER'
    | 'RETURN_ORDER'
    | 'COUPON_PURCHASE'
    | 'CARD_ORDER'
    | 'RETURN_CARD_ORDER'
    | 'FUNDING'
    | 'REFUNDING'
    | 'TRANSFER_BETWEEN_USERS'
    | 'ADULT_VAT'
    | 'REVERSE_FROM_DEPENDENT'
    | 'REVERSE_TO_DEPENDENT'
    | 'SETTLEMENT_MINUS'
    | 'DIGITAL_CARD_ORDER';

export const AdultTrTypeEnum = {
    Fund: 'FUNDING' as AdultTrTypeEnum,
    Refund: 'REFUNDING' as AdultTrTypeEnum,
    TransferBetweenUsers: 'TRANSFER_BETWEEN_USERS' as AdultTrTypeEnum,
    OnlineOrder: 'APP_ONLINE_ORDER' as AdultTrTypeEnum,
    WalkInOrder: 'WALK_IN_FOOD_ORDER' as AdultTrTypeEnum,
    ReturnOrder: 'RETURN_ORDER' as AdultTrTypeEnum,
    CouponOrder: 'COUPON_PURCHASE' as AdultTrTypeEnum,
    CardOrder: 'CARD_ORDER' as AdultTrTypeEnum,
    ReturnCardOrder: 'RETURN_CARD_ORDER' as AdultTrTypeEnum,
    AdultVat: 'ADULT_VAT' as AdultTrTypeEnum,
    ReverseFromDependent: 'REVERSE_FROM_DEPENDENT' as AdultTrTypeEnum,
    ReverseToDependent: 'REVERSE_TO_DEPENDENT' as AdultTrTypeEnum,
    SettlementInMinus: 'SETTLEMENT_MINUS' as AdultTrTypeEnum,
    DigitalCardOrder: 'DIGITAL_CARD_ORDER' as AdultTrTypeEnum,
}

export type TransactionTypeEnum =
    'NONE'
    | 'APP_ONLINE_ORDER'
    | 'APP_FOOD_ORDER'
    | 'WALK_IN_FOOD_ORDER'
    | 'RETURN_ORDER'
    | 'COUPON_PURCHASE'
    | 'WAKI_COUPON_FEE'
    | 'TRANSFER_FEE'
    | 'TRANSFER_TO_CANTEEN'
    | 'VAT'
    | 'REVERSE_FROM_DEPENDENT'
    | 'REVERSE_TO_DEPENDENT'
    | 'SETTLEMENT_MINUS'
    | 'CARD_ORDER'
    | 'RETURN_CARD_ORDER'
    | 'TRANSFER_BETWEEN_USERS'
    | 'PARENT_REFUND'
    | 'ADULT_VAT'
    | 'FUNDING'
    | 'REFUNDING'
    | 'DIGITAL_CARD_ORDER'
    | 'MERCHANT_WALK_IN_ORDER'
    | 'MERCHANT_RETURN_ORDER'
    | 'MERCHANT_TRANSFER_FEE'
    | 'TRANSFER_TO_MERCHANT'
    | 'CANTEEN_DIRECT_PAYMENT'
    | 'MERCHANT_DIRECT_PAYMENT'
export const TransactionTypeEnum: { [key: string]: TransactionTypeEnum } = {
    None: 'NONE' as TransactionTypeEnum,
    OnlineOrder: 'APP_FOOD_ORDER' as TransactionTypeEnum,
    WalkInOrder: 'WALK_IN_FOOD_ORDER' as TransactionTypeEnum,
    ReturnOrder: 'RETURN_ORDER' as TransactionTypeEnum,
    CouponOrder: 'COUPON_PURCHASE' as TransactionTypeEnum,
    WakiCouponFee: 'WAKI_COUPON_FEE' as TransactionTypeEnum,
    TransactionFee: 'TRANSFER_FEE' as TransactionTypeEnum,
    TransferToCanteen: 'TRANSFER_TO_CANTEEN' as TransactionTypeEnum,
    Vat: 'VAT' as TransactionTypeEnum,
    Fund: 'FUNDING' as TransactionTypeEnum,
    Refund: 'REFUNDING' as TransactionTypeEnum,
    ParentRefund: 'PARENT_REFUND' as TransactionTypeEnum,
    TransferBetweenUsers: 'TRANSFER_BETWEEN_USERS' as TransactionTypeEnum,
    CardOrder: 'CARD_ORDER' as TransactionTypeEnum,
    ReturnCardOrder: 'RETURN_CARD_ORDER' as TransactionTypeEnum,
    AdultVat: 'ADULT_VAT' as TransactionTypeEnum,
    ReverseFromDependent: 'REVERSE_FROM_DEPENDENT' as TransactionTypeEnum,
    ReverseToDependent: 'REVERSE_TO_DEPENDENT' as TransactionTypeEnum,
    SettlementInMinus: 'SETTLEMENT_MINUS' as TransactionTypeEnum,
    DigitalCardOrder: 'DIGITAL_CARD_ORDER' as TransactionTypeEnum,
    MerchantWalkInOrder: 'MERCHANT_WALK_IN_ORDER' as TransactionTypeEnum,
    MerchantDigitalCardOrder: 'MERCHANT_RETURN_ORDER' as TransactionTypeEnum,
    MerchantTransactionFee: 'MERCHANT_TRANSFER_FEE' as TransactionTypeEnum,
    TransferToMerchant: 'TRANSFER_TO_MERCHANT' as TransactionTypeEnum,
    CanteenDirectPayment: 'CANTEEN_DIRECT_PAYMENT' as TransactionTypeEnum,
    MerchantDirectPayment: 'MERCHANT_DIRECT_PAYMENT' as TransactionTypeEnum,
}

export const TransactionType: { [key: string]: string } = {
    None: '',
    OnlineOrder: 'Online Orders',
    WalkInOrder: 'Walk-in Orders',
    ReturnOrder: 'Returns',
    CouponOrder: 'Coupons Orders',
    WakiCouponFee: 'Coupons Fees',
    TransactionFee: 'Transactions Fees',
    TransferToCanteen: 'Transfers',
    Vat: 'VAT',
    Fund: 'Fund',
    Refund: 'Refund',
    ParentRefund: 'Refund',
    TransferBetweenUsers: 'Transfer between users',
    CardOrder: 'Card Orders',
    ReturnCardOrder: 'Return Card Orders',
    AdultVat: 'VAT',
    ReverseFromDependent: 'Reverse from Dependent',
    ReverseToDependent: 'Reverse to Dependent',
    SettlementInMinus: 'Settlement in Minus',
    DigitalCardOrder: 'Digital Card Order',
    MerchantWalkInOrder: 'Walk-in Orders',
    MerchantDigitalCardOrder: 'Returns',
    MerchantTransactionFee: 'Transactions Fees',
    TransferToMerchant:'Transfers',
    CanteenDirectPayment: 'transaction.trType.CANTEEN_DIRECT_PAYMENT',
    MerchantDirectPayment: 'transaction.trType.MERCHANT_DIRECT_PAYMENT'
}

export const OrderType: { [key: string]: string } = {
    None: '',
    OnlineOrder: 'Online Order',
    WalkInOrder: 'Walk-in Order',
    ReturnOrder: 'Return',
    CouponOrder: 'Coupons Order',
    WakiCouponFee: 'Coupons Fee',
    TransactionFee: 'Transactions Fee',
    TransferToCanteen: 'Transfer',
    Vat: 'VAT',
    Fund: 'Fund',
    Refund: 'Refund',
    ParentRefund: 'Refund',
    TransferBetweenUsers: 'Transfer between user',
    CardOrder: 'Card Order',
    ReturnCardOrder: 'Return Card Order',
    AdultVat: 'VAT',
    ReverseFromDependent: 'Reverse from Dependent',
    ReverseToDependent: 'Reverse to Dependent',
    SettlementInMinus: 'Settlement in Minus',
    DigitalCardOrder: 'Digital Card Order',
    MerchantWalkInOrder: 'Walk-in Order',
    MerchantDigitalCardOrder: 'Return',
    MerchantTransactionFee: 'Transactions Fee',
    TransferToMerchant:'Transfer',
    CanteenDirectPayment: 'Direct Payment',
    MerchantDirectPayment: 'Direct Payment'
}