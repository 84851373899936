import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    public get(key: string) {
        try {
            const data = localStorage.getItem(key);
            if (!data?.length || data === 'undefined') {
                return null;
            }
            return JSON.parse(data);
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public set(key: string, data: any): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }

    public clear(): void {
        localStorage.clear();
    }
}