import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ILoginResult, IOtpLoginResult } from "@portal/auth/models/login-result.model";
import { Credentials, IForgotPassword, ILoginOTP } from "@portal/shared/models/user.model";
import { IApiResult } from "@portal/shared/models/api-result.model";
import { HttpService } from "@portal/shared/services/http/http-service";
import { map } from "rxjs/operators";
import { IAuthLoginService } from "./i-auth-login.service";

@Injectable({
    providedIn: 'root',
})
export class AuthLoginService implements IAuthLoginService {
    constructor(private httpService: HttpService) { }

    public loginByEmail({ email, password }: Credentials): Observable<ILoginResult> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        const credentials = JSON.stringify({ email, password });

        return this.httpService
            .v2Post(`users/login?type=EMAIL`, credentials, headers)
            .pipe(map((response) => <ILoginResult>response));
    }

    public verifyLoginOTP(loginOtp: ILoginOTP): Observable<IOtpLoginResult> {
        return this.httpService
            .v2Post(`users/login-otp-verification`, loginOtp)
            .pipe(map((response) => <IOtpLoginResult>response));
    }

    public resendLoginOTP(loginOtp: ILoginOTP): Observable<IOtpLoginResult> {
        return this.httpService
            .v2Post(`users/resend-otp`, loginOtp)
            .pipe(map((response) => <IOtpLoginResult>response));
    }

    public forgotPassword(request: IForgotPassword, step = '1'): Observable<IApiResult> {
        return this.httpService.v2Patch(`users/forgot-password?step=${step}&type=EMAIL`, request);
    }

    public getMyProfile(): Observable<IApiResult> {
        return this.httpService.v2Get(`users/me`);
    }
}
