import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'tokenMethod', pure: false })
export class TokenMethodPipe implements PipeTransform {

    transform(token: string): string {
        switch (token) {
            case 'FACE': return 'Face ID';
            case 'QR_CODE': return 'QR Code';
            case 'CARD': return 'Card';
            case 'MANUAL': return 'Manual';
            default: return '';
        }
    }
}