import { Injectable } from "@angular/core";
import { IApiResult } from "@portal/shared/models/api-result.model";
import { Observable } from "rxjs";
import { HttpService } from "./http/http-service";

@Injectable({
    providedIn: 'root'
})
export class QRCodeService {
    constructor(private httpService: HttpService) { }

    public updateSettings(studentId: string, payload: any): Observable<IApiResult> {
        return this.httpService.v2Patch(`students/${studentId}`, payload);
    }
}
