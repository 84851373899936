/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

export const SMALL_RESOLUTION = 576;
export const MOBILE_RESOLUTION = 768;
export const TABLET_RESOLUTION = 1280;
export const DESKTOP_RESOLUTION = 1600;

export const COMMON_DESKTOP_RESOLUTIONS = [
    { w: 1280, h: 720 },
    { w: 1440, h: 900 },
    { w: 1536, h: 864 },
    { w: 1920, h: 1080 },
    { w: 1366, h: 768 },
];

export const COMMON_TABLET_RESOLUTIONS = [
    { w: 962, h: 601 },
    { w: 601, h: 962 },
    { w: 800, h: 1280 },
    { w: 1280, h: 800 },
    { w: 768, h: 1024 },
];

export const COMMON_MOBILE_RESOLUTIONS = [
    { w: 360, h: 640 },
    { w: 375, h: 667 },
    { w: 414, h: 896 },
    { w: 360, h: 780 },
    { w: 375, h: 812 },
];

@Injectable({
    providedIn: 'root',
})
export class ScreenResolutionService {
    private win = window;

    constructor(private deviceService: DeviceDetectorService) {}

    isSmallScreen = (): boolean => window.innerWidth <= SMALL_RESOLUTION;
    isMobileResolution = (): boolean => window.innerWidth < MOBILE_RESOLUTION;
    isTabletResolution = (): boolean => window.innerWidth >= MOBILE_RESOLUTION && window.innerWidth <= TABLET_RESOLUTION;
    isDesktopResolution = (): boolean => window.innerWidth > TABLET_RESOLUTION;

    public height = () => window.innerHeight;
    public width = () => window.innerWidth;

    public isDesktop = () => this.width() > 1240 && this.height() > 720;
    public isMobile = () => this.deviceService.isMobile();
    public isTablet = () => this.deviceService.isTablet();

    public isLandscape = (): boolean => !this.isPortrait();
    public isPortrait = (): boolean => this.win.matchMedia && this.win.matchMedia('(orientation: portrait)').matches;

    public testUserAgent = (expression: any): boolean => {
        const nav = this.win.navigator;
        return !!(nav && nav.userAgent && nav.userAgent.indexOf(expression) >= 0);
    }
}
