import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss']
})
export class PrimaryButtonComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() text = 'Apply';
  @Input() addClass:any;
  @Input() style:any;
  @Input() type:string = 'button';
  @Input() isLoading:boolean = false;
  @Output() clickButton = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
