const second = 1000;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;
const defaultCacheTime = 10 * second;
const referenceDataCacheTime = hour;
const contextCacheTime = hour;

const cacheSettings = {
  dateQualifiers: {
    getOnSetDateQualifiers: referenceDataCacheTime,
    getOtherDateQualifiers: referenceDataCacheTime,
  },
  transactionCodes: {
    get: referenceDataCacheTime,
    getActive: referenceDataCacheTime,
  },
  schools: {
    get: referenceDataCacheTime,
    getActive: referenceDataCacheTime,
  },
  canteens: {
    get: referenceDataCacheTime,
    getActive: referenceDataCacheTime,
  },
  merchants: {
    get: referenceDataCacheTime,
    getActive: referenceDataCacheTime,
  },
  clientContext: {
    get: contextCacheTime,
  },
  locations: {
    get: referenceDataCacheTime,
  },
  token: {
    get: minute,
  },
};
export default cacheSettings;
