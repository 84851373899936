/* eslint-disable prettier/prettier */
import { Component } from '@angular/core';
import { AuthService } from '@portal/auth/services/auth.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AppComponent } from '@portal/app.component';
import { translationLang } from '@portal/shared/functions/translate-language';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
    public app = AppComponent;
    public hiddenSidebar: boolean = true;
    private readonly EXCEPT_LINKS = ['/schools/dismissal-call/call-system'];

    constructor(private authService: AuthService, private router: Router) {
        this.router.events.subscribe((e: Event) => {
            if (e instanceof NavigationEnd && e.url) {
                const appPath = e.url.split('?')[0] || '';
                this.hiddenSidebar = this.EXCEPT_LINKS.includes(appPath);
            }
        })
    }

    public toggleSidebar = (): void => {
        AppComponent.showSidebar = !AppComponent.showSidebar;
    };

    public logout = (): void => {
        this.authService.logout();
        this.router.navigateByUrl('login');
    };

    public get contentClass(): string {
        const zhiq = document.getElementById('zsiq_float');
        const addClass = zhiq ? `p-zhiq${AppComponent.isDesktop ? '' : ' pt-5rem'}` : `p-${AppComponent.isDesktop ? '5' : '4 pt-5rem'}`;
        if (this.hiddenSidebar) {
            return AppComponent.isCallPage ? '' : 'p-5 pt-1 ' + 'p-5 full-width';
        } else if (!AppComponent.showSidebar) {
            return addClass + ' full-width';
        } else {
            return addClass + (AppComponent.textDir === 'rtl' ? ' remaining-rtl-width' : ' remaining-width');
        }
    }
}
