/* eslint-disable prettier/prettier */
import { Pipe, PipeTransform } from '@angular/core';
import { pickedByUserType } from '@portal/school/components/student-page/shared/student.constant';

@Pipe({ name: 'calledByUser', pure: false })
export class CalledByUserPipe implements PipeTransform {

    transform(value: string): string {
        return pickedByUserType[value];
    }
}
