import { Injectable } from '@angular/core';
import { RouteAuthGuardService } from '@portal/shared/guards/route-auth-guard.service';
import { UserClientContext } from '@portal/shared/models/user-client-context.model';
import { canViewDashboardStats, hasPermissionToGetUserList } from '@portal/shared/permissions/permission';

@Injectable({
  providedIn: 'root',
})
export class HomeGuardService extends RouteAuthGuardService {
  static canAccess = (context: UserClientContext, isRequired: boolean): boolean => !isRequired ? true : (hasPermissionToGetUserList(context.roles) || canViewDashboardStats(context));

  protected canAccess = (context: UserClientContext): boolean => HomeGuardService.canAccess(context, this.featureFlags.enableUserAccess);
}
