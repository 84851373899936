/* eslint-disable prettier/prettier */
import { HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiResult } from '@portal/shared/models/api-result.model';
import { Observable, of } from 'rxjs';
import { ICanteenFilter } from '../models/canteen-filter.model';
import { ICanteen } from '../models/canteen.model';
import { DEFAULT_SELECT_FIELDS, INTERCEPTOR_SKIP_HEADER, dummyErrorResponse } from '@portal/shared/constants/common.constants';
import { catchError } from 'rxjs/operators';
import { HttpService } from '@portal/shared/services/http/http-service';

@Injectable({
    providedIn: 'root'
})
export class CanteenService {
    constructor(private httpService: HttpService) {}

    public getCanteens = (filter?: ICanteenFilter): Observable<HttpResponse<IApiResult>> => {
        let url = `canteens?`
            .concat('page=', (filter?.page || 1).toString())
            .concat('&limit=', (filter?.limit || 1000).toString());

        if (filter?.search && filter?.search?.length) {
            url = url.concat('&search=', filter.search);
        }

        return this.httpService.v2Get(url, 'response');
    };

    public getCanteen = (canteenId: string): Observable<IApiResult> => {
        return this.httpService.v2Get(`canteens/${canteenId}`);
    };

    public getSelectCanteens = (select: string[] = DEFAULT_SELECT_FIELDS, schoolId?: string): Observable<IApiResult> => {
        // TODO: Handle for the limitation
        let uri = `canteens?limit=100&select=${(select || []).join(',')}`;

        if (schoolId?.length) {
            uri = uri.concat(`&school=${schoolId}`);
        }

        return this.httpService.v2Get(uri).pipe(catchError(() => of<IApiResult>(dummyErrorResponse)));
    };

    public getAllFoodCategories = (canteenId: string, getAll = false): Observable<IApiResult> => {
        let url = `canteens/${canteenId}/food-categories`
        if (getAll) {
            url = url.concat(`?getAll=${getAll}`)
        }
        return this.httpService.v2Get(url).pipe(catchError(() => of<IApiResult>(dummyErrorResponse)));
    }

    public lookupCanteen = (canteenId: string): Observable<IApiResult> => {
        return this.httpService.v2Get(`canteens/lookup?canteenId=${canteenId}`);
    };

    public createCanteen = (canteen: ICanteen, logo?: Blob): Observable<HttpEvent<IApiResult>> => {
        const headers = new HttpHeaders()
        headers.append('Content-Type', 'multipart/form-data');

        const formData = new FormData()
        if (logo) {
            const originalname = `canteenLogo.${logo.type.split('/')[1]}`; // Required by BE
            formData.append('logo', logo, originalname)
        }
        formData.append('postData', JSON.stringify(canteen));

        return this.httpService.v2Post(
            `canteens`,
            formData,
            headers.append(INTERCEPTOR_SKIP_HEADER, ''),
            'events',
            true
        );
    };

    public patchCanteen = (id: string, canteen: ICanteen, logo?: Blob): Observable<HttpEvent<IApiResult>> => {
        const headers = new HttpHeaders()
        headers.append('Content-Type', 'multipart/form-data');

        const formData = new FormData()
        if (logo) {
            const originalname = `canteenLogo.${logo.type.split('/')[1]}`; // Required by BE
            formData.append('logo', logo, originalname)
        }
        formData.append('postData', JSON.stringify(canteen));

        return this.httpService.v2Patch(
            `canteens/${id}`,
            formData,
            headers.append(INTERCEPTOR_SKIP_HEADER, ''),
            'events',
            true
        );
    };
}
