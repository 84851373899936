/* eslint-disable prettier/prettier */
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ISubscriptionPeriod } from '@portal/business-store/business-store-items-page/business-store-items.model';

@Pipe({ name: 'subscriptionPeriodPipe', pure: false })
export class SubscriptionPeriodPipe implements PipeTransform {
    constructor(private trans: TranslateService) { }

    transform(s: ISubscriptionPeriod | undefined): string {
        if (!s) {
            return '';
        }
        const str = s.value + ' ';
        if (s.unit === 'M') {
            return str + this.trans.instant(s.value > 1 ? 'Months' : 'Month')
        }

        if (s.unit === 'Y') {
            return str + this.trans.instant(s.value > 1 ? 'Years' : 'Year')
        }

        return str;
    }
}
