<form *ngIf="form" [formGroup]="form">
    <fieldset [disabled]="disableForm" class="formContainer">
            <div class="expand">
                <div class="infoHeader" data-toggle="no-collapse">
                    <span translate>Adult Information</span>
                </div>
                <div class="inputs">
                    <div class="inputContainer" formGroupName="nameTranslations">
                        <div class="form-group">
                            <label for="ar" translate>Adult Full Name in Arabic</label>
                            <input class="form-control" formControlName="ar" (keypress)="arabicInput($event, form.get('nameTranslations.ar'))"/>
                            <ng-container *ngIf="displayValidation('nameTranslations.ar')">
                                <small class="text-danger"
                                *ngIf="form.get('nameTranslations.ar')?.hasError('required')" translate>
                                Required
                                </small>
                                <small class="text-danger"
                                *ngIf="form.get('nameTranslations.ar')?.hasError('wrongInput')" translate>
                                Keyboard must be in Arabic
                                </small>
                            </ng-container>
                        </div>
                        <div class="form-group">
                            <label for="en" translate>Adult Full Name in English</label>
                            <input class="form-control" formControlName="en" (keypress)="englishInput($event, form.get('nameTranslations.en'))" />
                            <small class="text-danger"
                            *ngIf="form.get('nameTranslations.en')?.hasError('wrongInput')" translate>
                            Keyboard must be in English
                            </small>
                        </div>
                    </div>
                    <div class="inputContainer">
                        <div class="form-group">
                            <label for="mobileNo" translate>Adult Mobile No.</label>
                            <div class="inputGroup mobileNo" (click)="focusOnElement('mobileNo')">
                                <label class="inputGroupAddon">+966</label>
                                <input
                                    #mobileNo
                                    class="form-control addon"
                                    formControlName="mobileNo"
                                    maxLength="9"
                                    (keypress)="validateNumericInput($event, form.get('mobileNo'))" 
                                    placeholder="5x xxx xxxx"
                                    inputmode="numeric"
                                    >
                            </div>
                            <small class="text-danger"
                                *ngIf="duplicateMobileNoMatch" translate>
                                Duplicate Mobile No.
                            </small>
                            <div *ngIf="displayValidation('mobileNo')">
                                <small class="text-danger"
                                    *ngIf="form.get('mobileNo')?.hasError('required')" translate>
                                    Required
                                </small>
                                <small class="text-danger"
                                    *ngIf="form.get('mobileNo')?.hasError('pattern')" translate>
                                    The first number must be 5
                                </small>
                                <small class="text-danger"
                                    *ngIf="form.get('mobileNo')?.hasError('minlength')" translate>
                                    Mobile Number must be 9 digits.
                                </small>
                                <small class="text-danger"
                                    *ngIf="form.get('mobileNo')?.hasError('wrongInput')" translate>
                                    Must be numberic
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="expand">
            <div class="infoHeader" data-toggle="collapse" data-target="#student_info" (click)="toggleExpanded()">
                <span translate>Students Information</span>
                <i class="fas fa-angle-down expandIcon {{studentExpanded && 'openExpand'}}"></i>
            </div>
            <div id="student_info" class="inputs collapse show mt-3">
                <div class="d-flex flex-column gap-3" formArrayName="dependents">
                    <ng-container *ngFor="let dependentC of dependents.controls; let i = index">
                        <div class="studentData mx-2" [formGroup]="dependent(i)">
                            <div class="d-flex justify-content-between align-items-center studentDataHeader">
                                <h6 class="text-center mb-0 " translate>{{studentsOrderArray[i]}}</h6>
                                <button type="button"
                                    class="deleteButton"
                                    (click)="removeDependant(i)" *ngIf="requestPending">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="currentColor" d="M5 3h2a1 1 0 0 0-2 0ZM4 3a2 2 0 1 1 4 0h2.5a.5.5 0 0 1 0 1h-.441l-.443 5.17A2 2 0 0 1 7.623 11H4.377a2 2 0 0 1-1.993-1.83L1.941 4H1.5a.5.5 0 0 1 0-1H4Zm3.5 3a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0V6ZM5 5.5a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 1 0V6a.5.5 0 0 0-.5-.5Z"/></svg>
                                </button>
                            </div>
                            <div class="row">
                                <ng-container formGroupName="nameTranslations">
                                    <div class="form-group col-12 col-sm-4">
                                        <label for="ar{{i}}" translate>Student Full Name in Arabic</label>
                                        <input class="form-control" formControlName="ar" (keypress)="arabicInput($event, dependentC.get('nameTranslations.ar'))" />
                                        <div class="text-danger" *ngIf="dependentC.get('nameTranslations.ar')?.dirty || dependentC.get('nameTranslations.ar')?.touched">
                                            <small *ngIf="dependentC.get('nameTranslations.ar')?.hasError('required')">
                                                {{'Required' | translate}}
                                            </small>
                                            <small  *ngIf="dependentC.get('nameTranslations.ar')?.hasError('wrongInput')">
                                                {{'Keyboard must be in Arabic' | translate}}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="form-group col-12 col-sm-4">
                                        <label for="en{{i}}" translate>Student Full Name in English</label>
                                        <input class="form-control" formControlName="en" (keypress)="englishInput($event, dependentC.get('nameTranslations.en'))" />
                                        
                                        <div class="text-danger" *ngIf="dependentC.get('nameTranslations.en')?.hasError('wrongInput')">
                                            <small>{{'Keyboard must be in English' | translate}}</small>
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngIf="width > 575" class="form-group col-12 col-sm-4 ">
                                    <label for="mobileNo{{i}}" translate>Student Mobile No. (if exists)</label>
                                    <div class="inputGroup mobileNo">
                                        <label class="inputGroupAddon">+966</label>
                                        <input
                                            class="form-control addon"
                                            formControlName="mobileNo"
                                            maxLength="9"
                                            placeholder="5x xxx xxxx"
                                            inputmode="numeric"
                                            >
                                    </div>
                                    <div class="text-danger d-flex flex-column">
                                        <small *ngIf="dependentC.get('mobileNo')?.hasError('minlength')" translate>
                                            {{'VALIDATORS.MSG.MUST_9_DIGITS'}}
                                        </small>
                                        <small *ngIf="dependentC.get('mobileNo')?.hasError('pattern')" translate>
                                            {{'VALIDATORS.MSG.MOBILE_START_WITH_5'}}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12" [ngClass]="isEditing ? 'col-sm-3' : 'col-sm-4'">
                                    <label for="nationalId{{i}}" [class.has-required]="!useNewSignUpForm" translate>Student National/Iqama ID</label>
                                    <input type="text" class="form-control" formControlName="nationalId" />
                                    <app-input-required-error-message [control]="dependentC.get('nationalId') || undefined">
                                    </app-input-required-error-message>
                                    <div class="invalid-feedback" *ngIf="!isValidField(dependentC.value,'nationalId')">
                                        {{'SIGNUP_REQUEST.DUPLICATE_NID' | translate}}
                                    </div>
                                </div>
                                <div class="form-group col-12" *ngIf="useNewSignUpForm" [ngClass]="isEditing ? 'col-sm-3' : 'col-sm-4'">
                                    <label for="studentId{{i}}" translate>{{'STUDENT.student_id'}}</label>
                                    <input type="text" maxlength="15" class="form-control" formControlName="studentId">
                        
                                    <div class="invalid-feedback" *ngIf="!isValidField(dependentC.value,'studentId')">
                                        {{'SIGNUP_REQUEST.DUPLICATE_SID' | translate}}
                                    </div>
                                    <div class="text-danger d-flex flex-column"
                                        *ngIf="dependentC.get('studentId')?.dirty || dependentC.get('studentId')?.touched">
                                        <small *ngIf="dependentC.get('studentId')?.invalid && studentIdErrorMessage?.length">
                                            {{ studentIdErrorMessage | translate }}
                                        </small>
                                        <small *ngIf="dependentC.get('studentId')?.hasError('pattern')">
                                            {{'STUDENT.invalid_pattern' | translate}}
                                        </small>
                                    </div>
                                </div>


                                <div class="form-group col-12" *ngIf="!useNewSignUpForm || isEditing"  [ngClass]="isEditing ? 'col-sm-3' : 'col-sm-4'">
                                    <label for="dob{{i}}" [class.has-required]="!useNewSignUpForm" translate>Student DOB</label>
                                    <div class="inputGroup">
                                        <span class="inputGroupAddon dob">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M6.94 2c.416 0 .753.324.753.724v1.46c.668-.012 1.417-.012 2.26-.012h4.015c.842 0 1.591 0 2.259.013v-1.46c0-.4.337-.725.753-.725s.753.324.753.724V4.25c1.445.111 2.394.384 3.09 1.055c.698.67.982 1.582 1.097 2.972L22 9H2v-.724c.116-1.39.4-2.302 1.097-2.972c.697-.67 1.645-.944 3.09-1.055V2.724c0-.4.337-.724.753-.724Z"/><path fill="currentColor" d="M22 14v-2c0-.839-.004-2.335-.017-3H2.01c-.013.665-.01 2.161-.01 3v2c0 3.771 0 5.657 1.172 6.828C4.343 22 6.228 22 10 22h4c3.77 0 5.656 0 6.828-1.172C22 19.658 22 17.772 22 14Z" opacity=".5"/><path fill="currentColor" d="M18 17a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z"/></svg>
                                        </span>
                                        <span class="input-group">
                                            <input formControlName="dob" type="date" [min]="minDate" [max]="maxDate" class="form-control addon" onkeydown="() => {return false}" />
                                        </span>
                                    </div>
                                    <app-input-required-error-message
                                    [control]="dependentC.get('dob') || undefined">
                                    </app-input-required-error-message>
                                </div>
                                <div class="form-group col-12" [ngClass]="isEditing ? 'col-sm-3' : 'col-sm-4'">
                                    <label for="sex{{i}}" translate>Student Gender</label>
                                    <ng-select formControlName="sex" class="w-100" [clearable]="false"
                                        [readonly]="disableForm">
                                        <ng-option *ngFor="let item of genderOptions" [value]="item.value">
                                            {{ item.label | translate }}
                                        </ng-option>
                                    </ng-select>
                                    <app-input-required-error-message [control]="dependentC.get('sex') || undefined">
                                    </app-input-required-error-message>
                                </div>
                            </div>
                            <div *ngIf="signupRequest?.status === 'PENDING'" class="row">
                                <div class="form-group col-12 col-sm-4 ">
                                    <label for="schoolName{{i}}" translate>School Name</label>
                                    <ng-select
                                        formControlName="schoolName"
                                        class="w-100"
                                        [clearable]="true"
                                        [readonly]="disableForm"
                                        (change)="onChanged($event, i)" >
                                        <ng-option *ngFor="let item of schoolOptions" [value]="item.translations.en">
                                            {{ item.translations | translation }}
                                        </ng-option>
                                    </ng-select>
                                    <app-input-required-error-message [control]="dependentC.get('schoolName') || undefined">
                                    </app-input-required-error-message>
                                </div>
                                <div class="form-group col-12 col-sm-4 ">
                                    <label for="grade{{i}}" translate>Class</label>
                                    <ng-select
                                        formControlName="grade"
                                        class="w-100" 
                                        [readonly]="disableForm"
                                        [clearable]="true">
                                        <ng-option *ngFor="let item of gradesOptions[i]" [value]="item.label">
                                            {{ item.label }}
                                        </ng-option>
                                    </ng-select>
                                    <app-input-required-error-message [control]="dependentC.get('grade') || undefined">
                                    </app-input-required-error-message>
                                </div>
                                <div class="form-group col-12 col-sm-4 ">
                                    <label for="section{{i}}" translate>Section</label>
                                    <input class="form-control" formControlName="section" />
                                    <app-input-required-error-message [control]="dependentC.get('section') || undefined">
                                    </app-input-required-error-message>
                                    <div *ngIf="!isValidField(dependentC.value,'section')" class="invalid-feedback" translate>
                                        Section does not match
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div *ngIf="this.signupRequest &&  (this.signupRequest.status === 'PENDING' || this.signupRequest.status === 'REJECTED')" class="form-group col-12 col-sm-4 ">
                                    <label translate>User School Name Input</label>
                                    <input class="form-control" [value]="userSchoolNameInput[i] || ''" [disabled]="true" />
                                </div>
                                <div *ngIf="!this.signupRequest || this.signupRequest.status === 'APPROVED'" class="form-group col-12 col-sm-4 ">
                                    <label for="schoolName{{i}}" translate>School Name</label>
                                    <ng-select
                                    *ngIf="useNewSignUpForm"
                                    formControlName="schoolName"
                                    class="w-100"
                                    [clearable]="false"
                                    [readonly]="disableForm"
                                    (change)="onChanged($event, i)" >
                                    <ng-option *ngFor="let item of newSignUpSchoolOptions" [value]="item.translations.en">
                                        {{ item.translations | translation }}
                                    </ng-option>
                                </ng-select>
                                    <input *ngIf="!useNewSignUpForm" class="form-control" formControlName="schoolName" />
                                    <app-input-required-error-message [control]="dependentC.get('schoolName') || undefined">
                                    </app-input-required-error-message>
                                </div>
                                <div *ngIf="this.signupRequest && (this.signupRequest.status === 'PENDING' || this.signupRequest.status === 'REJECTED')" class="form-group col-12 col-sm-4 ">
                                    <label translate>User Class Input</label>
                                    <input class="form-control" [value]="userGradeInput[i] || ''" [disabled]="true" />
                                </div>
                                <div *ngIf="!this.signupRequest || this.signupRequest.status === 'APPROVED'" class="form-group col-12 col-sm-4 ">
                                    <label for="grade{{i}}" translate>Class</label>
                                    <input class="form-control" formControlName="grade" />
                                    <app-input-required-error-message [control]="dependentC.get('grade') || undefined">
                                    </app-input-required-error-message>
                                </div>
                                <div *ngIf="this.signupRequest && (this.signupRequest.status === 'PENDING' || this.signupRequest.status === 'REJECTED')" class="form-group col-12 col-sm-4 ">
                                    <label translate>User Section Input</label>
                                    <input class="form-control" [value]="userSectionInput[i] || ''" [disabled]="true" />
                                </div>
                                <div *ngIf="!this.signupRequest || this.signupRequest.status === 'APPROVED'" class="form-group col-12 col-sm-4 ">
                                    <label for="section{{i}}" translate>Section</label>
                                    <input class="form-control" formControlName="section" />
                                    <app-input-required-error-message [control]="dependentC.get('section') || undefined">
                                    </app-input-required-error-message>
                                </div>
                                <div *ngIf="width < 575" class="form-group col-12 col-sm-4 ">
                                    <label for="mobileNo{{i}}" translate>Student Mobile No. (if exists)</label>
                                    <div class="inputGroup mobileNo">
                                        <label class="inputGroupAddon">+966</label>
                                        <input
                                            class="form-control addon"
                                            formControlName="mobileNo"
                                            maxLength="9"
                                            placeholder="5x xxx xxxx"
                                            inputmode="numeric">
                                    </div>
                                    <div class="text-danger d-flex flex-column">
                                        <small *ngIf="dependentC.get('mobileNo')?.hasError('pattern')" translate>
                                            {{'VALIDATORS.MSG.MOBILE_START_WITH_5'}}
                                        </small>
                                        <small *ngIf="dependentC.get('mobileNo')?.hasError('minlength')" translate>
                                            {{'VALIDATORS.MSG.MOBILE_DIGITS'}}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="d-flex">
                        <app-primary-button text="Add Another Student?" addClass="bg-secondary text-primary mx-2 px-3 w-auto"
                        (clickButton)="addDependant()" *ngIf="requestPending && dependents.controls.length < 10"></app-primary-button>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>
<div class="row mt-4" *ngIf="captchaRequired">
    <div class="d-flex justify-content-center">
        <re-captcha
            id="recaptcha"
            name="recaptcha"
            (resolved)="captchaResolved($event)"
            (error)="captchaErrored($event)"
            errorMode="handled">
        </re-captcha>
    </div>
</div>
<div class="d-flex justify-content-center my-4 fw-bolder gap-2 mw-100" *ngIf="!signupRequest">
    <input name="terms" type="checkbox" class="form-check-input checkbox" (change)="toggleCheckBox()">
    <label class="termsText" for="terms"> <span translate>I acknowledge that I have read and accepted the </span><a target="_blank" [href]="currentLanguage === 'ar' ? 'https://waki.sa/terms-condition-ar/' : 'https://waki.sa/en/terms-conditions/'" translate>terms and conditions</a>.</label>
 </div>
<div class="row sticky_footer" [ngClass]="{ 'mt-5 mb-5': signupRequest, 'mt-2': !signupRequest }">
    <div class="d-flex justify-content-center gap-3">
        <app-primary-button
            text="Submit"
            addClass="submit"
            (clickButton)="submit()"
            *ngIf="!signupRequest"
            [disabled]="shouldDisableSignUpButton || isLoading || !checkboxValue"
        ></app-primary-button>
        <ng-container *ngIf="signupRequest">
            <ng-container *ngIf="requestPending">
                <app-primary-button text="Save" (click)="submit()" [disabled]="shouldDisableSignUpButton || isLoading"></app-primary-button>
                <button type="button" class="btn btn-outline-success btn-sm px-4"
                    (click)="approve.emit()" [disabled]="shouldDisableSignUpButton || isLoading || hasValidationErrors">
                    <i class="fa fa-check"></i>
                    {{'Approve' | translate}}
                </button>
                <button type="button" class="btn btn-outline-danger btn-sm px-4"
                    (click)="reject.emit()" [disabled]="isLoading">
                    <i class="fa fa-times"></i>
                    {{'Reject' | translate}}
                </button>
            </ng-container>
            <app-secondary-button text="Exit" (click)="exit.emit()" [disabled]="isLoading"></app-secondary-button>
        </ng-container>
    </div>
</div>
